import { HttpClient } from '@angular/common/http';
import { Headers, Http, RequestOptions, ResponseContentType } from '@angular/http';
import { map } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/http";
var LoginserviceService = (function () {
    function LoginserviceService(http, ht, hc) {
        this.http = http;
        this.ht = ht;
        this.hc = hc;
    }
    LoginserviceService.prototype.login = function (data) {
        console.log(data);
        var email = data['email'];
        console.log(email);
        var password = data['password'];
        console.log(password);
        return this.ht.get('https://therightdoctors.com/api/beta/doctor-article/login/' + email + '/' + password + '?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg', this.jwt()).map(function (response) { return response.json(); });
    };
    LoginserviceService.prototype.verify_code2 = function (token, country, phone, email) {
        var data = {
            "country_code": country,
            "phone_number": phone,
            "token": token,
            "email": email
        };
        return this.ht.post('https://msg.therightdoctors.com/api/verification/verify2', data).map(function (response) { return response.json(); });
    };
    LoginserviceService.prototype.verification_code_send2 = function (phone, country) {
        var data = {
            "country_code": country,
            "phone_number": phone,
            "via": "sms",
            "locale": "en"
        };
        return this.ht.post('https://msg.therightdoctors.com/api/verification/start', data).map(function (response) { return response.json(); });
    };
    LoginserviceService.prototype.register = function (data) {
        return this.ht.post('https://therightdoctors.com/api/beta/doctor-article/presentation/signup?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg', { 'json': data }, this.jwt()).map(function (response) { return response.json(); });
    };
    LoginserviceService.prototype.reset_password = function (email) {
        return this.ht.get('https://therightdoctors.com/api/beta/presentation/forgot/password/' + email + '?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg', this.jwt()).map(function (response) { return response.json(); });
    };
    LoginserviceService.prototype.user_login = function (username, password) {
        return this.ht.get('https://therightdoctors.com/api/beta/image/user/authentication/' + username + '/' + password + '?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg');
    };
    LoginserviceService.prototype.sendPwd = function (model) {
        console.log('i am here');
        return this.http.post('https://therightdoctors.com/api/beta/subscriber/podcast_send_pwd?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg', model);
    };
    LoginserviceService.prototype.registration = function (user) {
        return this.http.post('https://therightdoctors.com/api/beta/subscriber/new_polling_user?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg', { 'json': user });
    };
    LoginserviceService.prototype.mail_checking_login = function (email, password) {
        return this.ht.get('https://therightdoctors.com/api/beta/subscriber/poll_users_login/' + email + '/' + password + '?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg', this.jwt()).pipe(map(function (response) { return response.json(); }));
    };
    LoginserviceService.prototype.getlastwordbyslug = function (slug) {
        return this.ht.get('https://therightdoctors.com/api/beta/image/' + slug + '?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg');
    };
    LoginserviceService.prototype.podcast_audio_files = function () {
        return this.ht.get('https://therightdoctors.com/api/beta/article?category=podcast&event=wccmm-mumbai-2019&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4');
    };
    LoginserviceService.prototype.blog_list = function () {
        return this.ht.get('https://therightdoctors.com/api/beta/article?category=cme&event=wccmm-mumbai-2019&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4');
    };
    LoginserviceService.prototype.the_last_word = function () {
        return this.ht.get('https://therightdoctors.com/api/beta/image?type=gallery&event=wccmm-mumbai-2019&key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg');
    };
    LoginserviceService.prototype.user_register = function (data) {
        return this.ht.post('https://therightdoctors.com/api/beta/image/user?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg', { 'json': data });
    };
    LoginserviceService.prototype.get_videos = function () {
        return this.ht.get('https://therightdoctors.com/api/beta/article?event=wccmm-mumbai-2019&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4&is_live=true');
    };
    LoginserviceService.prototype.get_podcast = function (slug) {
        return this.ht.get('https://therightdoctors.com/api/beta/article/' + slug + '?key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4&is_live=true');
    };
    LoginserviceService.prototype.get_short_url = function (Url) {
        console.log('in Url Shortner service');
        console.log(Url);
        var url_service = 'http://localhost:5000/short';
        console.log(Url);
        return this.ht.post('https://trd.therightdoctors.com/short', { 'url': Url });
    };
    LoginserviceService.prototype.corona_ppt = function () {
        return this.ht.get('https://therightdoctors.com/api/beta/image?type=presentation&event=corona-virus&key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg');
    };
    LoginserviceService.prototype.get_presentation_all = function (event) {
        var final_url = 'https://therightdoctors.com/api/beta/image?type=presentation&event=' + event + '&key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg';
        console.log(final_url);
        return this.ht.get(final_url);
    };
    LoginserviceService.prototype.update_views = function (data, id) {
        return this.ht.put('https://therightdoctors.com/api/beta/image/' + id + '?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg', data);
    };
    LoginserviceService.prototype.get_single_video = function (slug, catagory) {
        console.log('in article service single video');
        var final_url = 'https://therightdoctors.com/api/beta/article/' + slug + '?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg';
        console.log(final_url);
        return this.ht.get(final_url);
    };
    LoginserviceService.prototype.get_next_video = function (category, event) {
        console.log(category);
        console.log(event);
        if (event == 'rssdi-jaipur') {
            event = 'rssdi-jaipur-2019';
        }
        var next = 'https://therightdoctors.com/api/beta/article?category=' + category + '&event=' + event + '&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4';
        return this.ht.get(next);
    };
    LoginserviceService.prototype.update_totalviews = function (model, slug) {
        var data = JSON.stringify(model);
        var final_url = 'https://therightdoctors.com/api/beta/article/' + slug + '?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg';
        return this.ht.put(final_url, { 'json': data });
    };
    LoginserviceService.prototype.subscribe_mail = function (email) {
        return this.ht.post('https://therightdoctors.com/api/beta/subscriber/subscribe_mail/?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg&token=trd_token', { 'json': email }, this.jwt()).map(function (response) { return response.json(); });
    };
    LoginserviceService.prototype.subscribe_mail_using_name_and_email = function (email) {
        return this.ht.post('https://therightdoctors.com/api/beta/subscriber/subscribe_mail_using/full_name_email/?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg&token=trd_token', { 'json': email }, this.jwt()).map(function (response) { return response.json(); });
    };
    LoginserviceService.prototype.get_session_details = function (hall, date) {
        return this.ht.get('https://therightdoctors.com/api/beta/event_details/' + hall + '/' + date + '?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg');
    };
    LoginserviceService.prototype.get_videos1 = function () {
        return this.ht.get('https://therightdoctors.com/api/beta/article?event=rssdi-jaipur-2019&category=the-interview&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4&is_live=true');
    };
    LoginserviceService.prototype.the_last_word_rssdi = function () {
        return this.ht.get('https://therightdoctors.com/api/beta/article?category=one-more-thing&event=rssdi-jaipur-2019&key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg');
    };
    LoginserviceService.prototype.criticare = function () {
        return this.ht.get('https://therightdoctors.com/api/beta/article?event=criticare-hyderabad-2020&key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg');
    };
    LoginserviceService.prototype.criticare_all = function (event, category) {
        if (category != '')
            return this.ht.get('https://therightdoctors.com/api/beta/article?event=' + event + '&category=' + category + '&key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg');
        else
            return this.ht.get('https://therightdoctors.com/api/beta/article?event=' + event + '&key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg');
    };
    LoginserviceService.prototype.criticare_for_tv = function () {
        return this.ht.get('https://therightdoctors.com/api/beta/article?category=feature&key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg');
    };
    LoginserviceService.prototype.the_last_word_iages = function () {
        return this.ht.get('https://therightdoctors.com/api/beta/article?category=one-more-thing&event=iages-guwahati-2020&key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg');
    };
    LoginserviceService.prototype.the_last_word_wccpci = function (event) {
        return this.hc.get("https://therightdoctors.com/api/beta/image?&event=" + event + "&limit=20&key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg");
    };
    LoginserviceService.prototype.BlogData = function () {
        return this.ht.get('https://therightdoctors.com/api/beta/article?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg&category=cme&event=rssdi-jaipur-2019');
    };
    LoginserviceService.prototype.get_presentation = function (id) {
        console.log('ID', id);
        var final_url = 'https://therightdoctors.com/api/beta/image/' + id + '?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg';
        console.log(final_url);
        return this.ht.get(final_url);
    };
    LoginserviceService.prototype.get_single_video2 = function () {
        console.log('in article service single video');
        var final_url = 'https://therightdoctors.com/api/beta/slide_article' + '?key=' + '6ZzQ52peX5XqUx3t824670wv8jIaf1B4&limit=100';
        console.log(final_url);
        return this.ht.get(final_url)
            .map(function (data) {
            data.json();
            console.log(' I CAN SEE DATA HERE: ', data.json());
            return data.json();
        }).catch(function (error) { return throwError(error.json()); });
    };
    LoginserviceService.prototype.get_single_video3 = function () {
        console.log('in article service single video');
        var final_url = 'https://therightdoctors.com/api/beta/slide_article_published' + '?key=' + '6ZzQ52peX5XqUx3t824670wv8jIaf1B4';
        console.log(final_url);
        return this.ht.get(final_url)
            .map(function (data) {
            data.json();
            console.log(' I CAN SEE DATA HERE: ', data.json());
            return data.json();
        }).catch(function (error) { return throwError(error.json()); });
    };
    LoginserviceService.prototype.get_presentation_one = function (limit) {
        if (limit === void 0) { limit = 500; }
        var final_url = "https://therightdoctors.com/api/beta/image?type=presentation&limit=" + limit + "&key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg";
        console.log(final_url);
        return this.ht.get(final_url);
    };
    LoginserviceService.prototype.get_presentation_all_popular_page = function () {
        var final_url = 'https://therightdoctors.com/api/beta/image?type=presentation' + '&key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg';
        console.log(final_url);
        return this.ht.get(final_url);
    };
    LoginserviceService.prototype.commented = function (model, slug) {
        var data = JSON.stringify(model);
        var final_url = 'https://therightdoctors.com/api/beta/article/slide_comment/' + slug + '?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg';
        return this.ht.put(final_url, { 'json': data })
            .map(function (data) {
            data.json();
            console.log('I CAN SEE DATA HERE put req: ', data.json());
            return data.json();
        }).catch(function (error) { return throwError(error.json()); });
    };
    LoginserviceService.prototype.getDownload = function (targetUrl) {
        this.ht.get(targetUrl, { responseType: ResponseContentType.Blob })
            .catch(function (err) { return err; })
            .subscribe(function (res) {
            var a = document.createElement("a");
            a.href = URL.createObjectURL(res.blob());
            a.download = "slide.pdf";
            a.click();
        });
    };
    LoginserviceService.prototype.liked = function (model, slug) {
        var data = JSON.stringify(model);
        var final_url = 'https://therightdoctors.com/api/beta/article/slide_like/' + slug + '?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg';
        return this.ht.put(final_url, { 'json': data })
            .map(function (data) {
            data.json();
            console.log('I CAN SEE DATA HERE put req: ', data.json());
            return data.json();
        }).catch(function (error) { return throwError(error.json()); });
    };
    LoginserviceService.prototype.getlastwordbyslugarticle = function (slug) {
        return this.ht.get('https://therightdoctors.com/api/beta/article/' + slug + '?key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4');
    };
    LoginserviceService.prototype.jwt = function () {
        console.log('in jwt function');
        var currentUser = null;
        if (currentUser && currentUser.token) {
            var headers = new Headers({ 'Authorization': 'Bearer ' + currentUser.token });
            return new RequestOptions({ headers: headers });
        }
    };
    LoginserviceService.ngInjectableDef = i0.defineInjectable({ factory: function LoginserviceService_Factory() { return new LoginserviceService(i0.inject(i1.HttpClient), i0.inject(i2.Http), i0.inject(i1.HttpClient)); }, token: LoginserviceService, providedIn: "root" });
    return LoginserviceService;
}());
export { LoginserviceService };

import { RouterModule } from '@angular/router';
import { NewHomeComponent } from './new-home/new-home.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { ImportContactRegisterComponent } from './import-contact-login/import-contact-login.component';
import { LikeComment } from './like-comment/like-comment.component';
import { RegistrationComponent } from './registration/registration.component';
import { WebinarBroadcastComponent } from './webinar-broadcast/webinar-broadcast.component';
import { MagzineComponent } from './magzine/magzine.component';
import { MagzineLandingPageComponent } from './magzine-landing-page/magzine-landing-page.component';
import { TestingComponent } from './testing/testing.component';
import { TopStoriesSlugPageComponent } from './top-stories-slug-page/top-stories-slug-page.component';
import { SendViewsMailerToDoctorsComponent } from './send-views-mailer-to-doctors/send-views-mailer-to-doctors.component';
import { LiveUpdateEditPageComponent } from './live-update-edit-page/live-update-edit-page.component';
import { RssComponent } from './rss/rss.component';
import { NewSurveyComponent } from './new-survey/new-survey.component';
import { RegisterForgotPasswordComponent } from './register-forgot-password/register-forgot-password.component';
import { ForgotPasswordNewComponent } from './forgot-password-new/forgot-password-new.component';
import { ResetPasswordNewComponent } from './reset-password-new/reset-password-new.component';
var ɵ0 = { name: 'Home' }, ɵ1 = { name: 'The-Interview' }, ɵ2 = { name: 'The-Speakers' }, ɵ3 = { name: 'One-More-Thing' }, ɵ4 = { name: 'In-Conversation' }, ɵ5 = { name: 'Specials' }, ɵ6 = { name: 'Synapse' }, ɵ7 = { name: 'Live' }, ɵ8 = { name: 'live' }, ɵ9 = { name: 'Revolution-Talk' }, ɵ10 = { name: 'category' }, ɵ11 = { name: 'Live-Cases' }, ɵ12 = { name: 'single' }, ɵ13 = { name: 'single' }, ɵ14 = { name: 'polls' }, ɵ15 = { name: 'Journals' }, ɵ16 = { name: 'presentation' }, ɵ17 = { name: 'Dr-Hk-Chopra' }, ɵ18 = { name: 'Dr-Manish-Verma' }, ɵ19 = { name: 'Dr-Rishi-Jain' }, ɵ20 = { name: 'Terms-Service' }, ɵ21 = { name: 'Disclaimer' }, ɵ22 = { name: 'Privacy' }, ɵ23 = { name: 'about' }, ɵ24 = { name: 'Contact' }, ɵ25 = { name: 'single' }, ɵ26 = { name: 'aoc-quiz' }, ɵ27 = { name: 'aoc-polls-quiz-winners' };
var MAINMENU_ROUTES = [
    { path: 'register-forgot-password', component: RegisterForgotPasswordComponent },
    { path: 'login-page', component: LoginPageComponent },
    { path: 'forgot-password', component: ForgotPasswordNewComponent },
    { path: 'reset-password/:id', component: ResetPasswordNewComponent },
    { path: 'question-sponsors-page', loadChildren: 'app/question-page-sponsor-noid/question-page-sponsor-noid.module#QuestionPageSponsorNoidModule' },
    { path: 'insights', loadChildren: 'app/insights/insights.module#InsightsModule' },
    { path: 'podcast', loadChildren: 'app/podcast-subdomain/podcast-subdomain.module#PodcastSubdomainModule' },
    { path: 'tv', loadChildren: 'app/tv/tv.module#TvModule' },
    { path: 'slides', loadChildren: 'app/slides-subdomain/slides-subdomain.module#SlidesSubdomainModule' },
    { path: 'images', loadChildren: 'app/images-subdomain/images-subdomain.module#ImagesSubdomainModule' },
    { path: 'iages/presentations/:id', loadChildren: 'app/send-iages-presentation-topics/send-iages-presentation-topics.module#SendIagesPresentationTopicsModule' },
    { path: 'live-telecast/:user/:pass', loadChildren: 'app/vmix/vmix.module#VmixModule' },
    { path: 'IAGES-2020-Question-of-the-Talk', loadChildren: 'app/iages/iages.module#IagesModule' },
    { path: 'question-page-sponsors/:id', loadChildren: 'app/question-page-sponsors/question-page-sponsors.module#QuestionPageSponsorsModule' },
    { path: 'question-page/:id', loadChildren: 'app/question-page/question-page.module#QuestionPageModule' },
    { path: 'question-page-post-event/:id', loadChildren: 'app/question-page-post-event/question-page-post-event.module#QuestionPagePostEventModule' },
    { path: 'question-page-last-minute/:id', loadChildren: 'app/question-page-last-minute/question-page-last-minute.module#QuestionPageLastMinuteModule' },
    { path: 'live-update-edit-delete', loadChildren: 'app/live-update-view-delete/live-update-view-delete.module#LiveUpdateViewDeleteModule' },
    { path: 'send-mailer-kp-pharma/:id', loadChildren: 'app/send-mailer-to-pharma/send-mailer-to-pharma.module#SendMailerToPharmaModule' },
    { path: 'AOC-2020-Live-Updates', loadChildren: 'app/live-updates-aoc/live-updates-aoc.module#LiveUpdatesAocModule' },
    { path: 'edit-transcript-event/:id/:tid/:did', loadChildren: 'app/edit-book-studio-slot/edit-book-studio-slot.module#EditBookStudioSlotModule' },
    { path: 'verification-of-registration/:id', loadChildren: 'app/verification-of-registration/verification-of-registration.module#VerificationOfRegistrationModule' },
    { path: 'import-contact-login/:slug', loadChildren: 'app/import-contact-login/import-contact-login.module#ImportContactLoginModule' },
    { path: 'import-contact-register/:slug', component: ImportContactRegisterComponent },
    { path: 'key-takeaways/:slug/:id', loadChildren: 'app/presentation-single/presentation-single.module#PresentationSingleModule' },
    { path: 'import-contact', loadChildren: 'app/import-contact/import-contact.module#ImportContactModule' },
    { path: 'unsubscribe-mailer/:slug', loadChildren: 'app/unsubscribe/unsubscribe.module#UnsubscribeModule' },
    { path: 'profile', loadChildren: 'app/profile/profile.module#ProfileModule' },
    { path: 'profile-edit', loadChildren: 'app/edit-profile/edit-profile.module#EditProfileModule' },
    { path: 'automation/:all', loadChildren: 'app/auto-engagement-triggers/auto-engagement-triggers.module#AutoEngagementTriggersModule' },
    { path: 'tv-studio-workflow', loadChildren: 'app/eventworkflow/eventworkflow.module#EventworkflowModule' },
    { path: 'post-event-branded-app', loadChildren: 'app/post-event-branded/post-event-branded.module#PostEventBrandedModule' },
    { path: 'subscription-newsletter', loadChildren: 'app/newsletter-subscription/newsletter-subscription.module#NewsletterSubscriptionModule' },
    { path: 'salesforce-mailer', loadChildren: 'app/salesforce-mailer/salesforce-mailer.module#SalesforceMailerModule' },
    { path: 'journal-mail', loadChildren: 'app/journal-mail/journal-mail.module#JournalMailModule' },
    { path: 'subscription-register', loadChildren: 'app/subscription-register/subscription-register.module#SubscriptionRegisterModule' },
    { path: 'subscription-plan', loadChildren: 'app/subscription-plan/subscription-plan.module#SubscriptionPlanModule' },
    { path: 'faq-subscription', loadChildren: 'app/faq-subscription/faq-subscription.module#FaqSubscriptionModule' },
    { path: 'mautic-user-login', loadChildren: 'app/mautic-user-login/mautic-user-login.module#MauticUserLoginModule' },
    { path: 'subscription', loadChildren: 'app/subscription-landing-page/subscription-landing-page.module#SubscriptionLandingPageModule' },
    { path: 'subscription-account', loadChildren: 'app/subscription-account-details/subscription-account-details.module#SubscriptionAccountDetailsModule' },
    { path: 'subscription-newsletters-new', loadChildren: 'app/subscription-newsletters/subscription-newsletters.module#SubscriptionNewslettersModule' },
    { path: 'mautic-user-dashboard/:team', loadChildren: 'app/mautic-user-dashboard/mautic-user-dashboard.module#MauticUserDashboardModule' },
    { path: 'subscription-billing-details', loadChildren: 'app/subscription-billing-details/subscription-billing-details.module#SubscriptionBillingDetailsModule' },
    { path: 'mautic-user-addcontact/:team', loadChildren: 'app/mautic-user-addcontact/mautic-user-addcontact.module#MauticUserAddcontactModule' },
    { path: 'mailer-event-wise-doctors-video-metrics', loadChildren: 'app/mailer-event-wise/mailer-event-wise.module#MailerEventWiseModule' },
    { path: 'mailers-auto', loadChildren: 'app/automation-of-mailers/automation-of-mailers.module#AutomationOfMailersModule' },
    { path: 'mailer-doctor-selected-videos-metrics', loadChildren: 'app/mailer-doctor-wise/mailer-doctor-wise.module#MailerDoctorWiseModule' },
    { path: 'image-presentation-email', loadChildren: 'app/img-presentaion-mailer-event-wise/img-presentaion-mailer-event-wise.module#ImgPresentaionMailerEventWiseModule' },
    { path: 'survey-100119', loadChildren: 'app/survey-questions/survey-questions.module#SurveyQuestionsModule' },
    { path: 'knowledge-partner', loadChildren: 'app/new-knowledge-partner/new-knowledge-partner.module#NewKnowledgePartnerModule' },
    { path: 'podcast-mail', loadChildren: 'app/podcast-mail/podcast-mail.module#PodcastMailModule' },
    { path: 'presentation-event', loadChildren: 'app/presentation-event/presentation-event.module#PresentationEventModule' },
    { path: 'test-audio', loadChildren: 'app/test-audio-player/test-audio-player.module#TestAudioPlayerModule' },
    { path: 'event-survey', loadChildren: 'app/event-survey/event-survey.module#EventSurveyModule' },
    { path: 'presentation-slider', loadChildren: 'app/presentation-slider/presentation-slider.module#PresentationSliderModule' },
    { path: 'upload-file', loadChildren: 'app/upload-file/upload-file.module#UploadFileModule' },
    { path: 'wockhardt-rssdi-tv-ahmedabad-2018/the-last-word', loadChildren: 'app/rssdi-gallery/rssdi-gallery.module#RssdiGalleryModule' },
    { path: 'wockhardt-rssdi-tv-ahmedabad-2018/the-last-word/:slug/:id', loadChildren: 'app/rssdi-single-gallery/rssdi-single-gallery.module#RssdiSingleGalleryModule' },
    { path: ':event/the-last-word', loadChildren: 'app/eventlast-word/eventlast-word.module#EventlastWordModule' },
    { path: ':event/the-last-word/:slug/:id', loadChildren: 'app/eventlast-word-single/eventlast-word-single.module#EventlastWordSingleModule' },
    { path: 'single-page-the-last-word', loadChildren: 'app/single-page-the-last-word-without-event/single-page-the-last-word-without-event.module#SinglePageTheLastWordWithoutEventModule' },
    { path: 'the-last-word', loadChildren: 'app/the-last-word-without-event/the-last-word-without-event.module#TheLastWordWithoutEventModule' },
    { path: '', component: NewHomeComponent, data: ɵ0 },
    { path: 'salesforce/get', loadChildren: 'app/sales-get/sales-get.module#SalesGetModule' },
    { path: 'salesforce/post/:slug', loadChildren: 'app/sales-post/sales-post.module#SalesPostModule' },
    { path: 'test', loadChildren: 'app/tes/tes.module#TesModule' },
    { path: 'tag-video/:tag', loadChildren: 'app/tags/tags.module#TagsModule' },
    { path: 'bap', loadChildren: 'app/bap/bap.module#BapModule' },
    { path: 'specials/:slug/gallerywcc', loadChildren: 'app/wccpci-gallery/wccpci-gallery.module#WccpciGalleryModule' },
    { path: 'specials/:slug/wccpcigallery/:id', loadChildren: 'app/single-yoga-of-wccpc/single-yoga-of-wccpc.module#SingleYogaOfWccpcModule' },
    { path: 'live-update-edit-page/:id', component: LiveUpdateEditPageComponent },
    { path: 'specials/:slug/wccpcigallery1/:id', loadChildren: 'app/single-yoga-of-wccpc1/single-yoga-of-wccpc1.module#SingleYogaOfWccpc1Module' },
    { path: 'specials/:slug/wccpcigallery2/:id', loadChildren: 'app/single-yoga-of-wccpc2/single-yoga-of-wccpc2.module#SingleYogaOfWccpc2Module' },
    { path: 'specials/:slug/wccpcigallery3/:id', loadChildren: 'app/single-yoga-of-wccpc3/single-yoga-of-wccpc3.module#SingleYogaOfWccpc3Module' },
    { path: 'specials/:slug/gallery/:id', loadChildren: 'app/single-benefits-of-yoga3/single-benefits-of-yoga3.module#SingleBenefitsOfYoga3Module' },
    { path: 'specials/apvic/event-gallery', loadChildren: 'app/apvic-gallery/apvic-gallery.module#ApvicGalleryModule' },
    { path: 'specials/csi-2017/event-gallery', loadChildren: 'app/csi2017-gallery/csi2017-gallery.module#Csi2017GalleryModule' },
    { path: 'specials/:slug/event-gallery/:id', loadChildren: 'app/single-benefits-of-yoga2/single-benefits-of-yoga2.module#SingleBenefitsOfYoga2Module' },
    { path: 'subscription-plan-page', loadChildren: 'app/subscription-plan-page/subscription-plan-page.module#SubscriptionPlanPageModule' },
    { path: 'blog/:category/:slug', loadChildren: 'app/blog/blog.module#BlogModule' },
    { path: 'home-page-video-dashboard', loadChildren: 'app/homepage-video-dashboard/homepage-video-dashboard.module#HomepageVideoDashboardModule' },
    { path: 'all-blog-page', loadChildren: 'app/all-blog-page/all-blog-page.module#AllBlogPageModule' },
    { path: 'subscription-login', loadChildren: 'app/subscription-login-page/subscription-login-page.module#SubscriptionLoginPageModule' },
    { path: 'verify-email/:email', loadChildren: 'app/verify-email/verify-email.module#VerifyEmailModule' },
    { path: 'presentation/new/password/:email', loadChildren: 'app/reset-password/reset-password.module#ResetPasswordModule' },
    { path: 'the-interview', loadChildren: 'app/the-interview/the-interview.module#TheInterviewModule', data: ɵ1 },
    { path: 'the-speakers', loadChildren: 'app/the-speakers/the-speakers.module#TheSpeakersModule', data: ɵ2 },
    { path: 'one-more-thing', loadChildren: 'app/one-more-thing/one-more-thing.module#OneMoreThingModule', data: ɵ3 },
    { path: 'in-conversation', loadChildren: 'app/in-conversation/in-conversation.module#InConversationModule', data: ɵ4 },
    { path: 'specials', loadChildren: 'app/specials/specials.module#SpecialsModule', data: ɵ5 },
    { path: 'specials/:event', loadChildren: 'app/event-home/event-home.module#EventHomeModule' },
    { path: 'specials/:event/:category', loadChildren: 'app/single-event-category/single-event-category.module#SingleEventCategoryModule' },
    { path: 'synapse', loadChildren: 'app/synapse/synapse.module#SynapseModule', data: ɵ6 },
    { path: 'live', loadChildren: 'app/live/live.module#LiveModule', data: ɵ7 },
    { path: 'live-streaming', loadChildren: 'app/live-streaming/live-streaming.module#LiveStreamingModule' },
    { path: 'live-streaming-dashboard', loadChildren: 'app/live-streaming-dashboard/live-streaming-dashboard.module#LiveStreamingDashboardModule' },
    { path: 'live/videos/:slug', loadChildren: 'app/live/live.module#LiveModule', data: ɵ8 },
    { path: 'showcase', loadChildren: 'app/products/products.module#ProductsModule' },
    { path: 'podcast', loadChildren: 'app/podcast-special/podcast-special.module#PodcastSpecialModule' },
    { path: 'aoc-2020/pdf/:slug', component: MagzineComponent },
    { path: 'aoc-2020', component: MagzineLandingPageComponent },
    { path: 'ada-2021/:id', component: WebinarBroadcastComponent },
    { path: 'testing', component: TestingComponent },
    { path: 'amp/:a/:b/:c/:slug', component: NewSurveyComponent },
    { path: 'login', component: LikeComment },
    { path: 'top-stories-slug', component: TopStoriesSlugPageComponent },
    { path: 'signup', component: RegistrationComponent },
    { path: 'blog', loadChildren: 'app/blog/blog.module#BlogModule' },
    { path: 'podcast-event/:event', loadChildren: 'app/podcast/podcast.module#PodcastModule' },
    { path: ':category/live-updates', loadChildren: 'app/live-updates/live-updates.module#LiveUpdatesModule' },
    { path: 'covid19-updates', loadChildren: 'app/covid-live-updates/covid-live-updates.module#CovidLiveUpdatesModule' },
    { path: ':category/live-updates/:date/:slug', loadChildren: 'app/live-update-singlepage/live-update-singlepage.module#LiveUpdateSinglepageModule' },
    { path: 'coronavirus-stories', loadChildren: 'app/submit-coronavirus-stories/submit-coronavirus-stories.module#SubmitCoronavirusStoriesModule' },
    { path: 'iages-live-updates', loadChildren: 'app/iages-live-updates/iages-live-updates.module#IagesLiveUpdatesModule' },
    { path: 'coronavirus-questions', loadChildren: 'app/coronavirus-questions/coronavirus-questions.module#CoronavirusQuestionsModule' },
    { path: 'iages-live-updates-form', loadChildren: 'app/iages-live-updates-form/iages-live-updates-form.module#IagesLiveUpdatesFormModule' },
    { path: 'live-updates-pipeline', loadChildren: 'app/live-updates-pipeline/live-updates-pipeline.module#LiveUpdatesPipelineModule' },
    { path: 'live-update-dashboard', loadChildren: 'app/live-update-form/live-update-form.module#LiveUpdateFormModule' },
    { path: 'login-live-updates', loadChildren: 'app/login-live-updates/login-live-updates.module#LoginLiveUpdatesModule' },
    { path: 'contribution-login', loadChildren: 'app/log-in-contribution/log-in-contribution.module#LogInContributionModule' },
    { path: 'contribution-register', loadChildren: 'app/register-contribution/register-contribution.module#RegisterContributionModule' },
    { path: 'plan', loadChildren: 'app/plan-contribution/plan-contribution.module#PlanContributionModule' },
    { path: 'contribution-forgot-pass', loadChildren: 'app/reset-pass-contribution/reset-pass-contribution.module#ResetPassContributionModule' },
    { path: 'contribution-profile', loadChildren: 'app/profile-contribution/profile-contribution.module#ProfileContributionModule' },
    { path: 'mailVer', loadChildren: 'app/mail-ver-contribution/mail-ver-contribution.module#MailVerContributionModule' },
    { path: 'live-update-main-dashboard', loadChildren: 'app/live-updates-main-dashboard/live-updates-main-dashboard.module#LiveUpdatesMainDashboardModule' },
    { path: 'admin', loadChildren: 'app/home-admin/home-admin.module#HomeAdminModule' },
    { path: 'revolution-talk', loadChildren: 'app/revolution-talk/revolution-talk.module#RevolutionTalkModule', data: ɵ9 },
    { path: ':category/all', loadChildren: 'app/category-all/category-all.module#CategoryAllModule', data: ɵ10 },
    { path: 'notfound', loadChildren: 'app/not-found-component/not-found-component.module#NotFoundComponentModule' },
    { path: 'specials/:event/presentation/:slug/:id', loadChildren: 'app/presentation/presentation.module#PresentationModule' },
    { path: 'csinic-2016/live_cases', loadChildren: 'app/live-cases/live-cases.module#LiveCasesModule', data: ɵ11 },
    { path: 'specials/event/revolution-talk/:slug', loadChildren: 'app/single-revolution/single-revolution.module#SingleRevolutionModule', data: ɵ12 },
    { path: ':event/:catagory/:slug', loadChildren: 'app/single-article-video/single-article-video.module#SingleArticleVideoModule', data: ɵ13 },
    { path: 'polls', loadChildren: 'app/polls/polls.module#PollsModule', data: ɵ14 },
    { path: 'journals', loadChildren: 'app/journels-all/journels-all.module#JournelsAllModule', data: ɵ15 },
    { path: 'presentation', loadChildren: 'app/slidepresentation/slidepresentation.module#SlidepresentationModule', data: ɵ16 },
    { path: 'live-update', loadChildren: 'app/live-updates/live-updates.module#LiveUpdatesModule' },
    { path: 'Dr-Hk-Chopra-Sr-Cardiologist-Moolchand-Hospital-Delhi', loadChildren: 'app/dr-hk-chopra/dr-hk-chopra.module#DrHkChopraModule', data: ɵ17 },
    { path: 'Dr-Manish-Verma-Sr-Cardiologist-Fortis-Hospital-Bangalore', loadChildren: 'app/dr-manish-verma/dr-manish-verma.module#DrManishVermaModule', data: ɵ18 },
    { path: 'Dr-Rishi-Jain-Sr-Cardiologist', loadChildren: 'app/dr-rishi-jain/dr-rishi-jain.module#DrRishiJainModule', data: ɵ19 },
    { path: 'terms-of-service', loadChildren: 'app/terms-of-service/terms-of-service.module#TermsOfServiceModule', data: ɵ20 },
    { path: 'disclaimer', loadChildren: 'app/disclaimer/disclaimer.module#DisclaimerModule', data: ɵ21 },
    { path: 'privacy-policy', loadChildren: 'app/privacy-policy/privacy-policy.module#PrivacyPolicyModule', data: ɵ22 },
    { path: 'about-us', loadChildren: 'app/about-us/about-us.module#AboutUsModule', data: ɵ23 },
    { path: 'contact-us', loadChildren: 'app/contact-us/contact-us.module#ContactUsModule', data: ɵ24 },
    { path: 'rssdi-jaipur-2019-slot-booking', loadChildren: 'app/details-form/details-form.module#DetailsFormModule' },
    { path: 'diabetes-india-2021-slot-booking', loadChildren: 'app/diabetes-form/diabetes-form.module#DiabetesFormModule' },
    { path: 'registration-form-covid', loadChildren: 'app/ask-questions-faq/ask-questions-faq.module#AskQuestionsFaqModule' },
    { path: 'csicon-delhi-2019-slot-booking', loadChildren: 'app/csicon2019-slot-booking/csicon2019-slot-booking.module#Csicon2019SlotBookingModule' },
    { path: 'edit-rssdi-jaipur-2019-slot-booking/:id', loadChildren: 'app/book-slot/book-slot.module#BookSlotModule' },
    { path: 'edit-diabetes-india-2021-slot-booking/:id', loadChildren: 'app/diabetes-slot/diabetes-slot.module#DiabetesSlotModule' },
    { path: 'edit-csicon-delhi-2019-slot-booking/:id', loadChildren: 'app/csicon2019-edit-slot/csicon2019-edit-slot.module#Csicon2019EditSlotModule' },
    { path: 'esicon-nagpur-2019-slot-booking', loadChildren: 'app/esicon-slot-booking/esicon-slot-booking.module#EsiconSlotBookingModule' },
    { path: 'edit-esicon-nagpur-2019-slot-booking/:id', loadChildren: 'app/edit-esicon-slot-booking/edit-esicon-slot-booking.module#EsiconEditSlotBookingModule' },
    { path: 'soapccon-hyderabad-2019-slot-booking', loadChildren: 'app/soapcon-slot-booking/soapcon-slot-booking.module#SoapconSlotBookingModule' },
    { path: 'book-slot-ecg', loadChildren: 'app/book-slot-ecg/book-slot-ecg.module#BookSlotEcgModule' },
    { path: ':catagory/:slug', loadChildren: 'app/single-component/single-component.module#SingleComponentModule', data: ɵ25 },
    { path: 'upload-auto-registration-data', loadChildren: 'app/auto-registration-of-invitees/auto-registration-of-invitees.module#AutoRegistrationOfInviteesModule' },
    { path: 'reset-password-registration/:id', loadChildren: 'app/reset-password-auto-registration-of-invitees/reset-password-auto-registration-of-invitees.module#ResetPasswordAutoRegistrationOfInviteesModule' },
    { path: 'trd-registration-form', loadChildren: 'app/trd-registration-form/trd-registration-form.module#TrdRegistrationFormModule' },
    { path: 'Import-Salesforce-contacts', loadChildren: 'app/import-salesforce-contacts/import-salesforce-contacts.module#ImportSalesforceContactsModule' },
    { path: 'slot-booking', loadChildren: 'app/book-slot-without-event/book-slot-without-event.module#BookSlotWithoutEventModule' },
    { path: 'thankyoupage', loadChildren: 'app/thankyoupage/thankyoupage.module#ThankyoupageModule' },
    { path: 'AOC-2020-Question-of-the-Talk', loadChildren: 'app/aoc-mumbai/aoc-mumbai.module#AocMumbaiModule', data: ɵ26 },
    { path: 'AOC-2020-Question-of-the-Talk-Winners', loadChildren: 'app/aoc-mumbai-polls-winners/aoc-mumbai-polls-winners.module#AocMumbaiPollsWinnersModule', data: ɵ27 },
    { path: 'AOC-2020-Live-Update-Live-Streaming', loadChildren: 'app/aoc-live-update-and-live-streaming/aoc-live-update-and-live-streaming.module#AocLiveUpdateAndLiveStreamingModule' },
    { path: 'corona-podcast', loadChildren: 'app/corona/corona.module#CoronaModule' },
    { path: 'send-email-for-transcript', loadChildren: 'app/send-email-for-transcript/send-email-for-transcript.module#SendEmailForTranscriptModule' },
    { path: 'send-email-for-interview-edit', loadChildren: 'app/send-email-for-interview-video-edit/send-email-for-interview-video-edit.module#SendEmailForInterviewVideoEditModule' },
    { path: 'edit-slot-booking/:id', loadChildren: 'app/edit-book-slot-without-event/edit-book-slot-without-event.module#EditBookSlotWithoutEventModule' },
    { path: 'send-mailer-kp', loadChildren: 'app/send-periodic-mailers/send-periodic-mailers.module#SendPeriodicMailersModule' },
    { path: 'send-mailer-for-interviews', loadChildren: 'app/send-mailer-to-docs/send-mailer-to-docs.module#SendMailerToDocsModule' },
    { path: 'send-mailer/kp/new/:id', loadChildren: 'app/send-periodic-mailers/send-periodic-mailers.module#SendPeriodicMailersModule' },
    { path: 'send-blog-mailer', loadChildren: 'app/send-blog-mailers/send-blog-mailers.module#SendBlogMailersModule' },
    { path: 'select-to-send-mailer-kp-pharma', loadChildren: 'app/select-to-send-mailer-to-pharma/select-to-send-mailer-to-pharma.module#SelectToSendMailerToPharmaModule' },
    { path: 'day-wise-updates', loadChildren: 'app/corona-daywise-updates/corona-daywise-updates.module#CoronaDaywiseUpdatesModule' },
    { path: 'edit-newsletters', loadChildren: 'app/edit-newsletter-mailer/edit-newsletter-mailer.module#EditNewsletterMailerModule' },
    { path: 'send-doctor-stats', component: SendViewsMailerToDoctorsComponent },
    { path: 'rss', component: RssComponent },
    { path: ':event', loadChildren: 'app/csi2019ppt/csi2019ppt.module#Csi2019pptModule' },
];
var AppRouting = (function () {
    function AppRouting() {
    }
    return AppRouting;
}());
export { AppRouting };
export var CONST_ROUTING = RouterModule.forRoot(MAINMENU_ROUTES);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27 };

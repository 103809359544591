import {APP_ID, Component, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild} from '@angular/core';
//import {LoginserviceService} from '../loginservice.service';
//import {AdItem} from '../dynamic/ad-item';
import {FormControl, Validators} from '@angular/forms';
import {VgAPI} from 'videogular2/core';
import {DOCUMENT, DomSanitizer, Meta, Title} from '@angular/platform-browser';
import {MatDialog} from '@angular/material';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {isPlatformBrowser, Location} from '@angular/common';
import {MAT_DIALOG_DATA} from '@angular/material';
//import {AdService} from '../dynamic/ad.service';
//import * as moment from '../single-article-video/single-article-video.component';
import {ScrollEvent} from 'ngx-scroll-event';
import { UserServiceService } from '../user-service.service';

import { UserServiceInsightService } from '../user-service_insight.service';
import { ArticleService } from '../article.service';
//import {HeroProfileComponent} from '../dynamic/hero-profile.component';
//import {MyErrorStateMatcher} from '../single-article-video/single-article-video.component';
//import {SinglePopup} from '../single-article-video/single-article-video.component';
import {MatDialogRef} from '@angular/material';
import {NgForm, FormGroupDirective} from "@angular/forms";
import {ErrorStateMatcher} from "@angular/material";
import { NavBarComponent } from '../nav-header/nav-bar.component';
import {Http, Response} from "@angular/http";
import {MyErrorStateMatcher} from '../aoc-mumbai/aoc-mumbai.component';
import { LoginService } from '../login.service';
import { LoginserviceService } from '../loginservice.service';


@Component({
  selector: 'app-new-survey',
  templateUrl: './new-survey.component.html',
  styleUrls: ['./new-survey.component.css']
})
export class NewSurveyComponent implements OnInit {

  slug:any;
  single_article : any;

  constructor(private router:Router, private route: ActivatedRoute,  public service: ArticleService,) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.slug = params['slug'];

      //this.category = params['category'];
      //this.isvalid = this.category ;

      this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0)
      });

    });
    this.get_single_video(this.slug );
  }


  get_single_video(slug ) {
    console.log('get one video calling');
    this.service.get_single_video_survey(slug,"").subscribe(
      data12 => {
        let data = data12.json();
        if(data['success'])
        {
   
          this.single_article = data['data'];
          }
        })
     
  }

}

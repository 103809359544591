var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserServiceService } from '../user-service.service';
var SendViewsMailerToDoctorsComponent = (function () {
    function SendViewsMailerToDoctorsComponent(userService, router, route) {
        this.userService = userService;
        this.router = router;
        this.route = route;
        this.buttonDisabled = false;
        this.isNonTrade = false;
        this.checkAllNonTrades = false;
        this.newsletter_list = [];
        this.newsletter_list1 = [];
        this.newsletter_list2 = [];
        this.newsletter_list3 = [];
        this.updated_newsletter_list = [];
    }
    SendViewsMailerToDoctorsComponent.prototype.ngOnInit = function () {
    };
    SendViewsMailerToDoctorsComponent.prototype.removeItem = function (item, list) {
        list.splice(list.indexOf(item), 1);
    };
    SendViewsMailerToDoctorsComponent.prototype.add_list = function () {
    };
    SendViewsMailerToDoctorsComponent.prototype.getdatafeature = function () {
        var _this = this;
        this.userService.getData_articles_forViews_Feature().subscribe(function (data) {
            _this.model = data['data'];
            _this.model.sort(function (a, b) {
                a = new Date(a.created_at);
                b = new Date(b.created_at);
                return a > b ? -1 : a < b ? 1 : 0;
            });
            console.log("Show Data ::: " + _this.model);
            for (var i = 0; i < 1; i++) {
                _this.newsletter_list.push(_this.model[i]);
            }
        });
    };
    SendViewsMailerToDoctorsComponent.prototype.getdatacolumn = function () {
        var _this = this;
        document.getElementById("newsletters").innerHTML = "";
        this.userService.getData_articles_forViews_Column().subscribe(function (data) {
            _this.model1 = data['data'];
            _this.model1.sort(function (a, b) {
                a = new Date(a.created_at);
                b = new Date(b.created_at);
                return a > b ? -1 : a < b ? 1 : 0;
            });
            console.log("Show Data ::: " + _this.model1);
            for (var i = 0; i < 1; i++) {
                _this.newsletter_list1.push(_this.model1[i]);
            }
        });
    };
    SendViewsMailerToDoctorsComponent.prototype.getdataguidelines = function () {
        var _this = this;
        this.userService.getData_articles_forViews_guidelines().subscribe(function (data) {
            _this.model2 = data['data'];
            _this.model2.sort(function (a, b) {
                a = new Date(a.created_at);
                b = new Date(b.created_at);
                return a > b ? -1 : a < b ? 1 : 0;
            });
            console.log("Show Data ::: " + _this.model2);
            for (var i = 0; i < 10; i++) {
                _this.newsletter_list2.push(_this.model2[i]);
            }
            _this.model2.splice(0, 10);
        });
    };
    SendViewsMailerToDoctorsComponent.prototype.getdatasurvey = function () {
        var _this = this;
        this.userService.getData_articles_forViews_survey().subscribe(function (data) {
            _this.model3 = data['data'];
            _this.model3.sort(function (a, b) {
                a = new Date(a.created_at);
                b = new Date(b.created_at);
                return a > b ? -1 : a < b ? 1 : 0;
            });
            console.log("Show Data ::: " + _this.model3);
            for (var i = 0; i < 10; i++) {
                _this.newsletter_list3.push(_this.model3[i]);
            }
            _this.model3.splice(0, 10);
        });
    };
    SendViewsMailerToDoctorsComponent.prototype.getData = function () {
        var _this = this;
        this.userService.getData_articles_forViews().subscribe(function (data) {
            _this.model = data['data'];
            _this.model.sort(function (a, b) {
                a = new Date(a.created_at);
                b = new Date(b.created_at);
                return a > b ? -1 : a < b ? 1 : 0;
            });
            console.log("Show Data ::: " + _this.model);
            for (var i = 0; i < 10; i++) {
                _this.newsletter_list.push(_this.model[i]);
            }
            _this.model.splice(0, 10);
        }, function (error) {
            console.log(error);
        });
    };
    SendViewsMailerToDoctorsComponent.prototype.changeTradesByCategory = function (event) {
        if (event.target.name == 'nontrades') {
            this.isNonTrade = true;
        }
        if (this.isNonTrade && this.checkAllNonTrades) {
            event.target.checked = true;
        }
    };
    SendViewsMailerToDoctorsComponent.prototype.send = function () {
        var em_list = this.email_list.replace(/\n/g, ',').split(",");
        em_list = __assign({}, em_list);
        console.log(em_list);
        this.newsletter_list.push(em_list);
        console.log("EditNewsletterMailerComponent -> send -> this.newsletter_list", this.newsletter_list);
        this.userService.send_email_views_feature(this.newsletter_list).subscribe(function (data) {
            console.log("EditNewsletterMailerComponent -> send -> data", data);
        });
    };
    SendViewsMailerToDoctorsComponent.prototype.SelectAll = function (event) {
        var checked = event.target.checked;
        this.model.forEach(function (item) { return item.selected = checked; });
    };
    return SendViewsMailerToDoctorsComponent;
}());
export { SendViewsMailerToDoctorsComponent };

import { Component, OnInit } from '@angular/core';
import { ArticleService } from '../article.service';

@Component({
  selector: 'app-rss',
  templateUrl: './rss.component.html',
  styleUrls: ['./rss.component.css']
})
export class RssComponent implements OnInit {

  feed :object[]=[];
  constructor(public service: ArticleService) { }

  ngOnInit() {
    this.service.table_data().subscribe(data2 => {
      console.log("aaaaa",data2)
      if(data2['success']){
        var data = data2['data']
        for(var i=0; i<data.length; i++){
          if(data[i]['category'] == 'cme' && data[i]['guest6_image_url'] != ''){
            
            this.feed.push(data[i])
          }
        }
      }
    })
  }



  createRange(number) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }

    return items;
  }
}

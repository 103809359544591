<div class="top-btns">
    <div class="container">
        <div class="row btns-subcon">
            <div class="col-xs-6 col-sm-6 col-md-6" fxLayoutAlign="end">
              <button mat-flat-button class="btn-subs" [routerLink]="['/subscription']">Subscribe</button>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6">
              <button mat-flat-button class="btn-contr" [routerLink]="['/plan']">Contribute</button>
            </div>
          </div>
    </div>
</div>
<div class="new-screen1">
    <div class="container" fxLayout="column">
        <div fxLayout.lt-sm="column" fxLayoutGap.lt-sm="20px" fxLayout.gt-xs="row"  fxLayoutGap.gt-xs="20px" style="min-height: 80vh; ">
          <div class="mob-view" fxFlex.lt-sm="100%" fxFlex.gt-xs="65%">

              <!--<h1>Haiiiiiiiiiiii</h1>-->
              <!--<p>{{exclusive_story['guest6_image_url']}}</p>-->
              <!--<a href= "{{exclusive_story['guest6_image_url']}}">Byeee</a>-->
              <!--<a href= "{{exclusive_story['guest6_image_url']}}">tyui</a>-->
              <a [href]="exclusive_story?.href_link">
                <picture>
                  <source type="image/webp" media="(max-width: 768px)" srcset="{{exclusive_story?.thumbnail_url1}}">
                  <!-- Source for WebP format, desktop devices -->
                  <source type="image/webp" media="(min-width: 769px)" srcset="{{exclusive_story?.thumbnail_url1}}">
                  
                    <img src="{{exclusive_story?.image_url}}" style="min-height: 25vh;" class="exclusive-mob-view" alt="{{exclusive_story?.headline}}"  width="100%">
                </picture>
                        <div style="padding-top: 5px;">
                            <p class="headnew">{{exclusive_story?.headline}}</p>
                         </div>
               
              </a>

            <div fxLayout="column" style="padding-top: 5px;">
              <div fxLayout.lt-sm="row" fxLayoutGap.lt-sm="2px" fxLayout.gt-xs="row"  fxLayoutGap.gt-xs="10px">
                <div fxFlex.lt-sm="50%" fxFlex.gt-xs="50%">
                    <a [href]="exclusive_story2?.href_link">
                      <picture>
                        <source type="image/webp" media="(max-width: 768px)" srcset="{{exclusive_story2?.thumbnail_url1}}">
                        <!-- Source for WebP format, desktop devices -->
                        <source type="image/webp" media="(min-width: 769px)" srcset="{{exclusive_story2?.thumbnail_url1}}">
       
                          <img style="padding: 5px;" src="{{exclusive_story2?.image_url}}" alt="{{exclusive_story2?.headline}}" class="bottom-exl-image" width="100%" height="60%">
                      </picture>
                       
                          
                        <div style="padding: 5px;">
                        <p class="subheadnew"> <span> {{exclusive_story2?.headline}} </span> </p>
                    </div>
                    </a>
                </div>
                <div fxFlex.lt-sm="50%" fxFlex.gt-xs="50%">
                    <a [href]="exclusive_story3?.href_link">
                      <picture>
                        <source type="image/webp" media="(max-width: 768px)" srcset="{{exclusive_story3?.thumbnail_url1}}">
                        <!-- Source for WebP format, desktop devices -->
                        <source type="image/webp" media="(min-width: 769px)" srcset="{{exclusive_story3?.thumbnail_url1}}">
                          <img style="padding: 5px;" src="{{exclusive_story3?.image_url}}" alt="{{exclusive_story3?.headline}}" class="bottom-exl-image" width="100%" height="60%">
                        </picture>
                        
                         <div style="padding: 5px;">
                            <p class="subheadnew"> <span>{{exclusive_story3?.headline}}</span> </p>
                        </div>
                    </a>
                </div>
              </div>
            </div>
          </div>
         
          <div class="mob-view marg" fxFlex.lt-sm="100%" fxFlex.gt-xs="35%">
            <br><br>
            <h5 class="all-head">top stories</h5>
            <ul fxLayout="column">
                <div *ngFor="let single_top_story of top_stories; let i = index ">
                    <div *ngIf="i < 13">
                        <li>
                            <a href="{{single_top_story?.href_link}}">
                                <div *ngIf="single_top_story['impact_feature']" style="font-size: 10px; font-weight: bold">Impact feature</div>
                                <div class="column brdr-btm-top" style="width: 100%;">
                                    <p class="internal-updates-head"> {{single_top_story?.headline}}</p>
                                </div>
                            </a>
                        </li>
                    </div>
                </div>
            </ul>
          </div>
        </div>
    </div>
</div>
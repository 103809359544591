import * as i0 from "./testing.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../node_modules/ng2-pdf-viewer/ng2-pdf-viewer.ngfactory";
import * as i5 from "ng2-pdf-viewer";
import * as i6 from "./testing.component";
import * as i7 from "@angular/router";
import * as i8 from "@angular/platform-browser";
import * as i9 from "@angular/forms";
var styles_TestingComponent = [i0.styles];
var RenderType_TestingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TestingComponent, data: {} });
export { RenderType_TestingComponent as RenderType_TestingComponent };
export function View_TestingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "background-know"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["fxLayout", "row"], ["fxLayout.lt-md", "column"]], null, null, null, null, null)), i1.ɵdid(3, 737280, null, 0, i2.LayoutDirective, [i3.MediaMonitor, i1.ElementRef, i3.StyleUtils], { layout: [0, "layout"], layoutLtMd: [1, "layoutLtMd"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["fxFlex", "10"]], null, null, null, null, null)), i1.ɵdid(5, 737280, null, 0, i2.FlexDirective, [i3.MediaMonitor, i1.ElementRef, [3, i2.LayoutDirective], i3.StyleUtils, [2, i3.ADD_FLEX_STYLES]], { flex: [0, "flex"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "page-content-sliders"], ["fxFlex", "80"]], null, null, null, null, null)), i1.ɵdid(7, 737280, null, 0, i2.FlexDirective, [i3.MediaMonitor, i1.ElementRef, [3, i2.LayoutDirective], i3.StyleUtils, [2, i3.ADD_FLEX_STYLES]], { flex: [0, "flex"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "pdf-viewer", [["style", "display: block;"]], null, [["window", "resize"], [null, "textlayerrendered"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onPageResize() !== false);
        ad = (pd_0 && ad);
    } if (("textlayerrendered" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).onTextLayerRendered($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_PdfViewerComponent_0, i4.RenderType_PdfViewerComponent)), i1.ɵdid(9, 770048, null, 0, i5.PdfViewerComponent, [i1.ElementRef], { src: [0, "src"], renderText: [1, "renderText"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["fxFlex", "10"]], null, null, null, null, null)), i1.ɵdid(11, 737280, null, 0, i2.FlexDirective, [i3.MediaMonitor, i1.ElementRef, [3, i2.LayoutDirective], i3.StyleUtils, [2, i3.ADD_FLEX_STYLES]], { flex: [0, "flex"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; var currVal_1 = "column"; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = "10"; _ck(_v, 5, 0, currVal_2); var currVal_3 = "80"; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.pdfSrc; var currVal_5 = true; _ck(_v, 9, 0, currVal_4, currVal_5); var currVal_6 = "10"; _ck(_v, 11, 0, currVal_6); }, null); }
export function View_TestingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-testing", [], null, null, null, View_TestingComponent_0, RenderType_TestingComponent)), i1.ɵdid(1, 114688, null, 0, i6.TestingComponent, [i7.ActivatedRoute, i8.Meta, i9.FormBuilder, i8.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TestingComponentNgFactory = i1.ɵccf("app-testing", i6.TestingComponent, View_TestingComponent_Host_0, {}, {}, []);
export { TestingComponentNgFactory as TestingComponentNgFactory };


<app-nav></app-nav>
<div class="container mar-top-bottom" style="clear:both;">
    <div fxLayout="row" fxLayout.lt-md="column">
        <div fxFlex="20"></div>
        <div fxFlex="60" >
<mat-card ngStyle.lt-md="margin-top:30px;">
    <h2 class="sign-up">Sign Up</h2>
<form role="form" (ngSubmit)="register()" #f="ngForm"  novalidate class="example-form">
    <mat-form-field class="example-full-width">
        <input matInput type="text" name="username" id="username"  placeholder="Full Name" style="box-shadow:none;border:0;background:transparent" [(ngModel)]="model2.username" #username="ngModel" required >
        <mat-error *ngIf="inputChecking.hasError('required')">
            First name is <b>required</b>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <input matInput type="text" name="email" id="email"  placeholder="Email"  style="box-shadow:none;border:0;background:transparent" [(ngModel)]="model2.email" #email="ngModel" required>
        <mat-error *ngIf="inputChecking.hasError('required')">
            Please enter valid email address (<b>Ex: abc@gmail.com</b>)
        </mat-error>
    </mat-form-field >

    <mat-form-field class="example-full-width">
        <input matInput type="password" name="password1" id="password1" [(ngModel)]="model2.password"  style="box-shadow:none;border:0;background:transparent" #password="ngModel"  placeholder="Password" required >
        <mat-error *ngIf="inputChecking.hasError('required')">
            Password is <b>required</b>
        </mat-error>

    </mat-form-field>
    <div>
        <mat-form-field class="example-full-width">
            <mat-select  name="country_code" placeholder="Select Country Code" [(value)]="selected" #country_code="ngModel"
                         [(ngModel)]="model2.country_code"  required>
                <mat-option> Select Country Code</mat-option>
                <mat-option value="+91">India +91</mat-option>
                <mat-option value="+1"> +1 United state +1</mat-option>
                <mat-option value="+44">+44 United Kingdom</mat-option>
                <mat-option value="+971"> +971 United Arab Emirates</mat-option>
                <mat-option value="+92">+92 pakistan</mat-option>
                <mat-option value="+977"> +977 Nepal</mat-option>
            </mat-select>
            <mat-error *ngIf="inputChecking.hasError('required')">You must make a selection</mat-error>
        </mat-form-field>
    </div>

    <mat-form-field class="example-full-width">
        <input matInput type="number" name ="mobile" id="mobile" placeholder="Mobile No." style="box-shadow:none;border:0;background:transparent" [(ngModel)]="model2.mobile" #mobile="ngModel" required>
        <mat-error *ngIf="inputChecking.hasError('required')">
            Number is <b>required</b>
        </mat-error>
    </mat-form-field>

<!--          <mat-form-field class="full-width">-->
<!--              <input matInput type="password" name="confirmpassword1" id="confirmpassword1" [(ngModel)]="model2.confirmpassword1" style="box-shadow:none;border:0;background:transparent" #confirmpassword1="ngModel"  placeholder="Password" required >-->
<!--              <mat-error *ngIf="inputChecking.hasError('required')">-->
<!--                  Password is <b>required</b>-->
<!--              </mat-error>-->
<!--              <mat-error class="error-msg" *ngIf="model2.password !== model2.confirmpassword1">-->
<!--                  Passwords don't match.-->
<!--              </mat-error>-->
<!--          </mat-form-field>-->

    <div style="width:100%">
        <button type="submit" mat-raised-button color="primary" [disabled]="!f.form.valid" id="submit">Register</button><br/><br/>
         Already Registered? <a [routerLink]="['/import-contact-login/'+slug]">Go to Login Page </a>
       
    </div>

</form>
</mat-card>
</div>
<div fxFlex="20"></div>
</div>

</div>
<app-footer></app-footer>
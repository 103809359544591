import * as i0 from "./rss.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../lazy-img.directive";
import * as i3 from "@angular/common";
import * as i4 from "./rss.component";
import * as i5 from "../article.service";
var styles_RssComponent = [".panel_six[_ngcontent-%COMP%] {\n        height: 340px !important;\n        margin-top: 10px !important;\n    }\n\n    @media (max-width:599px) {\n        .panel_six[_ngcontent-%COMP%] {\n            height: 260px !important;\n            margin-top: 10px;\n        }\n        .hd5[_ngcontent-%COMP%] {\n            font-size: 12px !important;\n            font-weight: 400 !important;\n            color:#666;\n        }\n        .pdg1[_ngcontent-%COMP%]{\n            padding:0px 10px ;\n        }\n\n    }", i0.styles];
var RenderType_RssComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RssComponent, data: {} });
export { RenderType_RssComponent as RenderType_RssComponent };
function View_RssComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "col-md-3 col-sm-12 col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "panel_sixx panel_light_grey border_yellow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "img", [["alt", "images"], ["style", "width:100%"]], [[8, "src", 4]], null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.LazyImgDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "pdg1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h4", [["class", "hd4 dis1"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "clearfix"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.feed[_v.context.index]["guest6_image_url"], ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.feed[_v.context.index]["image_url"], ""); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.feed[_v.context.index]["headline"]; _ck(_v, 7, 0, currVal_2); }); }
function View_RssComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row mrg_btm"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RssComponent_3)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.createRange(_co.feed.length); _ck(_v, 2, 0, currVal_0); }, null); }
function View_RssComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "section", [["class", "back2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "section", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RssComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.feed.length > 0); _ck(_v, 4, 0, currVal_0); }, null); }
export function View_RssComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "img", [["src", "../../assets/img/All Doctors2.jpg"], ["style", "width: 100%;"]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i2.LazyImgDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RssComponent_1)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "banner"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.feed.length > 0); _ck(_v, 4, 0, currVal_0); }, null); }
export function View_RssComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-rss", [], null, null, null, View_RssComponent_0, RenderType_RssComponent)), i1.ɵdid(1, 114688, null, 0, i4.RssComponent, [i5.ArticleService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RssComponentNgFactory = i1.ɵccf("app-rss", i4.RssComponent, View_RssComponent_Host_0, {}, {}, []);
export { RssComponentNgFactory as RssComponentNgFactory };

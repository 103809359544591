export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyBegOaO4MOHq0TRJgtcxMHh3as6CRncugI',
    authDomain: 'dpmtrd.firebaseapp.com',
    databaseURL: 'https://dpmtrd.firebaseio.com',
    projectId: 'dpmtrd',
    storageBucket: 'dpmtrd.appspot.com',
    messagingSenderId: '84699580355'
  },
  pusher: {
    key: '49121088c312711b26ca',
  }
};
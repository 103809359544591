import { Component, OnInit } from '@angular/core';
import {
	Meta,
	Title
} from '@angular/platform-browser';
import {
	Http,
	Response
} from "@angular/http";

interface ExcluStory{
	guest6_image_url:string,
	href_link: string,
	small_url:string,
	image_url:string,
	headline:string,
	thumbnail_url1:string,	
  };

@Component({
  selector: 'app-home-left-screen',
  templateUrl: './home-left-screen.component.html',
  styleUrls: ['./home-left-screen.component.css']
})
export class HomeLeftScreenComponent implements OnInit {
  exclusive: any =[];

  exclusive_story:ExcluStory;
  exclusive_paras= [];
  exclusive_paras2= [];
  exclusive_paras3= [];
  exclusive_story2:ExcluStory;
  exclusive_story3:ExcluStory;
  should_not=[];
	sources: Array < Object > ;


  constructor(  private meta: Meta, private titleService: Title,private http: Http) { }
  top_stories:any=[];
  top_storiesa:any;

  url:any;
  ngOnInit() {
    this.titleService.setTitle('COVID-19 Live Updates | TheRightDoctors');
		this.meta.updateTag({
			name: 'description',
			content: 'EXCLUSIVE: COVID 19 Treatment Success Story From SMS Medical College, Jaipur, India' + 'Pulmonology experts perspective on Coronavirus, Dr.Ravindra Mehta' + 'People with Diabetes and Hypertension are more susceptible to COVID-19, Dr. V Mohan' + 'Vitamin C, Hydration, and a healthy diet are key in the fight against coronavirus, Dr.Pradeep Seth' + 'COVID-19: Panic liberates stress hormones leading to HIGH BP, Dr. HK Chopra' + 'COVID 19: Understanding the bugs and breaking some of the myths with Dr. Shashank Joshi' + 'COVID-19 Pandemic: A detailed analysis by leading cardiologists Dr. Ravi Kasliwal and Dr. Hardeep Kaur Grewal' +
				'COVID-19 Treatment Success Story | Dr. Sudhir Bhandari In Conversation With Dr. Anil Pareek' + 'COVID-19 fatalities may be more than 3 times in Diabetic patients | Dr CM Batra' + 'COVID 19: Impact on Pregnancy & Nursing Mothers' + 'The only way to prevent COVID-19 is to flatten the curve of the spread'
		});

		this.meta.updateTag({
			name: 'keywords',
			content: 'EXCLUSIVE: COVID 19 Treatment Success Story From SMS Medical College, Jaipur, India' +
				'COVID-19 Treatment' + 'Coronavirus' + 'Coronavirus Pregnancy' + 'Coronavirus heart diseases' + 'Coronavirus live updates' + '21 days lockdown'
		});

		this.sources = new Array < Object > ();
		this.sources.push({
			src: "https://storage.googleapis.com/web-assets/RSSDI_2019/Day-1/Day-0-and-1_360.mp4",
			type: "video/mp4"
		});
	
	this.exclusiveStory();
	this.get_topStories();
   
  }
	get_topStories() {
		this.url = 'https://therightdoctors.com/api/beta/article?is_top_story=true&event=corona-virus&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4&limit=15'
		this.http.get(this.url)
			.map((res: Response) => res.json()).subscribe(data => {
				if (data['success']) {
					this.top_storiesa = data['data'];
					console.log("top stories data ",this.top_storiesa);
					// this.top_storiesa.sort(function (a, b) {
					// 	a = new Date(a.created_at);
					// 	b = new Date(b.created_at);
					// 	return a > b ? -1 : a < b ? 1 : 0;
					// });
					// console.log('LOL', this.top_storiesa);
					// this.story_to_show_according_to_day();

					// this.shuffleArray(this.top_stories);
					for (let i = 0; i < 15; i++) {
						if(! this.should_not.includes(this.top_storiesa[i]['slug'])){
										if (this.top_storiesa[i]['category'] == 'cme') {
											this.top_storiesa[i]['href_link'] = 'https://therightdoctors.com/insights/cme/' + this.top_storiesa[i]['blog_category'] + '/' + this.top_storiesa[i]['slug'];
										} else if (this.top_storiesa[i]['category'] == 'podcast') {
											this.top_storiesa[i]['href_link'] = 'https://therightdoctors.com/podcast/'+ this.top_storiesa[i]['event']+ '/' + this.top_storiesa[i]['slug'];
										} else {
											this.top_storiesa[i]['href_link'] = 'https://therightdoctors.com/' + this.top_storiesa[i]['event'] + '/' + this.top_storiesa[i]['category'] + '/' + this.top_storiesa[i]['slug'];
										}
										this.top_stories.push(this.top_storiesa[i]);
										
						}
					}

				}

			})
	}

	// story_to_show_according_to_day(){

	// }
		  
	
	shuffleArray(array) {
			for (var i = array.length - 1; i > 0; i--) {
				var j = Math.floor(Math.random() * (i + 1));
				var temp = array[i];
				array[i] = array[j];
				array[j] = temp;
			}
	}



  	exclusiveStory() {
		this.url = 'https://therightdoctors.com/api/beta/article?category=cme&is_exclusive=true&limit=6&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4'
		// this.url='https://therightdoctors.com/api/beta/article?category=cme&blog_category=column&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4'
		this.http.get(this.url)
			.map((res: Response) => res.json()).subscribe(data => {
				if (data['success']) {
					this.exclusive = data['data'];
				
					// console.log('excllllll_creatd',this.exclusive);

					// this.exclusive.sort(function (a, b) {
					// 	a = new Date(a.created_at);
					// 	b = new Date(b.created_at);
					// 	return a > b ? -1 : a < b ? 1 : 0;
					// });
					// console.log('excllllll_updated',this.exclusive);
					for (let i = 0; i < 3; i++) {
						if (this.exclusive[i]['category'] == 'cme') {
							this.exclusive[i]['href_link'] = 'https://therightdoctors.com/insights/cme/' + this.exclusive[i]['blog_category'] + '/' + this.exclusive[i]['slug'];
						} else if (this.exclusive['category'] == 'podcast') {
							this.exclusive[i]['href_link'] = 'https://therightdoctors.com/podcast/' + this.exclusive[i]['event'] + '/' + this.exclusive[i]['slug'];
						} else {
							this.exclusive[i]['href_link'] = 'https://therightdoctors.com/' + this.top_storiesa[i]['event'] + '/' + this.exclusive[i]['category'] + '/' + this.exclusive[i]['slug'];
						}
					}
					this.exclusive_story = this.exclusive[0];
					this.exclusive_story2 = this.exclusive[1];
					this.exclusive_story3 = this.exclusive[2];
					// console.log(this.exclusive_story3);
					this.should_not.push(this.exclusive[0]['slug']);
					this.should_not.push(this.exclusive[1]['slug']);
					// console.log(this.exclusive_story[2]['slug']);
					this.should_not.push(this.exclusive[2]['slug']);
			
				}
			})
			



	
	
		}
	}

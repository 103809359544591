import * as i0 from "./magzine.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../node_modules/ng2-pdf-viewer/ng2-pdf-viewer.ngfactory";
import * as i5 from "ng2-pdf-viewer";
import * as i6 from "./magzine.component";
import * as i7 from "../loginservice.service";
import * as i8 from "@angular/router";
import * as i9 from "@angular/platform-browser";
import * as i10 from "@angular/forms";
var styles_MagzineComponent = [i0.styles];
var RenderType_MagzineComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MagzineComponent, data: {} });
export { RenderType_MagzineComponent as RenderType_MagzineComponent };
export function View_MagzineComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["fxFlex.gt-sm", "75%"], ["fxFlex.lt-md", "100%"], ["fxFlex.lt-sm", "100%"]], null, null, null, null, null)), i1.ɵdid(2, 737280, null, 0, i2.FlexDirective, [i3.MediaMonitor, i1.ElementRef, [3, i2.LayoutDirective], i3.StyleUtils, [2, i3.ADD_FLEX_STYLES]], { flexGtSm: [0, "flexGtSm"], flexLtSm: [1, "flexLtSm"], flexLtMd: [2, "flexLtMd"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "background-know"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["fxLayout", "row"], ["fxLayout.lt-md", "column"]], null, null, null, null, null)), i1.ɵdid(6, 737280, null, 0, i2.LayoutDirective, [i3.MediaMonitor, i1.ElementRef, i3.StyleUtils], { layout: [0, "layout"], layoutLtMd: [1, "layoutLtMd"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["fxFlex", "10"]], null, null, null, null, null)), i1.ɵdid(8, 737280, null, 0, i2.FlexDirective, [i3.MediaMonitor, i1.ElementRef, [3, i2.LayoutDirective], i3.StyleUtils, [2, i3.ADD_FLEX_STYLES]], { flex: [0, "flex"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "page-content-sliders"], ["fxFlex", "80"]], null, null, null, null, null)), i1.ɵdid(10, 737280, null, 0, i2.FlexDirective, [i3.MediaMonitor, i1.ElementRef, [3, i2.LayoutDirective], i3.StyleUtils, [2, i3.ADD_FLEX_STYLES]], { flex: [0, "flex"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "pdf-viewer", [["style", "display: block;"]], null, [["window", "resize"], [null, "textlayerrendered"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onPageResize() !== false);
        ad = (pd_0 && ad);
    } if (("textlayerrendered" === en)) {
        var pd_1 = (i1.ɵnov(_v, 12).onTextLayerRendered($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_PdfViewerComponent_0, i4.RenderType_PdfViewerComponent)), i1.ɵdid(12, 770048, null, 0, i5.PdfViewerComponent, [i1.ElementRef], { src: [0, "src"], renderText: [1, "renderText"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "75%"; var currVal_1 = "100%"; var currVal_2 = "100%"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "row"; var currVal_4 = "column"; _ck(_v, 6, 0, currVal_3, currVal_4); var currVal_5 = "10"; _ck(_v, 8, 0, currVal_5); var currVal_6 = "80"; _ck(_v, 10, 0, currVal_6); var currVal_7 = _co.pdfSrc; var currVal_8 = true; _ck(_v, 12, 0, currVal_7, currVal_8); }, null); }
export function View_MagzineComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-magzine", [], null, null, null, View_MagzineComponent_0, RenderType_MagzineComponent)), i1.ɵdid(1, 114688, null, 0, i6.MagzineComponent, [i7.LoginserviceService, i8.ActivatedRoute, i9.Meta, i10.FormBuilder, i9.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MagzineComponentNgFactory = i1.ɵccf("app-magzine", i6.MagzineComponent, View_MagzineComponent_Host_0, {}, {}, []);
export { MagzineComponentNgFactory as MagzineComponentNgFactory };

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


@Component({
  selector: 'single-popup',
  templateUrl: 'single-popup.component.html',
})

export class SinglePopup {
  model: any ={};
  constructor(
    public dialogRef: MatDialogRef<SinglePopup>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  // onNoClick(): void {
  //   this.dialogRef.close();
  // }
  close() {
    this.dialogRef.close();
  }
}


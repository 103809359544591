<style>
    .back-2 {
        /*background:#f8f8f8;*/
    }

    .btu-google {
        padding: 10px 24px;
        margin-bottom: 10px;
        background-color: #F44336;
    }

    .btu-facebook {
        padding: 10px 24px;
        margin-bottom: 10px;
        background-color: #3F51B5;
    }

    .login-button {
        padding: 10px 24px;
        background-color: #3f51b5;
        text-align: center;
        margin-bottom: 10px;
    }

    .login {
        text-align: center;
        background: #f3f3f3;
        color: #000;
        padding: 6px;
        font-size: 23px;
        margin-bottom: 20px;
    }

    input {
        border: none;
        box-shadow: none;
    }
</style>

<div class="container" style="margin-top: 10%;">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
        <div fxFlex="50%" fxShow.lt-md="false" class="back-2" align="center">
            <img src="../../assets/new-home-page/images/logo.jpg" />
        </div>
        <div fxFlex="50%" align="center">

            <h2 class="login">Reset Password</h2>
            <form role="form" (ngSubmit)="register()" #ff="ngForm" novalidate class="example-form">

                <mat-form-field class="example-full-width">
                    <!-- <input matInput placeholder="New Password" type="password" name="password"
                        [(ngModel)]="model2.password" #password="ngModel"> -->
                    <div class="pass">
                        <input matInput placeholder="New Password" [type]="hidePassword ? 'password' : 'text'"
                            name="password" [(ngModel)]="model2.password" #password="ngModel" >
                        <button mat-icon-button type="button" (click)="togglePasswordVisibility()">
                            <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                        </button>
                    </div>
                </mat-form-field>

                <mat-form-field class="example-full-width">
                    <!-- <input matInput placeholder="Confirm New Password" type="confirmpassword" name="confirmpassword"
                        [(ngModel)]="model2.confirmpassword" #confirmpassword="ngModel" required> -->
                    <div class="pass">
                        <input matInput placeholder="Confirm New Password" [type]="hidePassword ? 'password' : 'text'"
                            name="confirmpassword" [(ngModel)]="model2.confirmpassword" #password="ngModel" required>
                        <button mat-icon-button type="button" (click)="togglePasswordVisibility()">
                            <mat-icon>{{ hideConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                        </button>
                    </div>
                </mat-form-field>
          
                <br />
                <!-- <p style="margin-bottom:20px;"><a href="#" style="cursor: pointer;text-decoration: underline!important;">forgot Password</a></p> -->
                <button type="submit" color="primary" class="login-button">Change Password</button>

            </form>

        </div>


    </div>
</div>
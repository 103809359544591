<div class="background-know">

  <div class="container" >
    <div fxLayout="row" fxLayout.lt-md="column" >
      <div fxFlex="10">
      </div>
      <div fxFlex="80" class="page-content-sliders">
        <pdf-viewer [src]="pdfSrc" 
        [render-text]="true"
        style="display: block;"
></pdf-viewer>

        </div>
        <div fxFlex="10">
        </div>
  </div>
</div>
</div>
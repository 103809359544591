<style>
    .vid_side_box_inner {
        padding: 0 0 5px 0 !important;
        margin: 5px 0 0 !important;
        border-bottom: 1px solid #eee;
    }
</style>
<div class="new-screen2">
    <div class="container">
        <div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="20px" class="display-mob">
            <div fxFlex.lt-md="100%" fxFlex.gt-sm="33.33%" fxLayout="column" class="load-screen">
                <div fxLayout="column" class="internal3 one-section">
                    <h5 class="all-head">{{this.block1_category}}</h5>
                    <ng-template ngFor let-item [ngForOf]="block1_data" let-currentIndex="index">
                        <div *ngIf="currentIndex < 1">
                            <a href="{{this.block1_link}}{{this.block1_data[currentIndex]['slug']}}" style="padding-bottom: 15px;">
                                <div fxLayout="column" class="brdr-btm">
                                    <div class="mrg-after-imgvid">
                                        <picture>
                                            <source type="image/webp" srcset="{{block1_data[currentIndex]['thumbnail_url1']}}">
                                            <img loading="lazy" src="{{block1_data[currentIndex]['image_url']}}" alt="Feature from TheRightDoctors" style="width:100%">
                                        </picture>

                                    </div>
                                    <div fxLayout="column">
                                        <p class="internal-dr-name">{{block1_data[currentIndex]['sub_headline']}}</p>
                                        <p class="internal-updates-head">{{block1_data[currentIndex]['headline'] | slice: 0 : 80}}</p>
                                        <!-- <p class="internal-para">
                    {{block1_data[currentIndex]['transcript_list'][0]['question'] | slice: 0 : 100}}...<a
                    href="{{this.block1_link}}{{this.block2_data[currentIndex]['slug']}}" style="color:red;">Read
                    More</a></p> -->
                                    </div>
                                </div>
                            </a>
                        </div>
                    </ng-template>
                    <!-- <div fxLayout="column" class="brdr-btm"> -->
                    <!-- <div fxLayout.lt-sm="column" fxLayout.gt-xs="row" fxLayoutGap="16px"> -->
                    <!-- <ng-template ngFor let-item [ngForOf]="block1_data" let-currentIndex="index+1">
                    <div *ngIf="currentIndex < 2">
                      <a href="{{this.block1_link}}{{this.block1_data[currentIndex]['slug']}}" style="padding-bottom: 15px;">
                        <div fxLayout.lt-sm="column" fxLayout.gt-xs="row" fxlayoutGap.lt-sm="8px" fxLayoutGap.gt-xs="8px">
                          <div fxFlex.lt-sm="100%" fxFlex.gt-xs="30%">
                            <img src="{{block1_data[currentIndex]['image_url']}}" style="width:100%">
                          </div>
                          <div fxFlex.lt-sm="100%" fxFlex.gt-xs="70%">
                            <p class="internal-dr-name">{{block1_data[currentIndex]['sub_headline']}}</p>
                            <p class="internal-updates-head">{{block1_data[currentIndex]['headline']}}</p>
                          </div>
                        </div>
                      </a>
                  </div>
                  </ng-template> -->
                    <!-- </div> -->
                    <!-- </div> -->
                    <!-- <ul fxLayout="column"> -->
                    <!-- <div ngFor let-item [ngForOf]="block1_data" let-i="index"> -->
                    <!-- <ng-template ngFor let-item [ngForOf]="block1_data" let-i="index+2">

                <div *ngIf="i < 5">
                  <li class="brdr-btm">
                      <a href ="{{block1_data[i]['href_link']}}">
                          <p class="internal-dr-name">{{block1_data[i]['sub_headline']}}
                          </p>
                          <p class="internal-updates-head">{{block1_data[i]['headline']}}</p>
                      </a>
                  </li>
                </div>
              </ng-template> -->
                    <!-- </ul> -->
                </div>
            </div>
           
            <!-- screen 2 ends -->
            <div *ngIf="overall" fxFlex.lt-md="100%" fxFlex.gt-sm="40%" fxLayout="column"  class="load-screen">
                <div fxLayout="column">

                    <div fxLayout="column" class="one-section one-section-des">
                        <h5 class="all-head"><span class="circle red"></span>LIVE UPDATES</h5>

                              <ng-template ngFor let-item [ngForOf]="createRange(final_model.length)" let-i="index">

                        <a [routerLink]="final_model[i]['category']">
                            <div  class="live_updates" fxLayout="row" fxLayoutGap="8px">
                                <div *ngIf = "final_model[i]['image']" fxFlex="30%">
                                    <img loading="lazy" src="{{final_model[i]['image']}}" alt="COVID 19">
                                </div>
                                <div fxFlex="70%">
                                    <p class="internal-updates-head">
                                        <!-- <span style="color: #e27520;">Coronavirus LIVE</span>: -->
                                         {{final_model[i]['title']}}
                                    </p>
                                </div>
                            </div>
                        </a>
                        <br>

                       </ng-template>
                    </div>
                  
                </div>
            </div>

            <div *ngIf="!overall" fxFlex.lt-md="100%" fxFlex.gt-sm="40%" fxLayout="column"  class="load-screen">
                <div fxLayout="column">
                    <h5 class="all-head">Story</h5>
                    <ng-template ngFor let-item [ngForOf]="top_storiesa" let-currentIndex="index+5">
                        <div *ngIf="currentIndex == 5">
                            <a href="{{this.block1_link}}{{this.top_storiesa[currentIndex]['slug']}}" style="padding-bottom: 15px;">
                                <div fxLayout="column" class="brdr-btm">
                                    <div class="mrg-after-imgvid">
                                        <picture>
                                            <source type="image/webp" srcset="{{top_storiesa[currentIndex]['thumbnail_url1']}}">
                                            <img loading="lazy" src="{{top_storiesa[currentIndex]['image_url']}}" alt="Feature from TheRightDoctors" style="width:100%">
                                        </picture>

                                    </div>
                                    <div fxLayout="column">
                                        <p class="internal-dr-name">{{top_storiesa[currentIndex]['sub_headline']}}</p>
                                        <p class="internal-updates-head">{{top_storiesa[currentIndex]['headline'] | slice: 0 : 80}}</p>
                                        <!-- <p class="internal-para">
                    {{block1_data[currentIndex]['transcript_list'][0]['question'] | slice: 0 : 100}}...<a
                    href="{{this.block1_link}}{{this.block2_data[currentIndex]['slug']}}" style="color:red;">Read
                    More</a></p> -->
                                    </div>
                                </div>
                            </a>
                        </div>
                    </ng-template>

                </div>
            </div>


            <div fxFlex.lt-md="100%" fxFlex.gt-sm="33.33%" fxLayout="column"  class="load-screen">
                <div fxLayout="column">
                    <h5 class="all-head">streaming now</h5>
                    <div fxLayout="column" class="brdr-btm">
                        <div class="mrg-after-imgvid">
                            <video preload="none" class="img-resp" (ended)="nextVideo()" *ngFor="let video of sources" style="width:100%" poster="{{liveUpdatesVideo[0]['image_url']}}" controls>
                <source [src]="video.src" type="video/mp4">
                </video>
                        </div>
                        <p class="internal-dr-name">{{liveUpdatesVideo[0]['sub_headline']}}</p>
                        <p class="internal-updates-head">{{liveUpdatesVideo[0]['headline']}}</p>
                    </div>
                   
                </div>

            </div>
        </div>
    </div>
</div>

import {map} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Http,Response } from '@angular/http';
import {
	IMedia
} from "../live/live.component";
import {
	AngularFireList
} from 'angularfire2/database';
import {
	Live_Details
} from "../live_details";
import { FirebaseServiceService } from '../firebase-service.service';
import { CatagoryServiceService } from '../catagory-service.service';

@Component({
  selector: 'app-new-screen-two',
  templateUrl: './new-screen-two.component.html',
  styleUrls: ['./new-screen-two.component.css']
})
export class NewScreenTwoComponent implements OnInit {
	articles: any;
	inc: number = 0;
	count:number=0;
	blogdata_c: any;

	audio_list1: object[] = [];
	video_list: any = [];
	conv_image: any;
	imagesSrcs = [];
	audio_list5: object[] = [];

	routerLinks = [];
	currentItem: IMedia = this.video_list[this.inc];
	catagory: any;
	// live_details: AngularFireList<Live_Details[]>;
	public now: Date = new Date();
	ld: any;
	conv: any;
	third_live:any;
	second_live:any;
	first_live:any;
	overall:any;
	finaldata: object[] = [];
	finaldata_cardio: object[]=[];
	finaldata_diab: object[]=[];
	finaldata_other: object[] = [];
	finaldata_oye: object[] = [];
	data_we_need: object[] = [];
	data_final: object[] = [];
	lastdata: object[] = [];
	d: any;
	d1:any;
	d3:any;
	d1f_category:any;
	d2f_category:any;
	d3f_category:any;
	d1f_title:any;
	d2f_title:any;
	d3f_title:any;
	d1f_image_url:any;
	d2f_image_url:any;
	d3f_image_url:any;

	h1: string;
	h2: string;
	h3: string;
	finaldata_all: object[] = [];
	private dbPath = '/live-updates';
	//private dbPath = '/live_updates';
	customersRef: AngularFireList < Live_Details > = null;
	isplay: boolean = true;
	isplay2: boolean = true;
	final_model:object[]=[];
	url: any;
	blogdata: any;
	blogdata_corna: any;
	corona_v: any;
	finaldata1 = [];
	cat = [];
	top_stories:any=[];
  	top_storiesa:any;

	event: string;
	finaldata2 = [];
	finaldata3 = [];
	audio_list: any;
	finaldata_two: object[] = [];
	audio_list_two: object[] = [];
	exclusive: any;
	exclusive_story: any;
	main_block2: any;
	block2_category: any;
	block2_data: any;
	url2: string;
	block2_link: string;
	main_block3: any;
	block3_category: any;
	block3_data: any;
	intr: object[] = [];
	new_categs:object[]=[];
	url1: string;

	block3_link: string;
	main_block4: any;
	block4_category: any;
	block4_data: any;
	block4_link: string;
	//top_stories: any;
	block1_category: any;
	main_block1: any;
	block1_data: any;
	block1_link: string;
  exclusive_paras = [];
  sources: Array < Object > ;
	d2: object;r
	liveUpdatesVideo:any=[];
	//ld: any;
  constructor( 	private fbs: FirebaseServiceService,private http: Http,private service: CatagoryServiceService) { }

  ngOnInit() {
	this.get_topStories()
    this.get_block1();
	this.get_live_category();
		this.sources = new Array < Object > ();
// 		this.sources.push({
// 			src: "https://storage.googleapis.com/web-assets/Coronavirus-2020/Shashank-Joshi/Shashank-Joshi-Special-Edit-Video/Shashank%20Joshi_1.mp4",
// type: "video/mp4"
// 		});


    this.service.VideoForLiveStreamingHomePage().subscribe(
      data => {
        this.liveUpdatesVideo = data['data'];

        this.sources = new Array<Object>();
            /*this.isvalid = catagory ;*/
            this.sources.push({
              src: this.liveUpdatesVideo[0]['video_url'],
              type: 'video/mp4'
            });
    // console.log("Sources" , this.sources);
        // console.log('I CANT SEE DATA HERE in single componenet next video : ', this.liveUpdatesVideo);
      }
    );
    


		this.fbs.onGetUsersDocuments().subscribe(data => {
			// console.log("In Function Data")

			this.conv = data;
			let islive1 = false;
			for (var j = 0; j < data.length; j++) {
		
				this.finaldata_all.push(data[j]);
				//  console.log("AAAAAAAA",data[j]['f_category']);
				// alert("AAAAAAAA"+this.finaldata_all);
		
				if ( data[j]['is_home_live'] == 'Yes') {
					islive1 = true;
					this.finaldata.push(data[j]);
				} 
				
			}
			// console.log(this.finaldata_all);
			// console.log("final data",this.finaldata);
			
		
				// this.d1 = this.finaldata_all[this.finaldata_all.length - 2];
                // console.log("NewScreenTwoComponent -> ngOnInit -> this.d1", this.d1)
				
				this.d3 = this.finaldata[this.finaldata.length - 1];
			
				// this.d1f_category='/'+this.d1['f_category'].toLowerCase()+'/live-updates';
				// this.d1f_title=this.d1['f_title'];
				// this.d1f_image_url=this.d1['f_image_url'];
				// this.d2f_category='/'+this.d2['f_category'].toLowerCase()+'/live-updates';
				// this.d2f_title=this.d2['f_title'];
				// this.d2f_image_url=this.d2['f_image_url']
				this.d3f_category='/'+this.d3['f_category'].toLowerCase()+'/live-updates';
				this.d3f_title=this.d3['f_title'];
				this.d3f_image_url=this.d3['f_image_url'];
				
			if (islive1 == false) {
				this.d = this.finaldata_all[data.length - 1];
				console.log("I CAN SEE EEEEEEEEE", this.d);
			} else {
				this.d = this.finaldata[this.finaldata.length - 1];

			}
			if (this.finaldata_other.length == 0) {
				for (let i = this.finaldata_oye.length - 1; i > this.finaldata_oye.length - 3; i--)
					this.data_we_need.push(this.finaldata_oye[i]);
			} else if (this.finaldata_other.length == 1) {
				this.data_we_need.push(this.finaldata_oye[this.finaldata_oye.length - 1]);
			} else if (this.finaldata_other.length > 1) {
				for (let i = this.finaldata_other.length - 1; i > this.finaldata_other.length - 3; i--)
					this.data_we_need.push(this.finaldata_other[i]);
			}

			if (this.data_we_need[0]['f_category'] == this.data_we_need[1]['f_category']) {
				for (let i = this.finaldata_oye.length - 1; i > 0; i--) {
					if (this.data_we_need[0]['f_category'] != this.finaldata_oye[i]['f_category']) {
						this.data_we_need[1] = this.finaldata_oye[i];
						break;
					}

				}

			}
			this.data_final.push(this.data_we_need[this.data_we_need.length - 1])
			this.data_final.push(this.data_we_need[this.data_we_need.length - 2])

			console.log("ATTT", this.data_final)
			this.h1 = this.d['f_category'].toLowerCase();
			this.h2 = this.data_we_need[0]['f_category'].toLowerCase();
			this.h3 = 'https://therightdoctors.com/' + this.data_we_need[1]['f_category'].toLowerCase() + '/live-updates';

			// console.log("AASSAS", this.finaldata_all[2]['f_category']); 


			console.log('datafinal', this.conv);
			console.log('datafinalfj ', this.finaldata);
		});




		this.fbs.onGetUsersDocuments2().subscribe(data => {
			// console.log("In Function Data")

			this.conv = data;
			let islive1 = false;
			for (var j = 0; j < data.length; j++) {
		
				this.finaldata_all.push(data[j]);
				if (data[j]['is_home_live'] == 'Yes') {
					this.finaldata_cardio.push(data[j]);
				} 
				
			}
			
			// console.log("final data cardio",this.finaldata_cardio);
			
				this.d2 = this.finaldata_cardio[this.finaldata_cardio.length - 1];
				
				this.d2f_category='/'+this.d2['f_category'].toLowerCase()+'/live-updates';
				this.d2f_title=this.d2['f_title'];
				this.d2f_image_url=this.d2['f_image_url']
				
		});



		this.fbs.onGetUsersDocuments3().subscribe(data => {
			// console.log("In Function Data")

			this.conv = data;
			let islive1 = false;
			for (var j = 0; j < data.length; j++) {
	
				if(data[j]['is_home_live'] == 'Yes')
				{
					this.finaldata_diab.push(data[j]);
				}
				
			}
			
			// console.log("final data diabetes",this.finaldata_diab);

				this.d1 = this.finaldata_diab[this.finaldata_diab.length - 1];

				this.d1f_category='/'+this.d1['f_category'].toLowerCase()+'/live-updates';
				this.d1f_title=this.d1['f_title'];
				this.d1f_image_url=this.d1['f_image_url'];
	
		});













  }



  get_topStories() {
	this.url = 'https://therightdoctors.com/api/beta/article?is_top_story=true&event=corona-virus&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4&limit=15'
	this.http.get(this.url)
		.map((res: Response) => res.json()).subscribe(data => {
			if (data['success']) {
				this.top_storiesa = data['data'];
				
				// console.log('LOL2', this.top_storiesa);
				// this.story_to_show_according_to_day();

				// this.shuffleArray(this.top_stories);
				// for (let i = 0; i < 15; i++) {
				// 	if(! this.should_not.includes(this.top_storiesa[i]['slug'])){
				// 					if (this.top_storiesa[i]['category'] == 'cme') {
				// 						this.top_storiesa[i]['href_link'] = 'https://therightdoctors.com/insights/cme/' + this.top_storiesa[i]['blog_category'] + '/' + this.top_storiesa[i]['slug'];
				// 					} else if (this.top_storiesa[i]['category'] == 'podcast') {
				// 						this.top_storiesa[i]['href_link'] = 'https://therightdoctors.com/podcast/' + this.top_storiesa[i]['slug'];
				// 					} else {
				// 						this.top_storiesa[i]['href_link'] = 'https://therightdoctors.com/' + this.top_storiesa[i]['event'] + '/' + this.top_storiesa[i]['category'] + '/' + this.top_storiesa[i]['slug'];
				// 					}
				// 					this.top_stories.push(this.top_storiesa[i]);
									
				// 	}
				// }

			}

		})
}

  get_live_category()
  {
	this.service.get_live_category02().subscribe(data => {
		
			// console.log("category data",data)
			var data2 = data['data'];
			for(var i =0; i<data2.length; i++ ){
				// if(data2[i]['category'] == 'coronavirus'){
				// 	this.corona_live = true;
				// }
				// if(data2[i]['category'] == 'cardiology'){
				// 	this.cardio_live = true;
				// }
				// if(data2[i]['category'] == 'diabetes'){
				// 	this.diab_live = true;
				// }
				// if(data2[i]['category'] == 'overall'){
				// 	this.overall=true;
				// }
				// if(data2[i]['key']){
					this.new_categs.push(data2[i]);
				// }
			}

			// console.log("new categsssss",this.new_categs);
			for(var i =0; i<this.new_categs.length; i++ ){
				if(this.new_categs[i]['category']=='overall'){
					// alert("aaaa")
					this.overall =true;
					continue;
				}
				// alert(this.count)
				if(this.count<3){
						if(this.new_categs[i]['category']=='coronavirus'){
							this.count++;
							this.fbs.onGetUsersDocuments().subscribe(data => {
								// console.log("In Function Data")
					
								this.conv = data;
								let islive1 = false;
								for (var j = 0; j < data.length; j++) {
							
									this.finaldata_all.push(data[j]);
									// console.log("AAAAAAAA",data[j]['f_category']);
									// alert("AAAAAAAA"+this.finaldata_all);
							
									if ( data[j]['is_home_live'] == 'Yes') {
										islive1 = true;
										this.finaldata.push(data[j]);
									} 
									
								}
								// console.log(this.finaldata_all);
								// console.log("final data",this.finaldata);

									// this.d1 = this.finaldata_all[this.finaldata_all.length - 2];
									// console.log("NewScreenTwoComponent -> ngOnInit -> this.d1", this.d1)
									
									this.d3 = this.finaldata[this.finaldata.length - 1];
								
									// this.d1f_category='/'+this.d1['f_category'].toLowerCase()+'/live-updates';
									// this.d1f_title=this.d1['f_title'];
									// this.d1f_image_url=this.d1['f_image_url'];
									// this.d2f_category='/'+this.d2['f_category'].toLowerCase()+'/live-updates';
									// this.d2f_title=this.d2['f_title'];
									// this.d2f_image_url=this.d2['f_image_url']
									this.d3f_category='/'+this.d3['f_category'].toLowerCase()+'/live-updates';
									this.d3f_title=this.d3['f_title'];
									this.d3f_image_url=this.d3['f_image_url'];
									var model = {category:this.d3f_category, title: this.d3f_title, image:this.d3f_image_url};
									this.final_model.push(model);
									
								if (islive1 == false) {
									this.d = this.finaldata_all[data.length - 1];
									// console.log("I CAN SEE EEEEEEEEE", this.d);
								} else {
									this.d = this.finaldata[this.finaldata.length - 1];
					
								}
								if (this.finaldata_other.length == 0) {
									for (let i = this.finaldata_oye.length - 1; i > this.finaldata_oye.length - 3; i--)
										this.data_we_need.push(this.finaldata_oye[i]);
								} else if (this.finaldata_other.length == 1) {
									this.data_we_need.push(this.finaldata_oye[this.finaldata_oye.length - 1]);
								} else if (this.finaldata_other.length > 1) {
									for (let i = this.finaldata_other.length - 1; i > this.finaldata_other.length - 3; i--)
										this.data_we_need.push(this.finaldata_other[i]);
								}
					
								if (this.data_we_need[0]['f_category'] == this.data_we_need[1]['f_category']) {
									for (let i = this.finaldata_oye.length - 1; i > 0; i--) {
										if (this.data_we_need[0]['f_category'] != this.finaldata_oye[i]['f_category']) {
											this.data_we_need[1] = this.finaldata_oye[i];
											break;
										}
					
									}
					
								}
								this.data_final.push(this.data_we_need[this.data_we_need.length - 1])
								this.data_final.push(this.data_we_need[this.data_we_need.length - 2])
					
								// console.log("ATTT", this.data_final)
								this.h1 = this.d['f_category'].toLowerCase();
								this.h2 = this.data_we_need[0]['f_category'].toLowerCase();
								this.h3 = 'https://therightdoctors.com/' + this.data_we_need[1]['f_category'].toLowerCase() + '/live-updates';
					
								// console.log("AASSAS", this.finaldata_all[2]['f_category']); 
					
					
								// console.log('datafinal', this.conv);
								// console.log('datafinalfj ', this.finaldata);
							});
					
						}
						else if(this.new_categs[i]['category']=='cardiology'){
							this.count++;
							this.fbs.onGetUsersDocuments2().subscribe(data => {
								// console.log("In Function Data")
					
								this.conv = data;
								let islive1 = false;
								for (var j = 0; j < data.length; j++) {
							
									this.finaldata_all.push(data[j]);
									if (data[j]['is_home_live'] == 'Yes') {
										this.finaldata_cardio.push(data[j]);
									} 
									
								}
								
								// console.log("final data cardio",this.finaldata_cardio);
								
									this.d2 = this.finaldata_cardio[this.finaldata_cardio.length - 1];
									
									this.d2f_category='/'+this.d2['f_category'].toLowerCase()+'/live-updates';
									this.d2f_title=this.d2['f_title'];
									this.d2f_image_url=this.d2['f_image_url']
									var model = {category:this.d2f_category, title: this.d2f_title, image:this.d2f_image_url};
									this.final_model.push(model);
									
							});
						}

						else if(this.new_categs[i]['category']=='diabetes'){
							this.count++;
							this.fbs.onGetUsersDocuments3().subscribe(data => {
								// console.log("In Function Data")
					
								this.conv = data;
								let islive1 = false;
								for (var j = 0; j < data.length; j++) {
						
									if(data[j]['is_home_live'] == 'Yes')
									{
										this.finaldata_diab.push(data[j]);
									}
									
								}
								
								// console.log("final data diabetes",this.finaldata_diab);
					
									this.d1 = this.finaldata_diab[this.finaldata_diab.length - 1];
					
									this.d1f_category='/'+this.d1['f_category'].toLowerCase()+'/live-updates';
									this.d1f_title=this.d1['f_title'];
									this.d1f_image_url=this.d1['f_image_url'];
									var model = {category:this.d1f_category, title: this.d1f_title, image:this.d1f_image_url};
									this.final_model.push(model);
						
							});
						}
						else{
							this.count++;
							var finaldata_diab=[];
							this.fbs.onGetUsersDocuments4(this.new_categs[i]['key']).subscribe(data => {
								this.conv = data;
								let islive1 = false;
								for (var j = 0; j < data.length; j++) {
						
									if(data[j]['is_home_live'] == 'Yes')
									{
										finaldata_diab.push(data[j]);
									}
									
								}
								
								// console.log("final data new categories",finaldata_diab);
					
									this.d1 = finaldata_diab[finaldata_diab.length - 1];
					
									this.d1f_category='/'+this.d1['f_category']+'/live-updates';
									this.d1f_title=this.d1['f_title'];
									this.d1f_image_url=this.d1['f_image_url'];
									var model = {category:this.d1f_category, title: this.d1f_title, image:this.d1f_image_url};
									this.final_model.push(model);
							});
						}
					}
				
				}

	})

  }

  createRange(number) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }

	get_block1() {
		/// Need to code.
		this.url = 'https://therightdoctors.com/api/beta/article?&homepage_block=b1&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4&limit=5'
		this.http.get(this.url).pipe(
			map((res: Response) => res.json())).subscribe(data => {
				if (data['success']) {

					this.main_block1 = data['data'];
					this.main_block1.sort(function (a, b) {
						a = new Date(a.created_at);
						b = new Date(b.created_at);
						return a > b ? -1 : a < b ? 1 : 0;
					});
					this.main_block1 = this.main_block1[0];

				}
				this.block1_category = 'feature';
				if (this.block1_category == 'feature') {
					this.main_block1['blog_category'] ='feature';
					this.url2 = 'https://therightdoctors.com/api/beta/article?category=cme&is_exclusive=false&blog_category=feature&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4&limit=5';
					this.http.get(this.url2).pipe(
						map((res: Response) => res.json())).subscribe(data2 => {
							if (data2['success']) {
								this.block1_data = data2['data'];
								this.block1_data.sort(function (a, b) {
									a = new Date(a.created_at);
									b = new Date(b.created_at);
									return a > b ? -1 : a < b ? 1 : 0;
								});
								// this.block2_data = this.block2_data.reverse(); 
								// console.log('Block1 DATA ::: ', this.block1_data);
								for (let i = 0; i < this.block1_data.length; ++i) {
									// if(this.block1_data.lenght-1 >= i){
									this.block1_data[i]['href_link'] = 'https://therightdoctors.com/insights/cme/' + this.main_block1['blog_category'] + '/' + this.block1_data[i]['slug'];
								}
								// }
							}
						})
					this.block1_link = 'https://therightdoctors.com/insights/cme/' + this.main_block1['blog_category'] + '/';
				} else if (this.block1_category == 'podcast') {
					this.block1_category = 'Podcast'
					this.url2 = 'https://therightdoctors.com/api/beta/article?category=podcast&event=' + this.main_block1['event'] + '&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4';
					this.http.get(this.url2).pipe(
						map((res: Response) => res.json())).subscribe(data2 => {
							if (data['success']) {
								this.block1_data = data2['data'];
								this.block1_data.sort(function (a, b) {
									a = new Date(a.created_at);
									b = new Date(b.created_at);
									return a > b ? -1 : a < b ? 1 : 0;
								});
								// this.block2_data = this.block2_data.reverse(); 
								console.log('Block1 DATA ::: ', this.block1_data);
								for (let i = 0; i < this.block1_data.length; ++i) {
									// if(this.block1_data.lenght-1 >= i){
									this.block1_data[i]['href_link'] = 'https://therightdoctors.com/podcast/' + this.block1_data[i]['slug'];
								}
								// } 
							}
						})
					this.block1_link = 'https://therightdoctors.com/podcast/'; // Currently for covid-19, need to change 
				} else {
					this.block1_category = 'The Interview'
					this.url2 = 'https://therightdoctors.com/api/beta/article?category=the-interview&event=' + this.main_block1['event'] + '&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4&limit=1';
					this.http.get(this.url2).pipe(
						map((res: Response) => res.json())).subscribe(data2 => {
							if (data['success']) {
								console.log('why data 6', data2)
								this.block1_data = data2['data'];
								this.block1_data.sort(function (a, b) {
									a = new Date(a.created_at);
									b = new Date(b.created_at);
									return a > b ? -1 : a < b ? 1 : 0;
								});
								// this.block2_data = this.block2_data.reverse(); 
								console.log('Block1 DATA ::: ', this.block1_data);
								for (let i = 0; i < this.block1_data.length; ++i) {
									// if(this.block1_data.lenght-1 >= i){
									this.block1_data[i]['href_link'] = 'https://therightdoctors.com/' + this.main_block1['event'] + '/the-interview/' + this.block1_data[i]['slug'];
								}
								// } 
							}
						})
					this.block1_link = 'https://therightdoctors.com/' + this.main_block1['event'] + '/the-interview/';
				}
				// console.log('blog data 1', this.block1_data)


			})
		//  console.log('blog data 2', this.block1_data)

	}
}

import { Component, OnInit } from '@angular/core';
import {UserServiceService} from '../user-service.service';
import { Router} from "@angular/router";


@Component({
  selector: 'app-forgot-password-new',
  templateUrl: './forgot-password-new.component.html',
  styleUrls: ['./forgot-password-new.component.css']
})


export class ForgotPasswordNewComponent implements OnInit {
  
  model:any={};


  constructor( private userService: UserServiceService, private router: Router) { }

  ngOnInit(): void {
  }


  login(){
    this.userService.forgetPassword(this.model.user_id)
      .subscribe(
        data => {
          console.log(data)
          if(data['success']) {
              alert(data["message"])
              this.router.navigate(['/login-page']);
          } else {
               alert(data['message']);
          }
        },
        error => {
          console.log(error);
        });
  }


}

import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material';
import {NgForm, FormControl, Validators} from "@angular/forms";
import {UserServiceService} from "../user-service.service";
import { LikeComment } from '../like-comment/like-comment.component';
import {Location} from '@angular/common';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html'
})
export class RegistrationComponent implements OnInit {
// model1: any = {};
model2: any = {};
confirmpassword1 : any;
@ViewChild(NgForm) f: NgForm;
inputChecking = new FormControl('', [
  Validators.required
]);
returnUrl: string;
message: any;
user: any;
countrycode: any;
temp: any;
selected: any;
constructor( 
   private _location: Location,
  private userservice: UserServiceService,private router: Router,private route: ActivatedRoute, public dialogRef: MatDialogRef<RegistrationComponent>,
             private dialog: MatDialog) { }
selecte = new FormControl('',[
    Validators.required,
    Validators.email,
])
ngOnInit() {
  console.log('jhgkfgh');
  this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';
}

function(value) {
  this.countrycode = value;
  this.temp = value;
}

register() {
  console.log('hiii');
  console.log(this.model2);
  this.userservice.registration(JSON.stringify(this.model2))
      .subscribe(
          data =>{
            console.log(data);
            if(data['success']){
              alert('Registered Successfully');
    this.router.navigate(['/slides']);
          
            }
          },
          error =>{
            console.log(error);
          });
}



  onNoClick(): void {
      this.dialogRef.close();
  }

  login_popup(): void{
      this.onNoClick();
      this.openDialog()
  }

  openDialog(): void {
      const dialogRef = this.dialog.open(LikeComment, {
          width: '450px',
          panelClass: 'like-comment',
      });

      dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
      });
  }
}
<style>
    .vid_side_box_inner {
        padding: 0 0 5px 0 !important;
        margin: 5px 0 0 !important;
        border-bottom: 1px solid #eee;
    }
</style>
<div class="new-screen2">
    <div class="container">
        <div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="20px" class="display-mob">
            <div fxFlex.lt-md="100%" fxFlex.gt-sm="33.33%" fxLayout="column"  class="load-screen">
                <div fxLayout="column" class="internal3 one-section">
                    <h5 class="all-head">{{this.block2_category}}</h5>
                    <ng-template ngFor let-item [ngForOf]="block2_data" let-currentIndex="index">
                        <div *ngIf="currentIndex < 1">
                            <a href="https://therightdoctors.com/insights/cme/column/dr-o-p-yadava-risk-benefit-ratio-overwhelmingly-in-favour-of-anticoagulation-in-covid-19-patients-say-recent-studies" style="padding-bottom: 15px;">
                                <div fxLayout="column" class="brdr-btm">
                                    <div class="mrg-after-imgvid">
                                        <img src="https://storage.googleapis.com/web-assets/COVID-19/anticoagulation-in-covid-19.jpg" style="width:100%">
                                    </div>
                                    <div fxLayout="column">
                                        <!-- <p class="internal-dr-name">{{block2_data[currentIndex]['sub_headline']}}</p> -->
                                        <p class="internal-updates-head">Risk-benefit ratio overwhelmingly in favour of anticoagulation in Covid-19 patients say recent studies </p>
                                        <p class="internal-para">
                                            Our understanding of the pathogenesis of Covid-19 has changed from the initial perception...<a href="https://therightdoctors.com/insights/cme/column/dr-o-p-yadava-risk-benefit-ratio-overwhelmingly-in-favour-of-anticoagulation-in-covid-19-patients-say-recent-studies" style="color:red;">Read
                  More</a></p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </ng-template>
                    <!-- <div fxLayout="column" class="brdr-btm">
                        <div fxLayout.lt-sm="column" fxLayout.gt-xs="row" fxLayoutGap="16px">
                            <ng-template ngFor let-item [ngForOf]="block2_data" let-currentIndex="index+1">
                                <div *ngIf="currentIndex < 2">

                                    <a href="{{this.block2_link}}{{this.block2_data[currentIndex]['slug']}}" style="padding-bottom: 15px;">
                                        <div fxLayout.lt-sm="column" fxLayout.gt-xs="row" fxlayoutGap.lt-sm="8px" fxLayoutGap.gt-xs="8px">
                                            <div fxFlex.lt-sm="100%" fxFlex.gt-xs="30%">
                                                <img src="{{block2_data[currentIndex]['image_url']}}" alt="{{block2_data[currentIndex]['headline']}}" style="width:100%">
                                            </div>
                                            <div fxFlex.lt-sm="100%" fxFlex.gt-xs="70%">
                                                <p class="internal-dr-name">{{block2_data[currentIndex]['sub_headline']}}</p>
                                                <p class="internal-updates-head">{{block2_data[currentIndex]['headline']| slice: 0 : 60}}...</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </ng-template>
                        </div>
                    </div> -->
                    <!-- ------------------- -->

                    <!-- <ul fxLayout="column">
                        <ng-template ngFor let-item [ngForOf]="block2_data" let-currentIndex="index+2">
                            <div *ngIf="currentIndex < 3">
                                <li class="brdr-btm">
                                    <a href="{{this.block2_link}}{{this.block2_data[currentIndex]['slug']}}" style="padding-bottom: 15px;">

                                        <p class="internal-dr-name">{{block2_data[currentIndex]['sub_headline']}}</p>
                                        <p class="internal-updates-head">{{block2_data[currentIndex]['headline']| slice: 0 : 80}}...</p>
                                    </a>
                                </li>
                            </div>

                        </ng-template>
                    </ul> -->
                </div>
            </div>
            <!-- col2 start-->
            <div fxFlex.lt-md="100%" fxFlex.gt-sm="33.33%" fxLayout="column"  class="load-screen">
                <div fxLayout="column" class="internal3 one-section">
                    <h5 class="all-head">{{this.block3_category}}</h5>
                    <ng-template ngFor let-item [ngForOf]="block3_data" let-currentIndex="index">
                        <div *ngIf="currentIndex < 1">
                            <a href="{{this.block3_link}}{{this.block3_data[currentIndex]['slug']}}" style="padding-bottom: 15px;">
                                <div fxLayout="column" class="brdr-btm">
                                    <div class="mrg-after-imgvid">
                                        <picture>
                                            <source type="image/webp" srcset="{{block3_data[currentIndex]['thumbnail_url1']}}">
                                                <img src="{{block3_data[currentIndex]['image_url']}}" alt="{{block3_data[currentIndex]['headline']}}" style="width:100%">
                                            </picture>
                                    </div>
                                    <div fxLayout="column">
                                        <!-- <p class="internal-dr-name">{{block3_data[currentIndex]['sub_headline']}}</p> -->
                                        <p class="internal-updates-head">{{block3_data[currentIndex]['headline']| slice: 0 : 80}}</p>
                                        <p class="internal-para">
                                            {{block3_data[currentIndex]['transcript_list'][currentIndex]['question'] | slice: 0 : 80}}...<a href="{{this.block3_link}}{{this.block3_data[currentIndex]['slug']}}" style="color:red;">Read
                          More</a></p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </ng-template>
                    <!-- <div fxLayout="column" class="brdr-btm">
                        <div fxLayout.lt-sm="column" fxLayout.gt-xs="row" fxLayoutGap="16px">
                            <ng-template ngFor let-item [ngForOf]="block3_data" let-currentIndex="index+1">
                                <div *ngIf="currentIndex < 2">

                                    <a href="{{this.block3_link}}{{this.block3_data[currentIndex]['slug']}}" style="padding-bottom: 15px;">
                                        <div fxLayout.lt-sm="column" fxLayout.gt-xs="row" fxlayoutGap.lt-sm="8px" fxLayoutGap.gt-xs="8px">
                                            <div fxFlex.lt-sm="100%" fxFlex.gt-xs="30%">
                                                <img src="{{block3_data[currentIndex]['image_url']}}" alt="{{block3_data[currentIndex]['headline']}}" style="width:100%">
                                            </div>
                                            <div fxFlex.lt-sm="100%" fxFlex.gt-xs="70%">
                                                <p class="internal-dr-name">{{block3_data[currentIndex]['sub_headline']}}</p>
                                                <p class="internal-updates-head">{{block3_data[currentIndex]['headline']| slice: 0 : 60}}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </ng-template>
                        </div>
                    </div> -->
                    <!-- ------------------- -->

                    <!-- <ul fxLayout="column">
                        <ng-template ngFor let-item [ngForOf]="block2_data" let-currentIndex="index+2">
                            <div *ngIf="currentIndex < 3">
                                <div *ngIf="block2_data[currentIndex]['headline']!=['']">

                                    <li class="brdr-btm">
                                        <a href="{{this.block2_link}}{{this.block2_data[currentIndex]['slug']}}" style="padding-bottom: 15px;">

                                            <p class="internal-dr-name">{{block2_data[currentIndex]['sub_headline']}}</p>
                                            <p class="internal-updates-head">{{block2_data[currentIndex]['headline']}}</p>
                                        </a>
                                    </li>
                                </div>
                            </div>

                        </ng-template>
                    </ul> -->
                </div>
            </div>
            <!-- col2 end -->
            <!-- col3 starts -->
            <div fxFlex.lt-md="100%" fxFlex.gt-sm="33.33%" fxLayout="column">
                <div fxLayout="column" class="internal3 one-section">
                    <h5 class="all-head">{{this.block4_category}}</h5>
                    <ng-template ngFor let-item [ngForOf]="block4_data" let-currentIndex="index">
                        <div *ngIf="currentIndex < 1">
                            <a href="{{this.block4_link}}{{this.block4_data[currentIndex]['slug']}}" style="padding-bottom: 15px;">
                                <div fxLayout="column" class="brdr-btm">
                                    <div class="mrg-after-imgvid" *ngIf="block4_data[currentIndex]['image_url']!=['']">
                                        <picture>
                                            <source type="image/webp" srcset="{{block4_data[currentIndex]['thumbnail_url1']}}">
                                                <img src="{{block4_data[currentIndex]['image_url']}}" alt="{{block4_data[currentIndex]['headline']}}" style="width:100%">
                                            </picture>
                                    </div>
                                    <div fxLayout="column">
                                        <!-- <p class="internal-dr-name">{{block4_data[currentIndex]['sub_headline']}}</p> -->
                                        <p class="internal-updates-head">{{block4_data[currentIndex]['headline']| slice: 0 : 80}}</p>
                                        <p class="internal-para">
                                            {{block4_data[currentIndex]['transcript_list'][currentIndex]['question'] | slice: 0 : 80}}...<a href="{{this.block4_link}}{{this.block4_data[currentIndex]['slug']}}" style="color:red;">Read
        More</a></p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </ng-template>
                    <!-- <div fxLayout="column" class="brdr-btm">
                        <div fxLayout.lt-sm="column" fxLayout.gt-xs="row" fxLayoutGap="16px">
                            <ng-template ngFor let-item [ngForOf]="block4_data" let-currentIndex="index+1">
                                <div *ngIf="currentIndex < 2 && block4_data[currentIndex]['headline']!=['']">

                                    <a href="{{this.block3_link}}{{this.block4_data[currentIndex]['slug']}}" style="padding-bottom: 15px;">
                                        <div fxLayout.lt-sm="column" fxLayout.gt-xs="row" fxlayoutGap.lt-sm="8px" fxLayoutGap.gt-xs="8px">
                                            <div fxFlex.lt-sm="100%" fxFlex.gt-xs="30%">
                                                <img src="{{block4_data[currentIndex]['image_url']}}" alt="{{block4_data[currentIndex]['headline']}}" style="width:100%">
                                            </div>
                                            <div fxFlex.lt-sm="100%" fxFlex.gt-xs="70%">
                                                <p class="internal-dr-name">{{block4_data[currentIndex]['sub_headline']}}</p>
                                                <p class="internal-updates-head">{{block4_data[currentIndex]['headline']| slice: 0 : 80}}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </ng-template>
                        </div>
                    </div> -->
                    <!-- ------------------- -->

                    <!-- <ul fxLayout="column">
                        <ng-template ngFor let-item [ngForOf]="block4_data" let-currentIndex="index+2">
                            <div *ngIf="currentIndex < 2">
                                <div *ngIf="block4_data[currentIndex]['headline']!=['']">
                                    <li class="brdr-btm">
                                        <a href="{{this.block4_link}}{{this.block4_data[currentIndex]['slug']}}" style="padding-bottom: 15px;">

                                            <p class="internal-dr-name">{{block4_data[currentIndex]['sub_headline']}}</p>
                                            <p class="internal-updates-head">{{block4_data[currentIndex]['headline']| slice: 0 : 60}}</p>
                                        </a>
                                    </li>
                                </div>
                            </div>

                        </ng-template>
                    </ul> -->
                </div>
            </div>

            <!-- col4 3ends -->



            <!-- col3 start-->
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import {UserServiceService} from '../user-service.service';
import {ActivatedRoute, Router} from "@angular/router";


@Component({
  selector: 'app-reset-password-new',
  templateUrl: './reset-password-new.component.html',
  styleUrls: ['./reset-password-new.component.css']
})


export class ResetPasswordNewComponent implements OnInit {

  model:any={};
  model2:any={};
  id:any;
  hidePassword: boolean = true;
  hideConfirmPassword: boolean = true;
  
  constructor(private userService: UserServiceService,private route: ActivatedRoute,private router: Router) { }


  ngOnInit(): void {
    this.route.params.subscribe(param => {
      this.id = param['id'];
      });
  }

  
  register()
  {

    if(this.model.password != this.model.cpassword){
      alert("Password does not match")
    }
    else{
      this.model2.password = this.model.password;
      this.model2.id = this.id;

      this.userService.resetPassword(this.model2).subscribe(data=>{
        if(data['success']){
          alert('Password Reset successfully done.')
          this.router.navigate(['/login-page']);
        }
        else{
          alert(data['message']);
        }
      })
    }
  }

  togglePasswordVisibility(): void {
    this.hidePassword = !this.hidePassword;
  }
  
  toggleConfirmPasswordVisibility(): void {
    this.hideConfirmPassword = !this.hideConfirmPassword;
  }

}

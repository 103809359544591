<router-outlet>
    
</router-outlet>
<!-- <app-rotating-cube></app-rotating-cube> -->
<!-- <div *ngIf="!accepts && !v" style="position: fixed;bottom:0;left:0;width: 100%;">
    <mat-card class="aaa" style="border-radius:0">
        <p>
            <b style="color: tomato;">Cookies and Privacy</b>
            <br>This website uses cookies to ensure you get the best experience on our website
        </p>
        <p>
            <button class="btn btn-primary" style="float: right;margin-top:-15px;margin-left:15px;" (click)="accept()">Accept</button>
            <a href="https://en.wikipedia.org/wiki/HTTP_cookie" target="_blank" rel="noopener" style="color:white!important;float:right;text-decoration:underline!important">Learn more</a>
        </p>
    </mat-card>
    
</div> -->
<!-- <div *ngIf="!this.contribute">
    <div class="blocklast fade">
        <div class="container">
            <div fxLayout="column">
                <div fxLayout="row" fxLayoutAlign="end" fxShow.lt-md="true" fxHide>
                    <i class="fa fa-times close-btn" aria-hidden="true" (click)="close_contribute()" style="margin-bottom: 10px;
                    font-size: 20px;"></i>
                </div>
                <div fxLayout.lt-md="column" fxLayout.gt-sm="row" class="mob-col">


                    <div fxLayout="column" fxFlex.lt-md="100%" fxFlex.gt-sm="80%">
                        <p style="font-size: 16px"><strong>Support TheRightDoctors. </strong>The Coronavirus Pandemic has taken the world by storm. We are witnessing a paradox of information overload and unanswered questions among both the lay public and the medical community.</p>

                        <p style="font-size: 16px">TheRightDoctors brings you Insights from thought leaders across leading medical specialities. Views which help correct the information asymmetry and articulate the voice of medical science and reason.</p>

                        <p style="font-size: 16px">This effort, however, needs your support. Medical journalism is a niche which can survive only when it is adequately funded.</p>
                        <p style="font-size : 16px">Help us fight the epidemic of misinformation and fear-mongering that this pandemic has unleashed on us.</p>

                    </div>
                    <div fxLayout="column" class="con_button">
                        <button mat-flat-button style="background-color: #3f51b5;color: #fff;font-weight: bold;border-radius: 5px;" [routerLink]="['/plan']">Contribute&nbsp;</button>

                    </div>

                    <div>

                    </div>
                    <div fxLayout="row" fxLayoutAlign="end" fxShow.gt-sm="true" fxHide>

                        <i class="fa fa-times close-btn" aria-hidden="true" (click)="close_contribute()" style="font-size: 24px;"></i>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div> -->
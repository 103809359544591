import { Component, OnInit } from '@angular/core';
import { UserServiceService } from '../user-service.service';

@Component({
  selector: 'app-new-screen-six',
  templateUrl: './new-screen-six.component.html',
  styleUrls: ['./new-screen-six.component.css']
})
export class NewScreenSixComponent implements OnInit {
	intr: object[] = [];
	conv: any;

  constructor(private feedService : UserServiceService) { }
  ngOnInit() {
    this.video_feature();

  }
	video_feature() {
		// console.log('get catagory calling');
		this.feedService.feature().subscribe(
			data => {
				let da = data.json();
				this.conv = da['data']
				for (var i = 0; i < this.conv.length; i++) {

					this.intr.push(this.conv[i])

				}
				// console.log('yes', this.intr)

				// console.log('I CANT SEE DATA HERE in single componenet : ', this.intr);
			} 
		);


  }
  
  createRange(number) {
		var items: number[] = [];
		for (var i = 1; i <= number; i++) {
			items.push(i);
		}
		return items;
	}

}

import {APP_ID, Component, Inject, OnInit, PLATFORM_ID, ViewChild,Renderer2} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {MAT_DIALOG_DATA} from '@angular/material';
import {MatDialog, MatDialogRef} from '@angular/material';
import {UserServiceService} from "../user-service.service";
// import { RegistrationComponent } from '../registration/registration.component';

@Component({
  selector: 'like-comment',
  templateUrl: 'like-comment.component.html',
})

export class LikeComment{
  model:any={};
  users : any;
  returnUrl: any;

  constructor(
      private service: UserServiceService,
      public dialogRef: MatDialogRef<LikeComment>,
      @Inject(MAT_DIALOG_DATA) public data:any,
      private route: Router,
      private router: ActivatedRoute,
      private dialog: MatDialog){
  }

  onNoClick(): void {
      this.dialogRef.close();
  }

  register_popup(): void{
      this.onNoClick();
      this.openDialog()
  }

  openDialog(): void {
        // this.router.navigate
        this.route.navigate(['/login-page']);

//       const dialogRef = this.dialog.open(RegistrationComponent, {
//           width: '450px',
//           panelClass: 'app-registration',
//       });

//       dialogRef.afterClosed().subscribe(result => {
//           console.log('The dialog was closed');
//       });
  }

  ngOnInit() {
      this.returnUrl = this.router.snapshot.queryParams['returnUrl'] || '';
  }

  login_validation(){
      console.log(this.model.user_id);
      this.service.mail_checking_login(this.model.user_id,this.model.password).subscribe(data => {
          if(data['success']){
              console.log(data['data'][0]);
              // let users = data['data'];
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              console.log("I am coming");
              localStorage.setItem('currentUser', JSON.stringify(data['data'][0]));
              alert('Logged in Successfully');
              // this.route.navigate(['/event-details/wccmm-2019']);
              this.onNoClick();
          }
          else{
              alert('Credentials are incorrect, please try again');
          }
      })
  }
}


import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.css']
})
export class TestingComponent implements OnInit {

            pdfSrc="https://storage.googleapis.com/web-assets/TheRightDoctorsWebinarBroadcast.pdf";
            public configure: PerfectScrollbarConfigInterface = {};
            event= 'webinar-broadcast';
            constructor(private route: ActivatedRoute, private meta: Meta,
            private _formBuilder: FormBuilder, private titleService: Title) { }
  ngOnInit() {

          console.log('if condition');
          this.titleService.setTitle('TheRightDoctors: Webinar Broadcast');
          this.meta.updateTag({name: 'description', content: 'TheRightDoctors: Webinar Broadcast'});
          this.meta.updateTag({name: 'keywords', content: 'Presentation,Webinar Broadcast,TheRightDoctors'});
          // this.meta.updateTag({name: 'rel', href: 'https://storage.googleapis.com/web-assets/wccmm-2019/WCCMM-Interview/G-Mancia/G-Mancia-large.jpg');
          this.meta.updateTag({
              property: 'vr:canonical',
              content: 'https://therightdoctors.com/' + this.event
          });
          this.meta.updateTag({property: 'og:title', content: 'TheRightDoctors: Webinar Broadcast'});
         this.meta.updateTag({property: 'og:description', content: 'A behind the scenes look'});
          this.meta.updateTag({
              property: 'og:url',
              content: 'https://therightdoctors.com/' + this.event
          });
          this.meta.updateTag({name: 'twitter:title', content: 'TheRightDoctors: Webinar Broadcast'});
         this.meta.updateTag({name: 'twitter:description', content: 'A behind the scenes look'});
        
         this.meta.updateTag({property: 'og:image', content: 'https://storage.googleapis.com/web-assets/wccmm-2019/WCCMM-Interview/G-Mancia/G-Mancia-large.jpg'});
        this.meta.updateTag({property: 'og:image:secure_url', content:'https://storage.googleapis.com/web-assets/wccmm-2019/WCCMM-Interview/G-Mancia/G-Mancia-large.jpg'});
        this.meta.updateTag({name: 'twitter:image', content: 'https://storage.googleapis.com/web-assets/wccmm-2019/WCCMM-Interview/G-Mancia/G-Mancia-large.jpg'});
        this.meta.updateTag({
          name: 'twitter:url',
          content: 'https://therightdoctors.com/'+ this.event});
          this.meta.updateTag({name: 'twitter:text:description', content: 'Webinar Broadcast'});

  }

}

















import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  // if(window) {
  //   window.console.log = function() {};
  // }
  
}

platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
    if ('serviceWorker' in navigator && environment.production) {
        navigator.serviceWorker.register('ngsw-worker-mod.js');
       
    }
}).catch(err => console.log(err));

import {Component, OnInit} from '@angular/core';
//import {ActivatedRoute} from '@angular/router';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {UserServiceService} from "../user-service.service";
import {ActivatedRoute, NavigationEnd} from '@angular/router';
import {Router} from "@angular/router";
import { Inject } from '@angular/core'; 
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Location} from '@angular/common';
import * as moment from 'moment';
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  home: any;
  notLogin:boolean=false;
  _isHandset: boolean = true;
  category: any;
  categoryy: any;
  categorye: any;
  isShow:boolean;
  Logged :boolean = false;
  p_voted: boolean;
  today: any;
  constructor(private _breakpointObserver: BreakpointObserver, private route: ActivatedRoute, public dialog: MatDialog, private router: Router) {
    this._breakpointObserver
      .observe([Breakpoints.Handset, Breakpoints.Tablet])
      .subscribe((result: BreakpointState) => {
        this._isHandset = result.matches;
      });
  }

  ngOnInit() {

    this.p_voted = false;
    this.today = moment(new Date().setMinutes(0));
    this.today.set('second', 0);
    this.today = this.today.format('dddd, MMMM DD, YYYY');
    // console.log(this.today);
    
    this.route.data.subscribe(name => {
      
      this.home = name['name'];

      // automatic login 
      if(localStorage.getItem('currentUser')!=null)
      this.Logged = true;
    });
    this.route.params.subscribe(category => {
      if (category) {
        // console.log('category=', category);
        if (category['event'] && category['category']) {
          if (category['category']) {
            this.categoryy = category['category'];
            console.log('Category Inside If condition =', this.categoryy);
          }
          this.categorye = category['event'];
          console.log('If Condition Event=', this.categorye);
        }
        else if (category['event']) {
          this.category = category['event'];
          console.log('event naresh=', this.category);
        }
        else {
         
          this.category = category['category'];
          
        }

        if (category['catagory']) {
          console.log('catagory');
          this.category = category['catagory'];
          console.log('else Condition category=', this.category);
        }
      }
    });
  }

  toggleSearch(){
    this.isShow=!this.isShow;
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(TrdLoginSignup, {
      width: '600px',
      panelClass: 'app-login-register'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  logout(){
   
    //  const lostruser = JSON.parse(localStorage.getItem('currentUser'));
    //  console.log(lostruser);
    // alert(lostruser);
    // alert(temp);

      // remove user from local storage to log user out
      localStorage.removeItem('currentUser');
      window.location.reload();
  }


}


@Component({
  selector: 'trd-login-signup',
  templateUrl: 'trd-login-signup.html',
})
export class TrdLoginSignup{
    personRe :boolean=true;
    loginpe:boolean=false;
    model:any={};
    users : any;
    returnUrl: any;
    model2:any = {};
    model3 : any={};
    countrycode: any;
    verification_code : boolean=false;
    m_verify : boolean=false;
    is_verify : boolean =false;
    selected : any;
  
  temp: any;
  constructor(
    private _location: Location,
    private service: UserServiceService,
    public dialogRef: MatDialogRef<TrdLoginSignup>,
    private route: Router,
    private router: ActivatedRoute,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this.returnUrl = this.router.snapshot.queryParams['returnUrl'] || '';
    

    //console.log(this.Logged);
}
verify_code(){
  this.service.verify_code_register(this.model2.token,this.model2.phone_number,this.model2.hash).subscribe
  (da=> {

      let data = da.json();
  
      if(data['success']){
          alert('Verification Done');
          this.verification_code = false;
          this.m_verify=true;
           this.is_verify = true;
      } else{
          alert('Please enter the correct OTP');
          this.verification_code = true;
          this.m_verify=false;
      }
  });
}

phone_btn_onclick() {
console.log(this.model);
          this.model2.phone_number = this.model2.mobile;
          //this.model2.country_code = this.model2.country_code;
          this.model2.via ='sms';
          this.service.verification_code_send_register(this.model2.phone_number, this.model2.country_code).subscribe
          (dat=> {
              let data = dat.json();
              if(data['success']){
                console.log(data);
                  //alert('Verification code sent to your mobile number');
                  this.verification_code = true;
                  this.model2.hash = data['hash'];
                  this.m_verify = true;
              } else{
                  alert('Error');
              }
          });  
}
function(value) {
  this.countrycode = value;
  this.temp = value;
}

gotoRegister(){
  this.personRe = false;
  this.loginpe = true;
}

  register(){
   // this.onNoClick()
  //  this.route.navigate(['/trd-registration-form']);
    if(!this.model2.username || !this.model2.email || !this.model2.password || !this.model2.confirmpassword || !this.model2.country_code || !this.model2.mobile){
      alert("Enter all details");
      return;
    }
    else if(this.model2.confirmpassword!=this.model2.password){
      alert("Password donot match");
      return;
    }

    else if(!this.is_verify){
      alert("Please verify the mobile number");
      return;
    }

    this.model3.username=this.model2.username;
    this.model3.email=this.model2.email;
    this.model3.password=this.model2.password;
    this.model3.confirmpassword=this.model2.confirmpassword;
    this.model3.mobile=this.model2.phone_number;
    //this.model3.country_code=this.selected;
    this.model3.country_code=this.selected;
     
     //this.model2.verification_flag = "true";
     console.log('hiii');
      console.log(this.model3);
      this.service.registration(JSON.stringify(this.model3))
          .subscribe(
              data =>{
                console.log(data);
                if(data['success']){
                  alert('Registered Successfully');
                  this.onNoClick();
                }
              },
              error =>{
                console.log(error);
              });
    }


    login_popup(): void{
        this.onNoClick();
        this.openDialog()
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(TrdLoginSignup, {
            width: '450px',
            panelClass: 'trd-login-signup',
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }

    login(){
      this.loginpe = false;
        this.personRe = true;
        console.log(this.model.user_id);
        this.service.mail_checking_login(this.model.user_id,this.model.password).subscribe(data => {
          console.log("Data-------"+JSON.stringify(data));
            if(data['success']){
            //  if(data['data'][0]['verification_flag']=="true"){
                console.log(data['data'][0]);
            //    console.log("VERFIFICATION FLAG == "+data['data'][0]['verification_flag']);
                // let users = data['data'];
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                console.log("I am coming");
                console.log(data);
                localStorage.setItem('currentUser', JSON.stringify(data['data'][0]));
                alert('Logged in Successfully');
                // this.route.navigate(['/event-details/wccmm-2019']);
                this.onNoClick();
                window.location.reload();
                //this.backClicked();
                // this.route.navigate(['/profile']);
                //location.reload();
                //console.log(this.isLogged);
               //this.isLogged=true;
           //   }else{
          //      alert("User Not Verified");
          //    }

            }
            else{
                alert('Credentials are incorrect, please try again');
            }
        })
    }

    // backClicked() {
    //   this._location.back();
    // }


}


import { AuthServiceConfig, GoogleLoginProvider } from 'angularx-social-login';
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true
};
var gOptions = {
    scope: 'https://www.googleapis.com/auth/contacts'
};
var ɵ0 = GoogleLoginProvider.PROVIDER_ID;
var config = new AuthServiceConfig([
    {
        id: ɵ0,
        provider: new GoogleLoginProvider('725459838767-hn8qh93m24kav98885it9vfrc61751og.apps.googleusercontent.com', gOptions)
    },
]);
export function provideConfig() {
    return config;
}
var ɵ1 = {}, ɵ2 = [], ɵ3 = DEFAULT_PERFECT_SCROLLBAR_CONFIG;
var AppModule = (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1, ɵ2, ɵ3 };

import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import {Headers, Http, RequestOptions, Response, ResponseContentType} from '@angular/http';
import {map} from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable({
  providedIn: 'root'
})
export class LoginserviceService {

  constructor(private http: HttpClient, private ht: Http,private hc: HttpClient) { }
  login(data) {
    console.log(data);
    var email = data['email'];
    console.log(email);
    var password = data['password'];
    console.log(password);
    return this.ht.get('https://therightdoctors.com/api/beta/doctor-article/login/'+email+'/'+password+'?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg', this.jwt()).map((response: Response) => response.json());
  }

  verify_code2(token,country,phone,email){
    var data={
      "country_code":country,
      "phone_number":phone,
      "token":token,
      "email":email
    };
    return this.ht.post('https://msg.therightdoctors.com/api/verification/verify2',data).map((response: Response) => response.json());
  }

  verification_code_send2(phone,country){
    var data={
      "country_code":country,
      "phone_number":phone,
      "via":"sms",
      "locale":"en"
    };
    return this.ht.post('https://msg.therightdoctors.com/api/verification/start',data).map((response: Response) => response.json());
  }

  register(data) {
    return this.ht.post('https://therightdoctors.com/api/beta/doctor-article/presentation/signup?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg',{'json':data}, this.jwt()).map((response: Response) => response.json());
  }
  reset_password(email) {
    return this.ht.get('https://therightdoctors.com/api/beta/presentation/forgot/password/' + email + '?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg',this.jwt()).map((response: Response) => response.json());
  }

  user_login(username: string, password: string) {
    return this.ht.get('https://therightdoctors.com/api/beta/image/user/authentication/'+username+'/'+password+'?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg');
  }

  sendPwd(model) {
    console.log('i am here');
      return this.http.post('https://therightdoctors.com/api/beta/subscriber/podcast_send_pwd?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg', model);
}

  registration(user) {
    return this.http.post('https://therightdoctors.com/api/beta/subscriber/new_polling_user?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg', {'json': user});
  }
  
  mail_checking_login(email,password) {
    return this.ht.get('https://therightdoctors.com/api/beta/subscriber/poll_users_login/'+email+'/'+password+'?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg', this.jwt()).pipe(map((response:Response) => response.json()));
  }

  getlastwordbyslug(slug) {
    return this.ht.get('https://therightdoctors.com/api/beta/image/' + slug + '?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg');
  }

  podcast_audio_files(){

    return this.ht.get('https://therightdoctors.com/api/beta/article?category=podcast&event=wccmm-mumbai-2019&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4');
  }

  blog_list(){

    return this.ht.get('https://therightdoctors.com/api/beta/article?category=cme&event=wccmm-mumbai-2019&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4');
  }

  the_last_word() {
    return this.ht.get('https://therightdoctors.com/api/beta/image?type=gallery&event=wccmm-mumbai-2019&key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg');
  }


  user_register(data) {
    return this.ht.post('https://therightdoctors.com/api/beta/image/user?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg', {'json':data});
  }

  get_videos(){
      return this.ht.get('https://therightdoctors.com/api/beta/article?event=wccmm-mumbai-2019&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4&is_live=true');
  }

  get_podcast(slug){
    return this.ht.get('https://therightdoctors.com/api/beta/article/'+slug+'?key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4&is_live=true');
  }

  get_short_url(Url) {
    console.log('in Url Shortner service');
    console.log(Url);
    const url_service ='http://localhost:5000/short';
    console.log(Url);
    return this.ht.post('https://trd.therightdoctors.com/short', {'url': Url});
  }

  corona_ppt() {
    return this.ht.get('https://therightdoctors.com/api/beta/image?type=presentation&event=corona-virus&key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg');
  }

  get_presentation_all(event) {
    const final_url = 'https://therightdoctors.com/api/beta/image?type=presentation&event='+event+'&key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg';
    console.log(final_url);
    return this.ht.get(final_url);
  }

  update_views(data,id) {
    return this.ht.put('https://therightdoctors.com/api/beta/image/'+id+'?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg', data);
  }


  get_single_video(slug, catagory) {
    console.log('in article service single video');
    const final_url = 'https://therightdoctors.com/api/beta/article/' + slug + '?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg';
    console.log(final_url);
    return this.ht.get(final_url)

  }

  get_next_video(category, event) {
    console.log(category);
    //category=the-interview
    console.log(event);
    if(event=='rssdi-jaipur'){
      event='rssdi-jaipur-2019'
    }
    
    const next: string = 'https://therightdoctors.com/api/beta/article?category=' + category + '&event=' + event + '&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4';
    return this.ht.get(next);
  }

  update_totalviews(model, slug) {
    //console.log('in article service single video hai',);
    let data = JSON.stringify(model);
    const final_url = 'https://therightdoctors.com/api/beta/article/' + slug + '?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg';

    return this.ht.put(final_url, {'json': data});

  }

  subscribe_mail(email) {
    return this.ht.post('https://therightdoctors.com/api/beta/subscriber/subscribe_mail/?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg&token=trd_token', {'json': email}, this.jwt()).map((response: Response) => response.json());
  }

  subscribe_mail_using_name_and_email(email) {
    return this.ht.post('https://therightdoctors.com/api/beta/subscriber/subscribe_mail_using/full_name_email/?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg&token=trd_token', {'json': email}, this.jwt()).map((response: Response) => response.json());
  }
  

  get_session_details(hall, date)
  {
    return this.ht.get('https://therightdoctors.com/api/beta/event_details/'+hall+'/'+date+'?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg');
  }

  get_videos1(){
    return this.ht.get('https://therightdoctors.com/api/beta/article?event=rssdi-jaipur-2019&category=the-interview&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4&is_live=true');
}

the_last_word_rssdi() {
  return this.ht.get('https://therightdoctors.com/api/beta/article?category=one-more-thing&event=rssdi-jaipur-2019&key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg');
}

criticare() {
  return this.ht.get('https://therightdoctors.com/api/beta/article?event=criticare-hyderabad-2020&key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg');
}
criticare_all(event,category) {
  if(category != '')
    return this.ht.get('https://therightdoctors.com/api/beta/article?event='+event+'&category='+category+'&key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg');
  else
  return this.ht.get('https://therightdoctors.com/api/beta/article?event='+event+'&key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg');

}
criticare_for_tv() {
  return this.ht.get('https://therightdoctors.com/api/beta/article?category=feature&key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg');
}

the_last_word_iages() {
  return this.ht.get('https://therightdoctors.com/api/beta/article?category=one-more-thing&event=iages-guwahati-2020&key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg');
}

the_last_word_wccpci(event:any) {
  return this.hc.get(`https://therightdoctors.com/api/beta/image?&event=${event}&limit=20&key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg`);
}

BlogData() {
  return this.ht.get('https://therightdoctors.com/api/beta/article?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg&category=cme&event=rssdi-jaipur-2019');
}

  get_presentation(id) {
    console.log('ID', id);
    const final_url = 'https://therightdoctors.com/api/beta/image/'+id+'?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg';
    console.log(final_url);
    return this.ht.get(final_url);
  }


  get_single_video2() {
    console.log('in article service single video');
    const final_url = 'https://therightdoctors.com/api/beta/slide_article' + '?key=' +'6ZzQ52peX5XqUx3t824670wv8jIaf1B4&limit=100';
    console.log(final_url);
    return this.ht.get(final_url)
      .map(data => {
        data.json();
        // the console.log(...) line prevents your code from working
        // either remove it or add the line below (return ...)
        console.log(' I CAN SEE DATA HERE: ', data.json());
        return data.json();
      }).catch(error => throwError(error.json()));
  }

  get_single_video3() {
    console.log('in article service single video');
    const final_url = 'https://therightdoctors.com/api/beta/slide_article_published' + '?key=' +'6ZzQ52peX5XqUx3t824670wv8jIaf1B4';
    console.log(final_url);
    return this.ht.get(final_url)
      .map(data => {
        data.json();
        // the console.log(...) line prevents your code from working
        // either remove it or add the line below (return ...)
        console.log(' I CAN SEE DATA HERE: ', data.json());
        return data.json();
      }).catch(error => throwError(error.json()));
  }

  get_presentation_one(limit=500) {
    //const final_url = 'https://therightdoctors.com/api/beta/image?type=presentation&event='+event+'&key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg';
    const final_url = `https://therightdoctors.com/api/beta/image?type=presentation&limit=${limit}&key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg`;
    console.log(final_url);
    return this.ht.get(final_url);
  }
  get_presentation_all_popular_page() {
    //const final_url = 'https://therightdoctors.com/api/beta/image?type=presentation&event='+event+'&key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg';
    const final_url = 'https://therightdoctors.com/api/beta/image?type=presentation'+'&key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg';
    console.log(final_url);
    return this.ht.get(final_url);
  }
  commented(model,slug){
    let data = JSON.stringify(model);
    const final_url = 'https://therightdoctors.com/api/beta/article/slide_comment/' + slug + '?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg';
    return this.ht.put(final_url, {'json': data})
        .map(data => {
            data.json();
            console.log('I CAN SEE DATA HERE put req: ', data.json());
            return data.json();
        }).catch(error => throwError(error.json()));
}

getDownload(targetUrl){
  this.ht.get(targetUrl,{responseType:ResponseContentType.Blob})
  .catch((err)=>{return err})
  .subscribe((res:Response)=>{
    var a = document.createElement("a");
    a.href = URL.createObjectURL(res.blob());
    a.download = "slide.pdf";
    // start download
    a.click();
  })
}

liked(model,slug){
  let data = JSON.stringify(model);
  const final_url = 'https://therightdoctors.com/api/beta/article/slide_like/' + slug + '?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg';
  return this.ht.put(final_url, {'json': data})
      .map(data => {
          data.json();
          console.log('I CAN SEE DATA HERE put req: ', data.json());
          return data.json();
      }).catch(error => throwError(error.json()));
  //return this.ht.put(final_url , model); 
}

getlastwordbyslugarticle(slug) {
  //   return this.ht.get('https://therightdoctors.com/api/beta/article/' + slug + '?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg');
    return this.ht.get('https://therightdoctors.com/api/beta/article/' + slug + '?key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4');
  }

  private jwt() {
    console.log('in jwt function');
    // create authorization header with jwt token
    /*let currentUser = JSON.parse(localStorage.getItem('currentUser'));*/
    let currentUser = null;
    if (currentUser && currentUser.token) {
      let headers = new Headers({'Authorization': 'Bearer ' + currentUser.token});
      return new RequestOptions({headers: headers});
    }
  }
}

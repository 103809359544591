function _window() {
    return window;
}
var WindowRefSub = (function () {
    function WindowRefSub() {
    }
    Object.defineProperty(WindowRefSub.prototype, "nativeWindow", {
        get: function () {
            return _window();
        },
        enumerable: true,
        configurable: true
    });
    return WindowRefSub;
}());
export { WindowRefSub };

<style>
    .padd-btm-all{
        margin-top:0px !important;
    }
    .internal-updates-head{
        text-align: center;
    }
   img{
       width: 100%;
   }
   .aocrss{
       margin-bottom: 7px !important;
   }
</style>






<div class="new-screen13">
    <div class="container" fxLayout="column">
        <h5 class="all-head aocrss">AOC 2020</h5>
        <p>
            <a href="https://aoc.therightdoctors.com/aoc-2020" class="all-head" style="font-weight: normal;">Med Slides</a>
        </p>
        <div fxLayout.lt-sm="column" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="20px" fxLayoutGap.lt-md="8px" class="rsddiwd">
            
            <div fxFlex.lt-sm="100%" fxFlex.lt-md="100%" fxFlex.gt-sm="33.33%" class="dmwid">
                <!-- <div>
                    <p>
                        <a class="all-head" href="https://rssdi2019.therightdoctors.com/event-details/rssdi-tv">The interview</a>
                    </p>
                </div> -->
                <div>
                    <ul>
                        <li fxLayout="row" fxLayoutGap="8px" style="padding: 2px 0px;border-top: 1px solid #ddd;">
                            <div fxFlex="30%" class="wid30p">
                                <a href="https://aoc.therightdoctors.com/aoc-2020/slides/aoc-2020-dr-brian-pinto-angiographic-patterns-in-cad-in-women-obstructive-non-obstructive-mino-ca/5e43f6f93e1ad071e2d6d955">
                                    <picture>
                                        <source type="image/webp" srcset="https://storage.googleapis.com/web-assets/AOC-Revised-Presenatation-thumbnails-2020/Brian%20Pinto/Brian%20Pinto-thumb.webp">
                                            <img src="https://storage.googleapis.com/web-assets/AOC-Revised-Presenatation-thumbnails-2020/Brian%20Pinto/Brian%20Pinto-large.jpg" alt=" Angiographic Patterns of CAD in Women " style="width:100%" /> 
                                        </picture>
                                </a>  
                            </div>
                            <div fxFlex="70%" class="wid70p">
                                    <!-- <p class="internal-dr-name">Dr. Brian Pinto</p> -->
                                    <p class="internal-updates-head" style="text-align: left;">Angiographic Patterns of CAD in Women</p>
                            </div>
                        </li>
                   
                      
                   

                        <li fxLayout="row" fxLayoutGap="8px" style="padding: 2px 0px;border-top: 1px solid #ddd;">
                            <div fxFlex="30%"  class="wid30p">
                                <a href="https://aoc.therightdoctors.com/aoc-2020/slides/aoc-2020-dr-gr-kane-24-yr-primi-12-critical-ms-in-pulmonary-edema/5e43f9273e1ad071e2d6d968">
                                    <picture>
                                        <source type="image/webp" srcset="https://storage.googleapis.com/web-assets/AOC-Revised-Presenatation-thumbnails-2020/G%20R%20Kane/G%20R%20Kane-thumb.webp">
                                            <img src="https://storage.googleapis.com/web-assets/AOC-Revised-Presenatation-thumbnails-2020/G%20R%20Kane/G%20R%20Kane-large.jpg" alt="24yr primi - 12 weeks critical MS in Pulmonary Edema" style="width:100%" /> 
                                        </picture>
                                </a>                            </div>
                            <div fxFlex="70%" class="wid70p">
                                    <!-- <p class="internal-dr-name"> Dr. GR Kane </p> -->
                                    <p class="internal-updates-head" style="text-align: left;"> "24yr primi - 12 weeks" critical MS in Pulmonary Edema </p>
                            </div>
                        </li> 
                    </ul>
                    
                </div>
            </div>

            <div fxFlex.lt-sm="100%" fxFlex.lt-md="100%" fxFlex.gt-sm="33.33%" class="dmwid">
                <!-- <div>
                    <p>
                        <a class="all-head" href="https://rssdi2019.therightdoctors.com/event-details/rssdi-tv">Highlights</a>
                    </p>
                </div> -->
                <div>
                   <ul>
                        <li fxLayout="row" fxLayoutGap="8px" style="padding: 2px 0px;border-top: 1px solid #ddd;">
                            <div fxFlex="30%"  class="wid30p">
                                <a href="https://aoc.therightdoctors.com/aoc-2020/medslides/aoc-2020-satyavan-sharma-hormone-replacement-therapy-hrt-is-there-a-role-in-cad-prevention/5e4533d2aecd813c4b800161">
                                            <img src="https://storage.googleapis.com/web-assets/AOC-Revised-Presenatation-thumbnails-2020/Satyavan%20Sharma/Satyavan%20Sharma-large.jpg" alt="Hormone Replacement Therapy: Is there a role in CAD prevention? " style="width:100%" />
                                 </a>                            </div>
                            <div fxFlex="70%" class="wid70p">
                                    <!-- <p class="internal-dr-name">Dr. Satyavan Sharma</p> -->
                                    <p class="internal-updates-head" style="text-align: left;">Hormone Replacement Therapy: Is there a role in CAD prevention?</p>
                            </div>
                        </li>
                   
                    
                        <li fxLayout="row" fxLayoutGap="8px" style="padding: 2px 0px;border-top: 1px solid #ddd;">
                            <div fxFlex="30%"  class="wid30p">
                                <a href="https://aoc.therightdoctors.com/aoc-2020/medslides/aoc-2020-case-based-discussion-rhd-and-pregnancy/5e4536ddaecd813c4b800184">
                                    <picture>
                                        <source type="image/webp" srcset="https://storage.googleapis.com/web-assets/RSSDI_2019/RSSDI-tv-2019-website-thumbnails/Day-03/Rakesh-Gupta/Rakesh-Gupta-thumb.webp">
                                            <img src="https://storage.googleapis.com/web-assets/AOC-Revised-Presenatation-thumbnails-2020/Sadanand%20Shetty/Sadanand%20Shetty-large.jpg" alt=" Case-Based Discussion RHD and Pregnancy " style="width:100%" /> 
                                        </picture>
                                 </a>                            </div>
                            <div fxFlex="70%" class="wid70p">
                                    <!-- <p class="internal-dr-name">Dr. Sadanand Shetty</p> -->
                                    <p class="internal-updates-head" style="text-align: left;">Case-Based Discussion RHD and Pregnancy</p>
                            </div>
                        </li>
                    </ul>
                    
                   
                </div>
            </div>
            
            <div fxFlex.lt-sm="100%" fxFlex.lt-md="100%" fxFlex.gt-sm="33.33%" class="dmwid">
                <!-- <div>
                    <p>
                        <a class="all-head" href="https://rssdi2019.therightdoctors.com/event-details/podcast">Podcast</a>
                    </p>
                </div> -->
                <div>
                    <ul>  
                    <li fxLayout="row" fxLayoutGap="8px" style="padding: 2px 0px;border-top: 1px solid #ddd;">
                            <div fxFlex="30%"  class="wid30p">
                                <a href="https://aoc.therightdoctors.com/aoc-2020/medslides/aoc-2020-dr-op-yadava-cabg-in-women-challenges/5e452e90aecd813c4b8000f6">
                                    <picture>
                                        <source type="image/webp" srcset="https://storage.googleapis.com/web-assets/AOC-Revised-Presenatation-thumbnails-2020/O%20P%20Yadava/O%20P%20Yadava-thumb.webp">
                                            <img src="https://storage.googleapis.com/web-assets/AOC-Revised-Presenatation-thumbnails-2020/O%20P%20Yadava/O%20P%20Yadava-large.jpg" alt=" CABG in Women - Challenges" style="width:100%" />
                                        </picture>
                                 </a>                            </div>
                            <div fxFlex="70%"  class="wid70p">
                                    <!-- <p class="internal-dr-name"> Dr. OP Yadav</p> -->
                                    <p class="internal-updates-head" style="text-align: left;"> CABG in Women - Challenges </p>
                            </div>
                        </li>
                   

                   
                        <li fxLayout="row" fxLayoutGap="8px" style="padding: 2px 0px;border-top: 1px solid #ddd;">
                                <div fxFlex="30%"  class="wid30p">
                                    <a href="https://aoc.therightdoctors.com/aoc-2020/medslides/aoc-2020-dr-uday-jadhav-hypertension-in-pregnancy-2020-update/5e453943aecd813c4b8001a7"> 
                                        <picture>
                                            <source type="image/webp" srcset="https://storage.googleapis.com/web-assets/AOC-Revised-Presenatation-thumbnails-2020/Uday%20Jadhav/Uday%20Jadhav-thumb.webp">
                                                <img src="https://storage.googleapis.com/web-assets/AOC-Revised-Presenatation-thumbnails-2020/Uday%20Jadhav/Uday%20Jadhav-large.jpg" alt="Hypertension In Pregnancy 2020 Update" style="width:100%" />
                                            </picture>   
                                        </a>                                </div>
                                <div fxFlex="70%"  class="wid70p">
                                        <!-- <p class="internal-dr-name"> Dr. Uday Jadhav </p> -->
                                        <p class="internal-updates-head" style="text-align: left;"> Hypertension In Pregnancy 2020 Update </p>
                                </div>
                            </li>
                        </ul>
                    
                </div>
            </div>
            
            
            
        </div>
        <a href="https://aoc.therightdoctors.com/aoc-2020">
            <button mat-flat-button class="view-all-btn">View all</button>
        </a>
    </div>
    
</div>



<div class="new-screen13">
    <div class="container" fxLayout="column">
        <h5 class="all-head aocrss">RSSDI&nbsp;<span style="text-transform: lowercase;">tv</span>&nbsp;2019</h5>
        <div fxLayout.lt-sm="column" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="20px" fxLayoutGap.lt-md="8px" class="rsddiwd">
            
            <div fxFlex.lt-sm="100%" fxFlex.lt-md="100%" fxFlex.gt-sm="33.33%" class="dmwid">
                <div>
                    <p>
                        <a class="all-head" href="https://rssdi2019.therightdoctors.com/event-details/rssdi-tv">The interview</a>
                    </p>
                </div>
                <div>
                    <ul>
                        <li  fxLayout="row" fxLayoutGap="8px" style="padding: 2px 0px;border-top: 1px solid #ddd;">
                            <div fxFlex="30%" class="wid30p">
                                <a href="https://rssdi2019.therightdoctors.com/rssdi-2019/the-interview/dr.-rakesh-gupta-certain-prescribed-guidelines-must-be-followed-for-treating-diabetes">
                                    <picture>
                                        <source type="image/webp" srcset="https://storage.googleapis.com/web-assets/RSSDI_2019/RSSDI-tv-2019-website-thumbnails/Day-03/Rakesh-Gupta/Rakesh-Gupta-thumb.webp">
                                            <img src="https://storage.googleapis.com/web-assets/RSSDI_2019/RSSDI-tv-2019-website-thumbnails/Day-03/Rakesh-Gupta/Rakesh-Gupta-large.jpg" alt="Certain prescribed guidelines must be followed for treating diabetes" style="width:100%" />
                                        </picture>

                                </a>                            </div>
                            <div fxFlex="70%" class="wid70p">
                                    <!-- <p class="internal-dr-name">Dr. Shankha Sen</p> -->
                                    <a href="https://rssdi2019.therightdoctors.com/rssdi-2019/the-interview/dr.-rakesh-gupta-certain-prescribed-guidelines-must-be-followed-for-treating-diabetes">  <p class="internal-updates-head" style="text-align: left;">Certain prescribed guidelines must be followed for treating diabetes</p></a>
                            </div>
                        </li>
                    
                      
                    

                        <li  fxLayout="row" fxLayoutGap="8px" style="padding: 2px 0px;border-top: 1px solid #ddd;">
                            <div fxFlex="30%" class="wid30p">
                                <a href="https://rssdi2019.therightdoctors.com/rssdi-2019/the-interview/dr.-balaji-jagan-mohan-diabetes,-the-leading-cause-of-kidney-failure-worldwide">
                                    <picture>
                                        <source type="image/webp" srcset="https://storage.googleapis.com/web-assets/RSSDI_2019/RSSDI-tv-2019-website-thumbnails/Day-03/ML-Negi/ML-Negi-thumb.webp">
                                            <img src="https://storage.googleapis.com/web-assets/RSSDI_2019/RSSDI-tv-2019-website-thumbnails/Day-03/ML-Negi/ML-Negi-large.jpg" alt=" Diabetes, the leading cause of kidney failure worldwide" style="width:100%" />
                                        </picture>
                                </a>                            </div>
                            <div fxFlex="70%" class="wid70p">
                                <a href="https://rssdi2019.therightdoctors.com/rssdi-2019/the-interview/dr.-balaji-jagan-mohan-diabetes,-the-leading-cause-of-kidney-failure-worldwide">    <!-- <p class="internal-dr-name">Dr. Ravindra Shukla</p> -->
                                    <p class="internal-updates-head" style="text-align: left;">Diabetes, the leading cause of kidney failure worldwide
                                    </p></a>
                            </div> 
                        </li> 
                   
                    
                        <li  fxLayout="row" fxLayoutGap="8px" style="padding: 2px 0px;border-top: 1px solid #ddd;">
                            <div fxFlex="30%" class="wid30p">
                                <a href="https://rssdi2019.therightdoctors.com/rssdi-2019/the-interview/dr-ak-gupta-role-of-beta-cells-in-diabetes">
                                    <picture>
                                        <source type="image/webp" srcset="https://storage.googleapis.com/web-assets/RSSDI_2019/RSSDI-tv-2019-website-thumbnails/Day-01/ALOKE-KUMAR-GUPTA/ALOKE-KUMAR-GUPTA-thumb.webp">
                                            <img src="https://storage.googleapis.com/web-assets/RSSDI_2019/RSSDI-tv-2019-website-thumbnails/Day-01/ALOKE-KUMAR-GUPTA/ALOKE-KUMAR-GUPTA-large.jpg" alt="Role of beta cells in diabetes" style="width:100%" />
                                        </picture>
                                </a>                            </div>
                            <div fxFlex="70%" class="wid70p">
                                    <!-- <p class="internal-dr-name">Dr. Ramesh Kumar Goenka</p> -->
                                    <a href="https://rssdi2019.therightdoctors.com/rssdi-2019/the-interview/dr-ak-gupta-role-of-beta-cells-in-diabetes"> <p class="internal-updates-head" style="text-align: left;">Role of beta cells in diabetes
                                    </p></a>
                            </div>
                        </li>  
                    </ul>
                </div>
            </div>

            <div fxFlex.lt-sm="100%" fxFlex.lt-md="100%" fxFlex.gt-sm="33.33%" class="dmwid">
                <div>
                    <p>
                        <a class="all-head" href="https://rssdi2019.therightdoctors.com/event-details/rssdi-tv">Insights</a>
                    </p>
                </div>
                <div>
                    <ul>
                        <li fxLayout="row" fxLayoutGap="8px" style="padding: 2px 0px;border-top: 1px solid #ddd;">
                            <div fxFlex="30%" class="wid30p">
                                <a href="https://rssdi2019.therightdoctors.com/rssdi-2019/cme/dr.-parimal-swamy-glycaemic-index-of-various-foods-in-indian-cuisine">
                                    <picture>
                                        <source type="image/webp" srcset="https://storage.googleapis.com/web-assets/RSSDI_2019/RSSDI-tv-2019-website-thumbnails/Day-02/Parimal-Swamy/Parimal-Swamy-thumb.webp">
                                            <img src="https://storage.googleapis.com/web-assets/RSSDI_2019/RSSDI-tv-2019-website-thumbnails/Day-02/Parimal-Swamy/Parimal-Swamy-large.jpg" alt="Glycaemic index of various foods in Indian cuisine" style="width:100%" />
                                        </picture>
                                </a>                            </div>
                            <div fxFlex="70%" class="wid70p">
                                <a href="https://rssdi2019.therightdoctors.com/rssdi-2019/cme/dr.-parimal-swamy-glycaemic-index-of-various-foods-in-indian-cuisine"> <!-- <p class="internal-dr-name">Dr. V Mohan</p> -->
                                    <p class="internal-updates-head" style="text-align: left;">Glycaemic index of various foods in Indian cuisine</p></a>
                            </div>
                        </li>
                    
                    
                        <li  fxLayout="row" fxLayoutGap="8px" style="padding: 2px 0px;border-top: 1px solid #ddd;">
                            <div fxFlex="30%" class="wid30p">
                                <a href="https://rssdi2019.therightdoctors.com/rssdi-2019/cme/torrent-rssdi-tv-dr-sandeep-suri-blog">
                                    <picture>
                                        <source type="image/webp" srcset="https://storage.googleapis.com/web-assets/RSSDI_2019/RSSDI-tv-2019-website-thumbnails/Day-02/Sandeep-Rai/Sandeep-Rai-thumb.webp">
                                        <img src="https://storage.googleapis.com/web-assets/RSSDI_2019/RSSDI-tv-2019-website-thumbnails/Day-02/Sandeep-Rai/Sandeep-Rai-large.jpg" alt="Indians are genetically more susceptible to diabetes" style="width:100%" />
                                        </picture>
                                </a>                            </div>
                            <div fxFlex="70%" class="wid70p">
                                    <!-- <p class="internal-dr-name">TheRightDoctors Showcase Video</p> -->
                                    <a href="https://rssdi2019.therightdoctors.com/rssdi-2019/cme/torrent-rssdi-tv-dr-sandeep-suri-blog">         <p class="internal-updates-head" style="text-align: left;">Indians are genetically more susceptible to diabetes</p></a>
                            </div>
                        </li>
                    
                    
                        <li fxLayout="row" fxLayoutGap="8px" style="padding: 2px 0px;border-top: 1px solid #ddd;">
                            <div fxFlex="30%" class="wid30p">
                                <a href="https://rssdi2019.therightdoctors.com/rssdi-2019/cme/torrent-rssdi-tv-dr-piyush-desai-blog">
                                    <picture>
                                        <source type="image/webp" srcset="https://storage.googleapis.com/web-assets/RSSDI_2019/RSSDI-tv-2019-website-thumbnails/Day-03/Piyush-Desai/Piyush-Desai-thumb.webp">
                                            <img src="https://storage.googleapis.com/web-assets/RSSDI_2019/RSSDI-tv-2019-Blogs-Images/Day-03/Piyush%20Desai/Piyush%20Desai-08.jpg" alt=" Diabetes is a leading cause of erectile dysfunction in men" style="width:100%" />
                                        </picture>
                                </a>                            </div>
                            <div fxFlex="70%" class="wid70p">
                                    <!-- <p class="internal-dr-name">Dr. Prakash Keswan, Dr. KK Pareek, Dr. Banshi Saboo</p> -->
                                    <a href="https://rssdi2019.therightdoctors.com/rssdi-2019/cme/torrent-rssdi-tv-dr-piyush-desai-blog">  <p class="internal-updates-head" style="text-align: left;">Diabetes is a leading cause of erectile dysfunction in men</p></a>
                            </div>
                        </li>  
                    </ul>
                   
                </div>
            </div>
            
            <div fxFlex.lt-sm="100%" fxFlex.lt-md="100%" fxFlex.gt-sm="33.33%" class="dmwid">
                <div>
                    <p>
                        <a class="all-head" href="https://rssdi2019.therightdoctors.com/event-details/podcast">Podcast</a>
                    </p>
                </div>
                <div>
                    
                         <!-- <div> -->
                            <ul>          
                    <li fxLayout="row" fxLayoutGap="8px" style="padding: 2px 0px;border-top: 1px solid #ddd;">
                        <div fxFlex="30%" class="wid30p">
                            <a href="https://rssdi2019.therightdoctors.com/rssdi-2019/podcast/dr.-jay-choradiya-the-new-plague-in-India">
                                <picture>
                                    <source type="image/webp" srcset="https://storage.googleapis.com/web-assets/RSSDI_2019/RSSDI-tv-2019-website-thumbnails/Day-01/JAY-CHORADIA/JAY-CHORADIA-thumb.webp">
                                        <img src="https://storage.googleapis.com/web-assets/RSSDI_2019/Revised-Podcast-Thumbnails/Day-01/Jay-Choradia/Jay-Choradia-large.jpg" alt="The New Plague In India" style="width:100%" />
                                    </picture>
                            </a>                        </div>  
                            <div fxFlex="70%" class="wid70p">
                                    <!-- <p class="internal-dr-name">Dr. Jay Choradiya</p> -->
                                    <a href="https://rssdi2019.therightdoctors.com/rssdi-2019/podcast/dr.-jay-choradiya-the-new-plague-in-India">   <p class="internal-updates-head" style="text-align: left;">The New Plague In India</p></a>
                            </div>
                        </li>
                    
                    
                        <li fxLayout="row" fxLayoutGap="8px" style="padding: 2px 0px;border-top: 1px solid #ddd;">
                            <div fxFlex="30%" class="wid30p">
                                <a href="https://rssdi2019.therightdoctors.com/rssdi-2019/podcast/dr.-k-vijay-kumar-just-cutting-down-on-carbohydrates-in-our-diet-is-not-sufficient">
                                    <picture>
                                        <source type="image/webp" srcset="https://storage.googleapis.com/web-assets/RSSDI_2019/Revised-Podcast-Thumbnails/Day-03/Vijay-Kumar/Vijay-Kumar-thumb.webp">
                                        <img src="https://storage.googleapis.com/web-assets/RSSDI_2019/Revised-Podcast-Thumbnails/Day-03/Vijay-Kumar/Vijay-Kumar-large.jpg" alt="Just Cutting Down On Carbohydrates In Our Diet Is Not Sufficient" style="width:100%" /> 
                                        </picture>
                                </a>                            </div>
                            <div fxFlex="70%" class="wid70p">
                                    <!-- <p class="internal-dr-name">Dr. K Vijay Kumar</p> -->
                                    <a href="https://rssdi2019.therightdoctors.com/rssdi-2019/podcast/dr.-k-vijay-kumar-just-cutting-down-on-carbohydrates-in-our-diet-is-not-sufficient"> <p class="internal-updates-head" style="text-align: left;">Just Cutting Down On Carbohydrates In Our Diet Is Not Sufficient</p></a>
                            </div>
                        </li>  
                    </ul>
                </div>
            </div>
            
            
            
        </div>
        <a href="https://rssdi2019.therightdoctors.com/event-details/home">
            <button mat-flat-button class="view-all-btn">View all</button>
        </a>
        </div>
        
    </div>
    
<!-- </div> -->


<!-- <div class="new-screen13">
    <div class="container" fxLayout="column">
        <h5 class="all-head">WCCMM 2019</h5>
        <div fxLayout.lt-sm="column" fxLayout.gt-xs="row" fxLayoutGap.lt-sm="0px" fxLayoutGap.gt-sm="20px" fxLayoutGap.lt-md="8px">
            
            <div fxFlex.lt-sm="100%" fxFlex.gt-xs="30%">
                <div>
                    <p>
                        <a class="all-head" href="https://wccmm2019.therightdoctors.com/event-details/wccmm-tv">The interview</a>
                    </p>
                </div>
                <div>
                    <a href="https://wccmm2019.therightdoctors.com/the-interview/dr-thair-al-jumaily-updates-in-management-of-cardiac-complications-in-diabetic-patients-interview">
                        <li fxLayout="row" fxLayoutGap="8px" style="padding: 2px 0px;border-top: 1px solid #ddd;">
                            <div fxFlex="30%">
                              <img src="https://storage.googleapis.com/web-assets/wccmm-2019/WCCMM-Interview/Thair%20Al%20Jumaliy/Thair%20Al%20Jumaliy-large.jpg" alt=" " style="width:100%" />
                            </div>
                            <div fxFlex="70%">
                                    <p class="internal-dr-name">Dr. Thair Al-Jumaily</p>
                                    <p class="internal-updates-head" style="text-align: left;">Updates In Management Of Cardiac Complications In Diabetic Patients</p>
                            </div>
                        </li>
                    </a>
                      
                    <a href="https://wccmm2019.therightdoctors.com/the-interview/dr-giuseppe-mancia-diuretic-past-present-and-future-focus-on-indapamide-interview">

                        <li fxLayout="row" fxLayoutGap="8px" style="padding: 2px 0px;border-top: 1px solid #ddd;">
                            <div fxFlex="30%">
                              <img src="https://storage.googleapis.com/web-assets/wccmm-2019/WCCMM-Interview/G-Mancia/G-Mancia-large.jpg" alt=" " style="width:100%" />
                            </div>
                            <div fxFlex="70%">
                                    <p class="internal-dr-name">Dr. Giuseppe Mancia</p>
                                    <p class="internal-updates-head" style="text-align: left;">Hypertension : Current Perspectives In 2019
                                    </p>
                            </div>
                        </li> 
                    </a>
                    <a href="https://wccmm2019.therightdoctors.com/the-interview/dr-amrish-agrawal-recognition-and-management-of-pulmonary-embolism-in-2019">
                        <li  fxLayout="row" fxLayoutGap="8px" style="padding: 2px 0px;border-top: 1px solid #ddd;">
                            <div fxFlex="30%">
                              <img src="https://storage.googleapis.com/web-assets/wccmm-2019/wccmm-website-thumbnalis/AmrishAgrawal/AmrishAgrawal-large.jpg" alt=" " style="width:100%" />
                            </div>
                            <div fxFlex="70%">
                                    <p class="internal-dr-name">Dr. Amrish Agrawal</p>
                                    <p class="internal-updates-head" style="text-align: left;">Recognition & Management Of Pulmonary Embolism In 2019</p>
                            </div>
                        </li>  
                   </a>
                </div>
            </div>

            <div fxFlex.lt-sm="100%" fxFlex.gt-xs="30%">
                <div>
                    <p>
                        <a class="all-head" href="https://wccmm2019.therightdoctors.com/event-details/presentation">Med Slides</a>
                    </p>
                </div>
                <div>
                    <a href="https://wccmm2019.therightdoctors.com/event-details/presentation/dr-smit-shrivastava-basics-of-normal-coronary-angiogram/5ccd6e5496a6d26990ade862">
                        <li fxLayout="row" fxLayoutGap="8px" style="padding: 2px 0px;border-top: 1px solid #ddd;">
                            <div fxFlex="30%">
                              <img src="https://storage.googleapis.com/web-assets/WCCMM/Presentation-thumbnails(medslide)/Smit%20Shrivastava/Smit%20Shrivastava-large.jpg" alt=" " style="width:100%" />
                            </div>
                            <div fxFlex="70%">
                                    <p class="internal-dr-name">Dr.Smit Shrivastava</p>
                                    <p class="internal-updates-head" style="text-align: left;">Basics Of Normal Coronary Angiogram</p>
                            </div>
                        </li>
                    </a>
                    <a href="https://wccmm2019.therightdoctors.com/event-details/presentation/dr-d-ramesh-top-ten-findings-a-cardiologists-should-pick-up-from-chest-x-ray/5ccd72a8f3a4bc6a758b2980">
                        <li fxLayout="row" fxLayoutGap="8px" style="padding: 2px 0px;border-top: 1px solid #ddd;">
                            <div fxFlex="30%">
                              <img src="https://storage.googleapis.com/web-assets/WCCMM/Presentation-thumbnails(medslide)/D%20Ramesh/D%20Ramesh-large.jpg" alt=" " style="width:100%" />
                            </div>
                            <div fxFlex="70%">
                                    <p class="internal-dr-name">Dr. D Ramesh</p>
                                    <p class="internal-updates-head" style="text-align: left;"> Top 10 Findings A Cardiologists Should Pick Up From Chest X-ray</p>
                            </div>
                        </li>
                    </a>
                    <a href="https://wccmm2019.therightdoctors.com/event-details/presentation/dr-lb-vijayalakshmi-top-ten-pit-falls-in-echo-you-should-know/5ccd7585f3a4bc6a758b29b1">
                        <li  fxLayout="row" fxLayoutGap="8px" style="padding: 2px 0px;border-top: 1px solid #ddd;">
                            <div fxFlex="30%">
                              <img src="https://storage.googleapis.com/web-assets/WCCMM/Presentation-thumbnails(medslide)/I%20B%20Vijayalakshmi/I%20B%20Vijayalakshmi-large.jpg" alt=" " style="width:100%" />
                            </div>
                            <div fxFlex="70%">
                                    <p class="internal-dr-name">Dr. I B Vijayalakshmi</p>
                                    <p class="internal-updates-head" style="text-align: left;">Top 10 Pit Falls In Echo You Should Know</p>
                            </div>
                        </li>  
                    </a>
                   
                </div>
            </div>
            
            <div fxFlex.lt-sm="100%" fxFlex.gt-xs="30%">
                <div>
                    <p>
                        <a class="all-head" href="https://wccmm2019.therightdoctors.com/event-details/podcast">Podcast</a>
                    </p>
                </div>
                <div>
                    <a href="https://wccmm2019.therightdoctors.com/event-details/podcast/dr-vs-narain-management-of-bleeding-in-patients-on-DOAC-podcast">    
                    <li fxLayout="row" fxLayoutGap="8px" style="padding: 2px 0px;border-top: 1px solid #ddd;">
                            <div fxFlex="30%">
                              <img src="https://storage.googleapis.com/web-assets/wccmm-2019/WCCMM-2019-REDO-PODCAST-THUMBNAILS/VS-Narain/VS-Narain-medium.jpg" alt=" " style="width:100%" />
                            </div>
                            <div fxFlex="70%">
                                    <p class="internal-dr-name">Dr. VS Narain</p>
                                    <p class="internal-updates-head" style="text-align: left;">Management Of Bleeding In Patients On Doac</p>
                            </div>
                        </li>
                    </a>
                    <a href="https://wccmm2019.therightdoctors.com/event-details/podcast/dr-sn-narasinghan-should-non-fasting-lipid-profile-be-the-standard-of-care-podcast">
                        <li fxLayout="row" fxLayoutGap="8px" style="padding: 2px 0px;border-top: 1px solid #ddd;">
                            <div fxFlex="30%">
                              <img src="https://storage.googleapis.com/web-assets/wccmm-2019/WCCMM-2019-REDO-PODCAST-THUMBNAILS/Narasingan/Narasingan-medium.jpg" alt=" " style="width:100%" />
                            </div>
                            <div fxFlex="70%">
                                    <p class="internal-dr-name">Dr. SN Narasingan</p>
                                    <p class="internal-updates-head" style="text-align: left;">Should Non Fasting Lipid Profile Be The Standard Of Care?</p>
                            </div>
                        </li>  
                      </a>
                      <a href="https://wccmm2019.therightdoctors.com/event-details/podcast/dr-sachin-chittawar-the-cardio-renal-benefits-of-SGLT2-inhibitors-and-is-the-benefit-a-class-effect-PODCAST">
                        <li fxLayout="row" fxLayoutGap="8px" style="padding: 2px 0px;border-top: 1px solid #ddd;">
                            <div fxFlex="30%">
                              <img src="https://storage.googleapis.com/web-assets/wccmm-2019/WCCMM-2019-REDO-PODCAST-THUMBNAILS/Sachin-Chitwar/Sachin-Chitwar-medium.jpg" alt=" " style="width:100%" />
                            </div>
                            <div fxFlex="70%">
                                    <p class="internal-dr-name">Dr. Sachin Chittawar</p>
                                    <p class="internal-updates-head" style="text-align: left;">The Cardio-renal Benefits Of Sglt2 Inhibitors And Is The Benefit A Class Effect ?</p>
                            </div>
                        </li>  
                      </a>
                </div>
            </div>
            
            
            
        </div>
        <a href="https://wccmm2019.therightdoctors.com/event-details/all-doctors">
            <button mat-flat-button class="view-all-btn">View all</button>
        </a>
    </div>
    
</div> -->
















<!-- 


<style>
    .padd-btm-all{
        margin-top:0px !important;
    }
    .internal-updates-head{
        text-align: center;
    }
    
</style>
<div class="new-screen13">
    <div class="container" fxLayout="column">
        <h5 class="all-head">Specials</h5>
        <div class="disp-in-blk" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap.lt-md="16px" fxLayoutGap.gt-sm="20px">
            <div fxFlex.lt-md="100%" fxFlex.gt-sm="50%" class="spl-wid1">
                <div class="inr-disp-in-blk" fxLayout.lt-sm="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="20px" fxLayoutGap.lt-sm="16px">
                    <div fxFlex.lt-sm="100%" fxFlex.gt-xs="50%" class="spl-wid2">
                        <div fxLayout="column" class="total-brdr-all">
                            <p class="internal-updates-head" style="padding:10px;">RSSDI tv 2019 </p>
                            <a target="_blank" rel="noopener" href="https://rssdi2019.therightdoctors.com/event-details/home">
                                <div fxLayout="column">
                                    <img alt="RSSDI 2019" src="assets/rssdi2019_Special.jpg ">
                                </div>
                            </a>
                            <div fxLayout="column" fxLayoutAlign="center center" class="padd-btm-all">
                                
                <b>
                    <a class="internal-dr-name" target="_blank" rel="noopener" href="https://rssdi2019.therightdoctors.com/event-details/rssdi-tv" style="text-decoration: none;color:#e27520;">The interview</a><br>
                    <a class="internal-dr-name" target="_blank" rel="noopener" href="https://rssdi2019.therightdoctors.com/event-details/podcast" style="text-decoration: none;color: #e27520;">Podcast</a><br>
                </b>
                            </div>
                            <a target="_blank" rel="noopener" href="https://rssdi2019.therightdoctors.com/event-details/home"><button mat-flat-button class="bg-whiite">See all&nbsp;<span class="material-icons">arrow_forward</span></button></a>
                        </div>
                    </div>
                    <div fxFlex.lt-sm="100%" fxFlex.gt-xs="50%" class="spl-wid3">
                        <div fxLayout="column" class="total-brdr-all">
                            <p class="internal-updates-head" style="padding:10px;">15<sup>th</sup> Annual ECG</p>
                            <a [routerLink]="['specials/dr-ulhas-pandurangi-fifteenth-annual-electrocardiology-course-basics-and-beyond-madras-medical-mission-2018']">
                                <div fxLayout="column">
                                    <img alt="ECG" src="../../assets/img/ecg-specials.jpg">
                                </div>
                            </a>
                            <div fxLayout="column" fxLayoutAlign="center center" class="padd-btm-all">
                               
                <b>
                    <a class="internal-dr-name" [routerLink]="['specials/dr-ulhas-pandurangi-fifteenth-annual-electrocardiology-course-basics-and-beyond-madras-medical-mission-2018/the-speakers']" style="text-decoration: none;color: #e27520;">The Speakers</a><br>
                    <a class="internal-dr-name" [routerLink]="['specials/dr-ulhas-pandurangi-fifteenth-annual-electrocardiology-course-basics-and-beyond-madras-medical-mission-2018/presentation/dr-ulhas-m-pandurangi-normal-ecg/5b80f4aa49d928151dccd92b']" style="text-decoration: none;color: #e27520;">Presentations</a>
                </b>
                            </div>
                            <a [routerLink]="['specials/dr-ulhas-pandurangi-fifteenth-annual-electrocardiology-course-basics-and-beyond-madras-medical-mission-2018']"><button mat-flat-button class="bg-whiite">See all&nbsp;<span class="material-icons">arrow_forward</span></button></a>
                        </div>
                    </div>
                </div>
            </div>
            <div fxFlex.lt-md="100%" fxFlex.gt-sm="50%" class="spl-wid4">
                <div class="inr-disp-in-blk" fxLayout.lt-sm="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="20px" fxLayoutGap.lt-sm="16px">
                    <div fxFlex.lt-sm="100%" fxFlex.gt-xs="50%" class="spl-wid5">
                        <div fxLayout="column" class="total-brdr-all">
                            <p class="internal-updates-head" style="padding:10px;">WCCMM 2019</p>
                            <a target="_blank" rel="noopener" href="https://wccmm2019.therightdoctors.com/wccmm2109">
                                <div fxLayout="column">
                                    <img alt="WCCMM 2019" src="assets/img/wccmm.jpg">
                                </div>
                            </a>
                            <div fxLayout="column" fxLayoutAlign="center center" class="padd-btm-all">
                               
                <b>
                    <a class="internal-dr-name" target="_blank" rel="noopener" href="https://wccmm2019.therightdoctors.com/event-details/wccmm-tv" style="text-decoration: none;color: #e27520;">The Interview</a><br>
                    <a class="internal-dr-name" target="_blank" rel="noopener" href="https://wccmm2019.therightdoctors.com/event-details/all-doctors" style="text-decoration: none;color: #e27520;">In Conversation</a>
                </b>
                            </div>
                            <a target="_blank" rel="noopener" href="https://wccmm2019.therightdoctors.com/wccmm2109"><button mat-flat-button class="bg-whiite">See all&nbsp;<span class="material-icons">arrow_forward</span></button></a>
                        </div>
                    </div>


                    <div fxFlex.lt-sm="100%" fxFlex.gt-xs="50%" class="spl-wid5">
                        <div fxLayout="column" class="total-brdr-all">
                            <p class="internal-updates-head" style="padding:10px;">Stemi India </p>
                            <a [routerLink]="['specials/stemi-india-lucknow-2018']">
                                <div fxLayout="column">
                                    <img alt="STEMI 2018" src="assets/new-home-page/images/STEMI.jpg">
                                </div>
                            </a>
                            <div fxLayout="column" fxLayoutAlign="center center" class="padd-btm-all">
                              
                <b>
                    <a class="internal-dr-name" [routerLink]="['specials/stemi-india-lucknow-2018/the-interview']" style="text-decoration: none;color:#e27520;">The Interview</a><br>
                    
                    </b>
                            </div>
                            <a [routerLink]="['specials/stemi-india-lucknow-2018']"> <button mat-flat-button class="bg-whiite">See all&nbsp;<span class="material-icons">arrow_forward</span></button></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 -->

import {Component, OnInit} from '@angular/core';
import {UserServiceService} from '../user-service.service';
import { LoginserviceService } from '../loginservice.service';



@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  sumodel: any = {};
  email;model: any={};
  localsto:any;


  constructor(private userService: UserServiceService, private service1:  LoginserviceService,) {
  }

  ngOnInit() {
  }


  subscribe_email(){
  
    if(JSON.parse(localStorage.getItem('mail_subscribed'))==null) {
      // alert("In If")
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(this.model.email).toLowerCase())==true){
        if (this.model.email && this.model.full_name) {
            this.service1.subscribe_mail_using_name_and_email(JSON.stringify(this.model))
                .subscribe(data2 => {
                console.log("SingleVideoComponent -> subscribe_email -> data2", data2)
                  
                  // let data = data2.json();
                    if (data2['success']) {
                        alert(data2['message']);
                    } else  if (!data2['success']) {
                      alert(data2['message']);

                    }
                });
        }
      }
      else{
        alert('Enter Valid Email Address');
      }
    } else{
        alert('Already subscribed');
    }
   }

  email_sub() 
  {
    console.log(this.sumodel);
    console.log('hii');
    // this.email= JSON.stringify(this.sumodel);
    // console.log('mail',this.email);
    /*this.userService.subscribe(this.sumodel)
      .subscribe(
        data => {
          if (data['success']) {
            console.log(data);
            alert('your Subscription successfully completed');
            console.log('return data' + data);
            //console.log('return id' + data['id']);
          }
        },
        error => {
          console.log(error);
        });*/
  }

}

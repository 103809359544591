
import {map} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Http,Response } from '@angular/http';

@Component({
  selector: 'app-new-screen-three',
  templateUrl: './new-screen-three.component.html',
  styleUrls: ['./new-screen-three.component.css']
})
export class NewScreenThreeComponent implements OnInit {
  sources: Array < Object > ;
	articles: any;
	inc: number = 0;
	blogdata_c: any;

	audio_list1: object[] = [];
	video_list: any = [];
	conv_image: any;
	imagesSrcs = [];
	audio_list5: object[] = [];

	routerLinks = [];
	catagory: any;
	// live_details: AngularFireList<Live_Details[]>;
	public now: Date = new Date();
	ld: any;
	conv: any;
	finaldata: object[] = [];
	finaldata_other: object[] = [];
	finaldata_oye: object[] = [];
	data_we_need: object[] = [];
	data_final: object[] = [];
	lastdata: object[] = [];
	d: any;
	h1: string;
	h2: string;
	h3: string;
	finaldata_all: object[] = [];
	private dbPath = '/live-updates';
	//private dbPath = '/live_updates';
	isplay: boolean = true;
	isplay2: boolean = true;
	url: any;
	blogdata: any;
	blogdata_corna: any;
	corona_v: any;
	finaldata1 = [];
	cat = [];
	event: string;
	finaldata2 = [];
	finaldata3 = [];
	audio_list: any;
	finaldata_two: object[] = [];
	audio_list_two: object[] = [];
	exclusive: any;
	exclusive_story: any;
	main_block2: any;
	block2_category: any;
	block2_data: any;
	url2: string;
	block2_link: string;
	main_block3: any;
	block3_category: any;
	block3_data: any;
	intr: object[] = [];
	url1: string;

	block3_link: string;
	main_block4: any;
	block4_category: any;
	block4_data: any;
	block4_link: string;
	top_stories: any;
	block1_category: any;
	main_block1: any;
	block1_data: any;
	block1_link: string;
	exclusive_paras = [];
	my_data2 :object[] = [];
	my_data3 :object[] = [];
	my_data4 :object[] = [];

  constructor(private http :Http) { }

  ngOnInit() {
    this.get_block2();
    this.get_block3();
    this.get_block4(); 
   }
	get_block2() {
		this.url = 'https://therightdoctors.com/api/beta/article?&homepage_block=b2&limit=2&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4'
		this.http.get(this.url).pipe(
			map((res: Response) => res.json())).subscribe(data => {
				if (data['success']) {
					this.main_block2 = data['data'];
					this.main_block2.sort(function (a, b) {
						a = new Date(a.created_at);
						b = new Date(b.created_at);
						return a > b ? -1 : a < b ? 1 : 0;
					});
					this.main_block2 = this.main_block2[0];

				}
				this.block2_category = "column";
				if (this.block2_category == 'column') {
					// if (this.main_block2['blog_category'] == 'the-interview')
					// 	this.block2_category = 'The Interview';
					// else if (this.main_block2['blog_category'] == 'in-conversation')
					// 	this.block2_category = 'In Conversation';
					// else if (this.main_block2['blog_category'] == 'column')
					// 	this.block2_category = 'Column';
					// else if (this.main_block2['blog_category'] == 'opinion-editorial')
					// 	this.block2_category = 'Opinion and Editorial';
					// else if (this.main_block2['blog_category'] == 'q-and-a')
					// 	this.block2_category = 'Q & A';
					// else if (this.main_block2['blog_category'] == 'survey')
					// 	this.block2_category = 'Survey';
					// else if (this.main_block1['blog_category'] == 'guidelines')
					// 	this.block2_category = 'Guidelines';
					// else if (this.main_block1['blog_category'] == 'feature')
					// 	this.block2_category = 'Feature';
					// else if (this.main_block1['blog_category'] == 'reviews')
					// 	this.block2_category = 'Reviews';
					// else if (this.main_block1['blog_category'] == 'journal-scan')
					// 	this.block2_category = 'Journal Scan';

					this.url2 = 'https://therightdoctors.com/api/beta/article?limit=2&category=cme&blog_category=' + this.main_block2['blog_category'] + '&is_exclusive=false&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4';
					this.http.get(this.url2).pipe(
						map((res: Response) => res.json())).subscribe(data2 => {
							if (data['success']) {
								// this.block2_data = data2['data'];

								for(var i =0 ; i<data2['data'].length; i++){
									if(data2['data'][i]['image_url']!='')
										this.my_data2.push(data2['data'][i])
								}
								this.block2_data = this.my_data2;


								// this.block2_data.sort(function (a, b) {
								// 	a = new Date(a.created_at);
								// 	b = new Date(b.created_at);
								// 	return a > b ? -1 : a < b ? 1 : 0;
								// });
								this.block2_data = this.sort_articles_by_date(this.block2_data);

								this.block2_data = this.daywise_update(this.block2_data);
								
								
								// this.block2_data = this.block2_data.reverse(); 
								// console.log('Block2 DATA ::: ', this.block2_data);
								// console.log('Block2 DATA ::: ', this.main_block2['blog_category']);

							}
						})
					this.block2_link = 'https://therightdoctors.com/insights/cme/' + this.main_block2['blog_category'] + '/';
				}
				// } else if (this.block2_category == 'podcast') {
				// 	this.block2_category = 'Podcast'
				// 	this.url2 = 'https://therightdoctors.com/api/beta/article?category=podcast&event=' + this.main_block2['event'] + '&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4';
				// 	this.http.get(this.url2).pipe(
				// 		map((res: Response) => res.json())).subscribe(data2 => {
				// 			if (data['success']) {
				// 				this.block2_data = data2['data'];
				// 				this.block2_data.sort(function (a, b) {
				// 					a = new Date(a.created_at);
				// 					b = new Date(b.created_at);
				// 					return a > b ? -1 : a < b ? 1 : 0;
				// 				});
				// 				// this.block2_data = this.block2_data.reverse(); 
				// 				console.log('Block2 DATA ::: ', this.block2_data);
				// 			}
				// 		})
				// 	this.block2_link = 'https://therightdoctors.com/podcast/'; // Currently for covid-19, need to change 
				// } else {
				// 	// this.block2_category = 'The Interview'
				// 	this.block2_category = 'Column'

				// 	this.url2 = 'https://therightdoctors.com/api/beta/article?category=the-interview&event=' + this.main_block2['event'] + '&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4';
				// 	this.http.get(this.url2).pipe(
				// 		map((res: Response) => res.json())).subscribe(data2 => {
				// 			if (data['success']) {
				// 				this.block2_data = data2['data'];
				// 				this.block2_data.sort(function (a, b) {
				// 					a = new Date(a.created_at);
				// 					b = new Date(b.created_at);
				// 					return a > b ? -1 : a < b ? 1 : 0;
				// 				});
				// 				// this.block2_data = this.block2_data.reverse(); 
				// 				console.log('Block2 DATA ::: ', this.block2_data);
				// 			}
				// 		})
				// 	this.block2_link = 'https://therightdoctors.com/' + this.main_block2['event'] + '/the-interview/';
				// }

			})
	}
  get_block3(){
    this.url = 'https://therightdoctors.com/api/beta/article?&homepage_block=b3&limit=3&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4'
    this.http.get(this.url).pipe(
    map((res:Response) => res.json())).subscribe(data =>{
    if(data['success']){
    this.main_block3 = data['data']; 
    this.main_block3.sort(function(a, b) {
    a = new Date(a.created_at);
    b = new Date(b.created_at);
    return a>b ? -1 : a<b ? 1 : 0;
    });
    this.main_block3 = this.main_block3[0];
    }
    this.block3_category = "in-conversation"; 
    if(this.block3_category == 'cme'){
    if(this.main_block3['blog_category'] == 'the-interview')
    this.block3_category = 'The Interview';
    else if (this.main_block3['blog_category'] == 'in-conversation')
    this.block3_category = 'In Conversation';
    else if (this.main_block3['blog_category'] == 'column')
    this.block3_category = 'Column';
    else if (this.main_block3['blog_category'] == 'opinion-editorial')
    this.block3_category = 'Opinion and Editorial';
    else if (this.main_block3['blog_category'] == 'q-and-a')
    this.block3_category = 'Q & A';
    else if (this.main_block3['blog_category'] == 'survey')
    this.block3_category = 'Survey';
    else if (this.main_block1['blog_category'] == 'guidelines')
    this.block3_category = 'Guidelines';
    else if (this.main_block1['blog_category'] == 'feature')
    this.block3_category = 'Feature';
    else if (this.main_block1['blog_category'] == 'reviews')
    this.block3_category = 'Reviews';
    else if (this.main_block1['blog_category'] == 'journal-scan')
    this.block3_category = 'Journal Scan';
   
    this.url2 = 'https://therightdoctors.com/api/beta/article?category=cme&limit=2&is_exclusive=false&blog_category='+ this.main_block3['blog_category'] + '&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4'; 
    this.http.get(this.url2).pipe(
    map((res:Response) => res.json())).subscribe(data2 => {
    if(data['success']){
	// this.block3_data = data2['data']; 

	for(var i =0 ; i<data2['data'].length; i++){
		if(data2['data'][i]['image_url']!='')
			this.my_data3.push(data2['data'][i])
	}
	this.block3_data = this.my_data3;
    // this.block3_data.sort(function(a, b) {
    // a = new Date(a.created_at);
    // b = new Date(b.created_at);
    // return a>b ? -1 : a<b ? 1 : 0;
	// });
	this.block3_data = this.sort_articles_by_date(this.block3_data);
	this.block3_data = this.daywise_update(this.block3_data, 2);


    // this.block2_data = this.block2_data.reverse(); 
    // console.log('Block3 DATA ::: ', this.block3_data); 
    }
    })
    this.block3_link = 'https://therightdoctors.com/insights/cme/'+ this.main_block3['blog_category'] + '/' ; 
    }
    else if(this.block3_category == 'podcast'){
    this.block3_category = 'Podcast'
    this.url2 = 'https://therightdoctors.com/api/beta/article?limit=2&category=podcast&event='+ this.main_block3['event'] + '&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4'; 
    this.http.get(this.url2).pipe(
    map((res:Response) => res.json())).subscribe(data2 => {
    if(data['success']){
    this.block3_data = data2['data']; 
    this.block3_data.sort(function(a, b) {
    a = new Date(a.created_at);
    b = new Date(b.created_at);
    return a>b ? -1 : a<b ? 1 : 0;
	});
	// this.shuffleArray(this.block3_data);



    // this.block2_data = this.block2_data.reverse(); 
    // console.log('Block3 DATA ::: ', this.block3_data); 
    }
    })
    this.block3_link = 'https://therightdoctors.com/podcast/'; // Currently for covid-19, need to change 
    }
    else{
    this.block3_category = 'The Interview'
    this.url2 = 'https://therightdoctors.com/api/beta/article?limit=2&category=the-interview&event='+ this.main_block3['event'] + '&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4'; 
    this.http.get(this.url2).pipe(
    map((res:Response) => res.json())).subscribe(data2 => {
    if(data['success']){
	// this.block3_data = data2['data']; 
	for(var i =0 ; i<data2['data'].length; i++){
		if(data2['data'][i]['image_url']!='')
			this.my_data3.push(data2['data'][i])
	}
	this.block3_data = this.my_data3;
    // this.block3_data.sort(function(a, b) {
    // a = new Date(a.created_at);
    // b = new Date(b.created_at);
    // return a>b ? -1 : a<b ? 1 : 0;
	// });
	// this.shuffleArray(this.block3_data);



    // this.block2_data = this.block2_data.reverse(); 
    // console.log('Block3 DATA ::: ', this.block3_data); 
    }
    })
	// this.block3_link = 'https://therightdoctors.com/'+this.main_block3['event'] + '/the-interview/'; 
	this.block3_link = 'https://therightdoctors.com/tv/'+this.main_block3['event'] + '/the-interview/'; 

    }
    
    })
    }
	get_block4() {
		this.url = 'https://therightdoctors.com/api/beta/article?homepage_block=b4&limit=2&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4'
		this.http.get(this.url).pipe(
			map((res: Response) => res.json())).subscribe(data => {
				if (data['success']) {
					this.main_block4 = data['data'];
					this.main_block4.sort(function (a, b) {
						a = new Date(a.created_at);
						b = new Date(b.created_at);
						return a > b ? -1 : a < b ? 1 : 0;
					});
					this.main_block4 = this.main_block4[0];
				}
				this.block4_category = this.main_block4['category'];

				if (this.block4_category == 'cme') {
					// if (this.main_block4['blog_category'] == 'the-interview')
					// 	this.block4_category = 'The Interview';
					// else if (this.main_block4['blog_category'] == 'in-conversation')
					// 	this.block4_category = 'In Conversation';
					// else if (this.main_block4['blog_category'] == 'column')
					// 	this.block4_category = 'Column';
					// else if (this.main_block4['blog_category'] == 'opinion-editorial')
					// 	this.block4_category = 'Opinion and Editorial';
					// else if (this.main_block4['blog_category'] == 'q-and-a')
					// 	this.block4_category = 'Q & A';
					// else if (this.main_block4['blog_category'] == 'survey')
					// 	this.block4_category = 'Survey';
					// else if (this.main_block1['blog_category'] == 'guidelines')
					// 	this.block4_category = 'Guidelines';
					// else if (this.main_block1['blog_category'] == 'feature')
					// 	this.block4_category = 'Feature';
					// else if (this.main_block1['blog_category'] == 'reviews')
					// 	this.block4_category = 'Reviews';
					// else if (this.main_block1['blog_category'] == 'journal-scan')
					// 	this.block4_category = 'Journal Scan';
// alert(this.main_block4['block_category']);

          this.url2 = 'https://therightdoctors.com/api/beta/article?category=cme&is_exclusive=false&limit=2&blog_category=' + this.main_block4['blog_category'] + '&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4';
					this.http.get(this.url2).pipe(
						map((res: Response) => res.json())).subscribe(data2 => {
							if (data['success']) {
								// this.block4_data = data2['data'];

								for(var i =0 ; i<data2['data'].length; i++){
									if(data2['data'][i]['image_url']!='')
										this.my_data4.push(data2['data'][i])
								}
								this.block4_data = this.my_data4;

								this.block4_data = this.sort_articles_by_date(this.block4_data);


								this.block4_data = this.daywise_update(this.block4_data, 2);
								// this.block2_data = this.block2_data.reverse(); 
								// console.log('Block4 DATA ::: ', this.block4_data);
							}
						})
					this.block4_link = 'https://therightdoctors.com/insights/cme/' + this.main_block4['blog_category'] + '/';
				} else if (this.block4_category == 'podcast') {
					this.block4_category = 'Podcast'
					this.url2 = 'https://therightdoctors.com/api/beta/article?limit=2&category=podcast&event=' + this.main_block4['event'] + '&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4';
					this.http.get(this.url2).pipe(
						map((res: Response) => res.json())).subscribe(data2 => {
							if (data['success']) {
								// this.block4_data = data2['data'];
								for(var i =0 ; i<data2['data'].length; i++){
									if(data2['data'][i]['image_url']!='')
										this.my_data4.push(data2['data'][i])
								}
								this.block4_data = this.my_data4;
								this.block4_data.sort(function (a, b) {
									a = new Date(a.created_at);
									b = new Date(b.created_at);
									return a > b ? -1 : a < b ? 1 : 0;
								});
								// this.block2_data = this.block2_data.reverse(); 
								// console.log('Block4 DATA ::: ', this.block4_data);
							}
						})
					this.block4_link = 'https://therightdoctors.com/podcast/'; // Currently for covid-19, need to change 
				} else {
					this.block4_category = 'The Interview'
					this.url2 = 'https://therightdoctors.com/api/beta/article?limit=2&category=the-interview&event=' + this.main_block4['event'] + '&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4';
					this.http.get(this.url2).pipe(
						map((res: Response) => res.json())).subscribe(data2 => {
							if (data['success']) {
								// this.block4_data = data2['data'];
								for(var i =0 ; i<data2['data'].length; i++){
									if(data2['data'][i]['image_url']!='')
										this.my_data4.push(data2['data'][i])
								}
								this.block4_data = this.my_data4;
								// this.block4_data.sort(function (a, b) {
								// 	a = new Date(a.created_at);
								// 	b = new Date(b.created_at);
								// 	return a > b ? -1 : a < b ? 1 : 0;
								// });
								// this.shuffleArray(this.block4_data);



								// this.block2_data = this.block2_data.reverse(); 
								// console.log('Block4 DATA ::: ', this.block4_data);
							}
						})
					this.block4_link = 'https://therightdoctors.com/tv/' + this.main_block4['event'] + '/the-interview/';
				}

			})
	}

	shuffleArray(array) {
		for (var i = array.length - 1; i > 0; i--) {
			var j = Math.floor(Math.random() * (i + 1));
			var temp = array[i];
			array[i] = array[j];
			array[j] = temp;
		}
	  }

	sort_articles_by_date(object_to_sort){
		// object_to_sort.sort(function (a, b) {
		// 	a = new Date(a.created_at);
		// 	b = new Date(b.created_at);
		// 	return a > b ? -1 : a < b ? 1 : 0;
		// });
		return object_to_sort;
	}



	daywise_update(object_to_show, no_of_articles = 3){
		let date: Date = new Date();  
		var today_date = date.getDate();
		var new_block2_data : object[] = [];
		// console.log("my_date", date.getDate());
		let start = no_of_articles * today_date - no_of_articles;
		let end = start + no_of_articles;
		// this.shuffleArray(this.block2_data);
		let index : any;
		object_to_show = object_to_show.slice(0,10); //slicing in between 0-10 becoz we want top 10 latest articles
		for(let i = start; i<end; i++){
			// index = i % this.block2_data.length;
			index = i % object_to_show.length;
			new_block2_data.push(this.block2_data[index]);
		}
		// this.block2_data = new_block2_data;
		// console.log("my elem list", new_block2_data);
		return new_block2_data;
	}
}

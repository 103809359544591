import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { ArticleService } from '../article.service';

import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
import { LoginserviceService } from '../loginservice.service';
@Component({
  selector: 'app-webinar-broadcast',
  templateUrl: './webinar-broadcast.component.html',
  styleUrls: ['./webinar-broadcast.component.css']
})
export class WebinarBroadcastComponent implements OnInit {


      public loading:boolean = true;
      public loaded:boolean=false;
     pdfSrc  : any;
     public configure: PerfectScrollbarConfigInterface = {};
     slug : any;
     heading:any;
     drname:any;
     id:any;
     constructor(private route: ActivatedRoute, private meta: Meta,
      private _formBuilder: FormBuilder, private service: ArticleService, private titleService: Title, private router: ActivatedRoute) { 
      //this.loading=true;
      //this.loaded=false;     
      }
      ngOnInit() {
            this.loading=true;
            this.loaded=false;
            this.router.params.subscribe(params => {
              //   console.log('this is llovwee,',params);
                 this.id = params['id'];
                  this.service.get_pdf_links(this.id).subscribe(data=>{
                    console.log("dataaaaaaaaaaaaaaaaaaaaa",data)
                    this.pdfSrc = data["data"];
                    //this.loading=false;
                  })
            })
          }
   
        
           totalPages: number;
           page: number = 1;
           isLoaded: boolean = false;
         
         
              nextPage() {
                 this.page += 1;
               }
         
               previousPage() {
                 this.page -= 1;
               }
         
               afterLoadComplete(pdfData: any) {
                 this.totalPages = pdfData.numPages;
                 this.loading = false;
                 this.loaded=true;
               }

}

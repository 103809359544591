import { Component, OnInit } from '@angular/core';
import { UserServiceService } from '../user-service.service';

@Component({
  selector: 'app-new-screen-five',
  templateUrl: './new-screen-five.component.html',
  styleUrls: ['./new-screen-five.component.css']
})
export class NewScreenFiveComponent implements OnInit {
	audio_list5: object[] = [];

  constructor(private feedService: UserServiceService) { }

  ngOnInit() {
    this.podcast();
  }
	podcast() {
		this.feedService.podcast_audio_files_cor().subscribe(
			data => {
				// console.log('podcast listen to journal', data);
				if (data['success']) {
					this.audio_list5 = data['data'];
				}
				// console.log('audio files data for cornonavirus', this.audio_list5[0]['video_url']);
			}
		);
  }
  createRange(number) {
		var items: number[] = [];
		for (var i = 1; i <= number; i++) {
			items.push(i);
		}
		return items;
	}
}

import { Component, OnInit } from '@angular/core';
import {UserServiceService} from "../user-service.service";
import {ActivatedRoute} from '@angular/router';
import {Router} from "@angular/router";
import { Inject } from '@angular/core'; 
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Location} from '@angular/common';
import { LoginService } from '../login.service';



@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})



export class LoginPageComponent implements OnInit {
  
  
  personRe :boolean=true;
  loginpe:boolean=false;
  model:any={};
  users : any;
  returnUrl: any;
  model2:any = {}; model3:any = {};
  countrycode: any;
  m_verify: boolean = false;
  is_verify: boolean = false;
  verification_code: boolean = false;
  temp: any;


  constructor(
      private _location: Location,
      private service: UserServiceService,
      private route: Router,
      private router: ActivatedRoute,
      private dialog: MatDialog,
      private loginService : LoginService,
      @Inject(MAT_DIALOG_DATA) public data: any) {}


ngOnInit() {
  this.returnUrl = this.router.snapshot.queryParams['returnUrl'] || '/';
  if(this.loginService.switch_to_register===true){
    this.loginService.switch_to_register = false;
    this.switch_to_reg_page();
  }
}

password: string = '';
confirmPassword: string = '';
hidePassword: boolean = true;
hideConfirmPassword: boolean = true;

togglePasswordVisibility(): void {
  this.hidePassword = !this.hidePassword;
}

toggleConfirmPasswordVisibility(): void {
  this.hideConfirmPassword = !this.hideConfirmPassword;
}

register(){

  // check if mobile is verified or not
  if(this.model2.password!=this.model2.confirmpassword){
    alert("confirm password doesn't match");
    return;
  }
  if(!this.validatePassword()){
    // alert("Password must be at least 8 characters long");
    return;
  }
  if(!this.m_verify) {
    //If not verified then don't return and don't register user
    alert('verify the phone number');
    return;
  }
 
  this.service.registration(JSON.stringify(this.model2))
        .subscribe(
            data =>{
              console.log(data);
              if(data['success']){
                alert(data["message"]);                
              }
            },
            error =>{
              console.log(error);
            });
  }


  function (value) {
    this.countrycode = value;
    this.model2.country_code = value;
  }

  validatePassword(): boolean {
    const password = this.model2.password;
  
    // Check length
    if (password.length < 8) {
      alert("Password must be at least 8 characters long.");
      return false;
    }
  
    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      alert("Password must contain at least one uppercase letter.");
      return false;
    }
  
    // Check for at least one digit
    if (!/\d/.test(password)) {
      alert("Password must contain at least one digit.");
      return false;
    }
    return true;
  }

  phone_btn_onclick() {

    // check if mobile number and country code is provided by the user
    if(!this.model2.mobile || !this.model2.country_code) {
      alert('please give phone number or select country code');
      return;
    } 

    
    //adjust model object accordingly
    this.model3.phone_number = this.model2.mobile;
    this.model3.country_code = this.model2.country_code;
    this.model3.via ='sms';
    

    //call the api to send verification code to user's mobile
    this.service.verification_code_send_signup(this.model3.phone_number,this.model3.country_code).subscribe
    (dat=> {
        let data = dat.json();
        if(data['success']){
          
          //if OTP sent successfully to user's mobile then store returned hash and chage verification_code flag also.
          this.verification_code = true;
            this.model3.hash = data['hash'];
        } 
        
        else{
            alert('Error');
        }
    });  
  }


  //Verify whether user enters correct otp or not
  verify_code(){
    this.service.verify_code_signup(this.model3.token,this.model3.phone_number,this.model3.hash).subscribe
    (da=> {
        let data = da.json();

        //if OTP verified successfully then if block will execute
        if(data['success']){
            alert('Verification Done');
            this.verification_code = false;
            this.m_verify=true;
            this.is_verify = true;
        } else{
            alert('Please enter the correct OTP');
            this.verification_code = true;
            this.m_verify=false;
        }
    });
}


 

switch_to_login_page()
{
  this.loginpe = false;
  this.personRe = true;
}



switch_to_reg_page()
{
   this.personRe = false;
   this.loginpe = true;
}



login(){
      this.service.mail_checking_login(this.model2.user_id,this.model2.password).subscribe(data => {
          if(data['success']){
              localStorage.setItem('currentUser', JSON.stringify(data['data']));
              alert(data["message"]);
             this.route.navigateByUrl(this.returnUrl);

          }
          else{
              alert(data["message"]);
          }
      })
  }

}


<div class="nav-sticky">
  <div class="container">
    <div class="row disp-desk-none" fxLayoutAlign.lt-md="start center">
      <div class="col-xs-2 col-sm-1 col-md-1 desk-none" fxLayoutAlign="center center">
        <button mat-button [matMenuTriggerFor]="menu3" style="background-color: #fff;"> <i class="fa fa-bars mobile-view iphn-x-menu" style="background-color: #fff;color:#424242;font-size: 20px;"></i></button>
        <mat-menu #menu3="matMenu">

          <a [routerLink]="['/']"><button mat-menu-item>Home</button></a>
          <a [routerLink]="['/live']"><button mat-menu-item>Live</button></a>
          <a [routerLink]="['/all/live-updates']"><button mat-menu-item>Live Updates</button></a>
          <a [routerLink]="['/insights']"><button mat-menu-item>Insights</button></a>
          <a [routerLink]="['/podcast']"><button mat-menu-item>Podcast</button></a>
          <a [routerLink]="['/tv']"><button mat-menu-item>TV</button></a>
          <a [routerLink]="['/images']"><button mat-menu-item>Images</button></a>
          <a [routerLink]="['/slides']"><button mat-menu-item>Slides</button></a>        
          <a [routerLink]="['/specials']"><button mat-menu-item>Specials</button></a>
          <a [routerLink]="['/survey-100119']"><button mat-menu-item>Survey</button></a>



          <button mat-menu-item [matMenuTriggerFor]="submenu1">More</button>
               
          <mat-menu #submenu1="matMenu">

            <a [routerLink]="['/the-interview']"> <button mat-menu-item>The Interview</button></a>
            <a [routerLink]="['/one-more-thing']"> <button mat-menu-item>One More Thing</button></a>
            <a [routerLink]="['/the-speakers']"> <button mat-menu-item>Speakers</button></a>
            <a [routerLink]="['/in-conversation']"> <button mat-menu-item>In Conversation</button></a>
            <a [routerLink]="['/journals']"> <button mat-menu-item>Journals</button></a>
            <a [routerLink]="['/synapse']"> <button mat-menu-item>Synapse</button></a>

          </mat-menu>

        <a *ngIf="Logged===false">
          <!-- <button mat-menu-item (click)="openDialog()" class="login-botton">Login</button> -->
          <a [routerLink]="['/login-page']"><button mat-menu-item type="button" class="login-botton">Login</button></a>
        </a>
        <a *ngIf="Logged===true">
          <button mat-menu-item (click)="logout()" class="logout-botton">Logout</button>
        </a>

        </mat-menu>


      </div>
      <div class="col-xs-10 col-sm-11 col-md-11 col-lg-12 header-logo">
        <picture>
          <source type="image/webp" srcset="https://storage.googleapis.com/web-assets/tv-webps/home-podcast-tv-webps/new/insights-logo-1.webp">
            <img src="https://storage.googleapis.com/web-assets/insights-logo-1.png" alt="TheRightDoctors Logo">
          </picture>
        <!-- <div class=""><p class="pre_alpha">Pre Alpha</p></div> -->
      </div>
    </div>

    <!-- <div class="header-logo1">
      <img src="https://storage.googleapis.com/web-assets/insights-logo-1.png" alt="TheRightDoctors Logo"><br>
      <div class="pre_alpha">Pre Alpha</div>
    </div> -->
    <div class="row desk-brdr">

      <div class="col-md-3 col-lg-3 algn-btm" fxLayoutAlign="start end">
        <p class="time-btn-pad" style="color:#424242;">{{this.today}}</p>
      </div>
      <div class="col-md-6 col-lg-6">
        <picture>
          <source type="image/webp" srcset="https://storage.googleapis.com/web-assets/tv-webps/home-podcast-tv-webps/new/insights-logo-1.webp">
            <img src="https://storage.googleapis.com/web-assets/insights-logo-1.png" alt="TheRightDoctors Logo" style="width:300px;display: block;  margin: auto;">
          </picture>
      
      </div>
      <div class="col-md-3 col-lg-3 row time-btn-pad algn-btm" fxLayoutGap="10px" fxLayoutAlign="end end">
        <button mat-flat-button class="btn-subs" [routerLink]="['/subscription']">Subscribe</button>
        <button mat-flat-button class="btn-contr" [routerLink]="['/plan']">Contribute</button>
      </div>
    </div>
    <div class="row nav-items-list">
      <ul class="desk-nav-inline" fxLayoutAlign="center center" fxLayout="row">
        <li><a [routerLink]="['/']">Home</a></li>
        <li><a [routerLink]="['/live']">Live</a></li>
        <li><a [routerLink]="['/all/live-updates']">Live Updates</a></li>
        
        <li><a [routerLink]="['/insights']">Insights</a></li>
        <li><a [routerLink]="['/podcast']">Podcast</a></li>
        <li><a [routerLink]="['/tv']">TV</a></li>
        <li><a [routerLink]="['/images']">Images</a></li>
        <li><a [routerLink]="['/slides']">Slides</a></li>
        <li><a [routerLink]="['/specials']">Specials</a></li>
        <li><a [routerLink]="['/survey-100119']">Survey</a></li>
        <li>
          <button mat-button [matMenuTriggerFor]="menu1" style="background-color: #fff !important;">
            <span style="color:#000;">More</span>
            <!-- <i class="fas fa-ellipsis-h"></i> -->
          </button>
          <mat-menu #menu1="matMenu">

              <a [routerLink]="['/the-interview']"> <button mat-menu-item>The Interview</button></a>
              <a [routerLink]="['/one-more-thing']"> <button mat-menu-item>One More Thing</button></a>
              <a [routerLink]="['/the-speakers']"> <button mat-menu-item>Speakers</button></a>
              <a [routerLink]="['/in-conversation']"> <button mat-menu-item>In Conversation</button></a>
              <a [routerLink]="['/journals']"> <button mat-menu-item>Journals</button></a>

              <a [routerLink]="['/synapse']"> <button mat-menu-item>Synapse</button></a>


          </mat-menu>
        </li>
        <li *ngIf="Logged===false">
          <!-- <button type="button" (click)="openDialog()" class="login-botton">Login</button> -->
          <a [routerLink]="['/login-page']"><button type="button" class="login-botton">Login</button></a>
        </li> 
        <li *ngIf="Logged===true">
          <button type="button" (click)="logout()" class="logout-botton">Logout</button>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- <div ng-sticky [offSet]="0" [addClass]="'is-sticky'">
    <div class="top_line wow fadeIn animated " class="desktop-view bor-nav-top" xmlns="http://www.w3.org/1999/html">
        <div class="container relative">
            <div>
                <div class="pull-left col-md-3 pad-left-nopx" fxLayout.gt-sm="row">
                    <div fxLayout="column" class="logos">
                        <img src="/assets/new-home-page/single/images/logo_6303dbf9adadd170aac4fd62a1f6c410.webp" alt="TheRightDoctors Logo">
                        <div class="pre_alpha">Pre Alpha</div>
                    </div>

                </div>
                <div class="pull-5 col-md-7" align="center">
                    <div class="insight_frm">Insights from the World's Best Medical Minds</div>
                </div>
                <div class="col-md-2 img-nine pad-right-nopx" style="display: inline !important;" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="15px">
                    <button type="button" style="background-color: #3f51b5;color:#fff;font-weight: bold;" [routerLink]="['/plan']">Contribute</button>
                    <button type="button" style="background-color: #3f51b5;color:#fff;font-weight: bold;" [routerLink]="['/subscription']">Subscribe</button>


                </div>
            </div>
        </div>
    </div>
    <div class="nav-desk">
        <mat-toolbar class="newsty">
            <span class="fill-remaining-space"></span>
            <div class="container">
                <ul class="menustyle">

                    <li><a [routerLink]="['/']">Home</a></li>
                    <li><a [routerLink]="['/live']">Live</a></li>
                    <li><a [routerLink]="['/coronavirus/live-updates']">Live Updates</a></li>
                    
                    <li><a [routerLink]="['/insights']">Insights</a></li>
                    <li><a [routerLink]="['/podcast']">Podcast</a></li>
                    <li><a [routerLink]="['/tv']">TV</a></li>
                    <li><a [routerLink]="['/images']">Images</a></li>
                    <li><a [routerLink]="['/slides']">Slides</a></li>
                    <li><a [routerLink]="['/specials']">Specials</a></li>
                    <li><a [routerLink]="['/survey-100119']">Survey</a></li>



                    <li *ngIf="Logged===false"><a [routerLink]="['/profile']">Profile</a></li>
                    <li>
                        <button mat-button [matMenuTriggerFor]="menu1" style="background-color: #cc1318 !important;"><span class="material-icons">more_horiz</span></button>
                        <mat-menu #menu1="matMenu">

                            <a [routerLink]="['/the-interview']"> <button mat-menu-item>The Interview</button></a>
                            <a [routerLink]="['/one-more-thing']"> <button mat-menu-item>One More Thing</button></a>
                            <a [routerLink]="['/the-speakers']"> <button mat-menu-item>Speakers</button></a>
                            <a [routerLink]="['/in-conversation']"> <button mat-menu-item>In Conversation</button></a>
                            <a [routerLink]="['/journals']"> <button mat-menu-item>Journals</button></a>

                            <a [routerLink]="['/synapse']"> <button mat-menu-item>Synapse</button></a>


                        </mat-menu>
                    </li>
                    <li *ngIf="Logged===true">

                        <button type="button" (click)="openDialog()" class="login-botton" style="margin-top: 5px;">Login</button>

                    </li>

                    <li *ngIf="Logged===false">

                        <button type="button" (click)="logout()" class="logout-botton" style="margin-top: 5px;">Logout</button>

                    </li>


                </ul>
            </div>
        </mat-toolbar>
        <div *ngIf="isShow">
            <div class="container search-design">
                <input type="text" class="in-put-form" placeholder="Search....." />

            </div>
        </div>
    </div>
</div>
<div ng-sticky [offSet]="0" [addClass]="'is-sticky'">
    <div class="height-adj-m">
        <div class="top_line wow fadeIn animated mobile-view bor-top-nav-mobile">
            <div class="container relative">
                <div class="pull-left" style="background:#fff">
                    <img src="/assets/new-home-page/single/images/logo_6303dbf9adadd170aac4fd62a1f6c410.webp" alt="TheRightDoctors Logo" class="logo-m">
                    <span *ngIf="this.home==='Home'" class="span-page-title">
        <a [routerLink]="['/']">Home</a>
      </span>
                    <span *ngIf="this.home==='The-Interview'" class="span-page-title">
        <a [routerLink]="['/the-interview']">The Interview</a>
      </span>
                    <span *ngIf="this.home==='Live-Updates'" class="span-page-title">
<a [routerLink]="['/coronavirus/live-updates']">COVID-19 Live-Updates</a>
</span>
                    <span *ngIf="this.home=='The-Speakers'" class="span-page-title">
        <a [routerLink]="['/the-speakers']">The Speakers</a>
      </span>
                    <span *ngIf="this.home==='One-More-Thing'" class="span-page-title">
        <a [routerLink]="['/one-more-thing']">One More Thing</a>
      </span>
                    <span *ngIf="this.home==='In-Conversation'" class="span-page-title">
        <a [routerLink]="['/in-conversation']">In Conversation</a>
      </span>
                    <span *ngIf="((this.home==='Specials'))" class="span-page-title">
        <a [routerLink]="['/specials']">Specials</a>
      </span>
                    <span *ngIf="((this.home==='Dr-Hk-Chopra'))" class="span-page-title">
        <a [routerLink]="['/Dr-Hk-Chopra-Sr-Cardiologist-Moolchand-Hospital-Delhi']"> Dr Hk Chopra</a>
      </span>
                    <span *ngIf="((this.home==='Dr-Manish-Verma'))" class="span-page-title">
        <a [routerLink]="['/Dr-Manish-Verma-Sr-Cardiologist-Fortis-Hospital-Bangalore']">Dr Manish Verma</a>
      </span>
                    <span *ngIf="((this.home==='Dr-Rishi-Jain'))" class="span-page-title">
        <a [routerLink]="['/Dr-Rishi-Jain-Sr-Cardiologist']">Dr Rishi Jain</a>
      </span>
                    <span *ngIf="this.home==='Revolution-Talk'" class="span-page-title">
        <a [routerLink]="['/revolution-talk']">Revolution Talk</a>
      </span>
                    <span *ngIf="this.home==='Synapse'" class="span-page-title">
        <a [routerLink]="['/synapse']">Synapse</a>
      </span>
                    <span *ngIf="this.home==='Live'" class="span-page-title">
        <a [routerLink]="['/the-interview']">Live</a>
      </span>
                    <span *ngIf="this.home==='Live-Cases'" class="span-page-title">
        <a [routerLink]="['/csinic-2016/live_cases']">Live Cases</a>
      </span>
                    <span *ngIf="this.category==='the-interview'" class="span-page-title">
        <a [routerLink]="['/the-interview']">The Interview</a>
      </span>
                    <span *ngIf="this.category==='the-speakers'" class="span-page-title">
        <a [routerLink]="['/the-speakers']">The Speakers</a>
      </span>
                    <span *ngIf="this.category==='one-more-thing'" class="span-page-title">
        <a [routerLink]="['/one-more-thing']">One More Thing</a>
      </span>
                    <span *ngIf="this.category==='in-conversation'" class="span-page-title">
        <a [routerLink]="['/in-conversation']">In Conversation</a>
      </span>
                    <span *ngIf="(this.category==='live-case')" class="span-page-title">
        <a [routerLink]="['/csinic-2016/live_cases']">Live Cases</a>
      </span>
                    <span *ngIf="this.category==='revolution-talk'" class="span-page-title">
        <a [routerLink]="['/revolution-talk']">Revolution Talk</a>
      </span>
                    <span *ngIf="(this.category==='highlights')" class="span-page-title">
        <a [routerLink]="['/']">Highlights</a>
      </span>
                    <span *ngIf="(this.category==='ias-2014')" class="span-page-title">
        <a [routerLink]="['/specials/ias-2014']">IAS 2014</a>
      </span>
                    <span *ngIf="(this.category==='wcchd-2015')" class="span-page-title">
        <a [routerLink]="['/specials/wcchd-2015']">WCCHD 2015</a>
      </span>
                    <span *ngIf="(this.category==='csicon-2014')" class="span-page-title">
        <a [routerLink]="['/specials/csicon-2014']">CSICON 2014</a>
      </span>
                    <span *ngIf="(this.category==='wccpci-2015')" class="span-page-title">
        <a [routerLink]="['/specials/wccpci-2015']">WCCPCI 2015</a>
      </span>
                    <span *ngIf="(this.category==='cardiac-prevent-2015')" class="span-page-title">
        <a [routerLink]="['/specials/cardiac-prevent-2015']">Cardiac Prevent 2015</a>
      </span>
                    <span *ngIf="(this.category==='ihj-2015')" class="span-page-title">
        <a [routerLink]="['/specials/ihj-2015']">IHJ 2015</a>
      </span>
                    <span *ngIf="(this.category==='wccpci-2016')" class="span-page-title">
        <a [routerLink]="['/specials/wccpci-2016']">WCCPCI 2016</a>
      </span>
                    <span *ngIf="(this.category==='csikochi-2016')" class="span-page-title">
        <a [routerLink]="['/specials/csikochi-2016']">CSIKochi 2016</a>
      </span>
                    <span *ngIf="(this.category==='csinic-2016')" class="span-page-title">
        <a [routerLink]="['/specials/csinic-2016']">CSINIC 2016</a>
      </span>
                    <span *ngIf="(this.category==='apvic')" class="span-page-title">
        <a [routerLink]="['/specials/apvic']">APVIC</a>
      </span>
                    <span *ngIf="(this.category==='c3')" class="span-page-title">
        <a [routerLink]="['/specials/c3']">C3</a>
      </span>
                    <span *ngIf="(this.category==='wccicc-2017')" class="span-page-title">
        <a [routerLink]="['/specials/wccicc-2017']">WCCICC 2017</a>
      </span>

                    <span *ngIf="(this.category==='rssdi-ahmedabad-2018')" class="span-page-title">
        <a [routerLink]="['/specials/rssdi-ahmedabad-2018']">Wockhardt RSSDI tv</a>
      </span>

                    <span *ngIf="(this.category==='stemi-india-lucknow-2018')" class="span-page-title">
        <a [routerLink]="['/specials/stemi-india-lucknow-2018']">STEMI India 2018</a>
      </span>

                    <span *ngIf="((this.categoryy==='highlights') && (this.categorye == 'wccicc-2017'))" class="span-page-title">
        <a [routerLink]="['/specials/wccicc-2017']"
          class="log-side-title" >WCCICC 2017  <span> Highlights </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='highlights') && (this.categorye == 'csinic-2016'))" class="span-page-title">
        <a [routerLink]="['/specials/wccicc-2017']"
           class="log-side-title" >CSINIC 2016 <span> Highlights </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='highlights') && (this.categorye == 'wccpci-2015'))" class="span-page-title">
        <a [routerLink]="['/specials/wccicc-2017']"
           class="log-side-title">WCCPCI 2015 <span> Highlights </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='highlights') && (this.categorye == 'wccpci-2016'))">
        <a [routerLink]="['/specials/wccicc-2017']"
           class="log-side-title">WCCPCI 2016 <span> Highlights </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='the-speakers') && (this.categorye == 'wccpci-2016'))">
        <a [routerLink]="['/specials/wccpci-2016/the-speakers']" class="log-side-title">WCCPCI 2016 <span> The Speakers </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='the-interview') && (this.categorye == 'wccpci-2016'))">
        <a [routerLink]="['/specials/wccpci-2016/the-interview']" class="log-side-title">WCCPCI 2016 <span> The Interview </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='one-more-thing') && (this.categorye == 'wccpci-2016'))">
        <a [routerLink]="['/specials/wccpci-2016/one-more-thing']" class="log-side-title">WCCPCI 2016 <span> One More Thing </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='the-speakers') && (this.categorye == 'wccpci-2015'))">
        <a [routerLink]="['/specials/wccpci-2015/the-speakers']" class="log-side-title">WCCPCI 2015 <span> The Speakers </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='the-interview') && (this.categorye == 'wccpci-2015'))">
        <a [routerLink]="['/specials/wccpci-2015/the-interview']" class="log-side-title">WCCPCI 2015 <span> The Interview </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='one-more-thing') && (this.categorye == 'wccpci-2015'))">
        <a [routerLink]="['/specials/wccpci-2015/one-more-thing']" class="log-side-title">WCCPCI 2015 <span> One More Thing </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='the-speakers') && (this.categorye == 'csinic-2016'))">
        <a [routerLink]="['/specials/csinic-2016/the-speakers']" class="log-side-title">CSINIC 2016 <span> The Speakers </span></a>
                    </span>

                    <span *ngIf="((this.categoryy==='the-interview') && (this.categorye == 'stemi-india-lucknow-2018'))">
        <a [routerLink]="['/specials/stemi-india-lucknow-2018/the-interview']"
           style="color: #333;font-size:18px;margin-top:27px;">STEMI India 2018 <span class="mobile-title-logo"> The Interview </span></a>
                    </span>

                    <span *ngIf="((this.categoryy==='the-interview') && (this.categorye == 'rssdi-ahmedabad-2018'))">
        <a [routerLink]="['/specials/rssdi-ahmedabad-2018/the-interview']"
           style="color: #333;font-size:18px;margin-top:27px;">Wockhardt RSSDI tv<span class="mobile-title-logo"> The Interview </span></a>
                    </span>

                    <span *ngIf="((this.categoryy==='highlights') && (this.categorye == 'rssdi-ahmedabad-2018'))">
        <a [routerLink]="['/specials/rssdi-ahmedabad-2018/highlights']"
           style="color: #333;font-size:18px;margin-top:27px;">Wockhardt RSSDI tv<span class="mobile-title-logo">Highlights</span></a>
                    </span>


                    <span *ngIf="((this.categoryy==='live_cases') && (this.categorye == 'csinic-2016'))">
        <a [routerLink]="['/specials/csinic-2016/live_cases']" class="log-side-title">CSINIC 2016 <span> Live Cases </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='the-interview') && (this.categorye == 'csinic-2016'))">
        <a [routerLink]="['/specials/csinic-2016/the-interview']" class="log-side-title">CSINIC 2016 <span> The Interview </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='one-more-thing') && (this.categorye == 'csinic-2016'))">
        <a [routerLink]="['/specials/csinic-2016/one-more-thing']" class="log-side-title">CSINIC 2016 <span> One More Thing </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='the-interview') && (this.categorye == 'ias-2014'))">
        <a [routerLink]="['/specials/ias-2014/the-interview']" class="log-side-title">IAS 2014 <span> The Interview </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='the-interview') && (this.categorye == 'wcchd-2015'))">
        <a [routerLink]="['/specials/wcchd-2015/the-interview']" class="log-side-title">WCCHD 2015 <span> The Interview </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='highlights') && (this.categorye == 'csicon-2014'))">
        <a [routerLink]="['/specials/csicon-2014/highlights']" class="log-side-title">CSICON 2014 <span> Highlights </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='the-speakers') && (this.categorye == 'csicon-2014'))">
        <a [routerLink]="['/specials/csicon-2014/the-speakers']" class="log-side-title">CSICON 2014 <span> The Speakers </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='the-interview') && (this.categorye == 'csicon-2014'))">
        <a [routerLink]="['/specials/csicon-2014/the-interview']" class="log-side-title">CSICON 2014 <span> The Interview </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='one-more-thing') && (this.categorye == 'csicon-2014'))">
        <a [routerLink]="['/specials/csicon-2014/one-more-thing']" class="log-side-title">CSICON 2014 <span> One More Thing </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='highlights') && (this.categorye == 'apvic'))">
        <a [routerLink]="['/specials/apvic/highlights']"
           class="log-side-title">APVIC <span> Highlights </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='the-interview') && (this.categorye == 'apvic'))">
        <a [routerLink]="['/specials/apvic/the-interview']"
           class="log-side-title">APVIC <span> The Interview </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='one-more-thing') && (this.categorye == 'apvic'))">
        <a [routerLink]="['/specials/apvic/one-more-thing']"
           class="log-side-title">APVIC <span> One More Thing </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='highlights') && (this.categorye == 'c3'))">
        <a [routerLink]="['/specials/c3/highlights']" class="log-side-title">C3 <span> Highlights </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='highlights') && (this.categorye == 'csikochi-2016'))">
        <a [routerLink]="['/specials/csikochi-2016/highlights']" class="log-side-title">CSIKochi <span> Highlights </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='the-speakers') && (this.categorye == 'csikochi-2016'))">
        <a [routerLink]="['/specials/csikochi-2016/the-speakers']" class="log-side-title">CSIKochi 2016 <span> The Speakers </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='live_cases') && (this.categorye == 'csikochi-2016'))">
        <a [routerLink]="['/specials/csikochi-2016/live_cases']" class="log-side-title">CSIKochi 2016 <span> Live Cases </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='the-interview') && (this.categorye == 'csikochi-2016'))">
        <a [routerLink]="['/specials/csikochi-2016/the-interview']" class="log-side-title">CSIKochi 2016 <span> The Interview </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='one-more-thing') && (this.categorye == 'csikochi-2016'))">
        <a [routerLink]="['/specials/csikochi-2016/one-more-thing']"
           class="log-side-title">CSIKochi 2016 <span> One More Thing </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='the-speakers') && (this.categorye == 'wccicc-2017'))">
        <a [routerLink]="['/specials/wccicc-2017/the-speakers']" class="log-side-title">WCCICC 2017 <span> The Speakers </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='live_cases') && (this.categorye == 'wccicc-2017'))">
        <a [routerLink]="['/specials/wccicc-2017/live_cases']" class="log-side-title">WCCICC 2017 <span> Live Cases </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='the-interview') && (this.categorye == 'wccicc-2017'))">
        <a [routerLink]="['/specials/wccicc-2017/the-interview']" class="log-side-title">WCCICC 2017 <span> The Interview </span></a>
                    </span>
                    <span *ngIf="((this.categoryy==='one-more-thing') && (this.categorye == 'wccicc-2017'))">
        <a [routerLink]="['/specials/wccicc-2017/one-more-thing']" class="log-side-title">WCCICC 2017 <span> One More Thing </span></a>
                    </span>




                </div>
                <div class="pull-right nne">

                    <div fxLayout="row" class="sub-con" fxLayoutGap="10px">
                        <button mat-flat-buton style="background-color: #3f51b5;color:#fff;font-weight: bold;" [routerLink]="['/plan']">Contribute</button>
                        <button mat-flat-buton style="background-color: #3f51b5;color:#fff;font-weight: bold;" [routerLink]="['/subscription']">Subscribe</button>

                    </div>
                    <i class="fa fa-bars mobile-view iphn-x-menu" [matMenuTriggerFor]="menu"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="mobile-view">
        <mat-menu #menu="matMenu" [overlapTrigger]="false" yPosition="above">
            <button mat-menu-item [routerLink]="['/']">Home</button>
            <button mat-menu-item [routerLink]="['/live']">Live</button>
            <button mat-menu-item [routerLink]="['/coronavirus/live-updates']">Live Updates</button>





            <a href="https://therightdoctors.com/insights"> <button mat-menu-item>Insights</button></a>

            <a href="https://therightdoctors.com/tv"> <button mat-menu-item [routerLink]="['/one-more-thing']">TV</button></a>
            <a href="https://therightdoctors.com/podcast"> <button mat-menu-item [routerLink]="['/the-speakers']">Podcast</button></a>
            <a href="https://therightdoctors.com/images"> <button mat-menu-item [routerLink]="['/in-conversation']">Images</button></a>
            <a [routerLink]="['/slides']"> <button mat-menu-item [routerLink]="['/specials']">Slides</button></a>
            <a [routerLink]="['/specials']"><button mat-menu-item>Specials</button></a>
            <a [routerLink]="['/survey-100119']"><button mat-menu-item>Survey</button></a>
        </mat-menu>
    </div>
</div> -->



























import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
var CanonService = (function () {
    function CanonService(dom) {
        this.dom = dom;
    }
    CanonService.prototype.createCanonicalURL = function (url) {
        var canURL = url == undefined ? this.dom.URL : url;
        var link = this.dom.createElement('link');
        link.setAttribute('rel', 'canonical');
        this.dom.head.appendChild(link);
        link.setAttribute('href', canURL);
    };
    CanonService.ngInjectableDef = i0.defineInjectable({ factory: function CanonService_Factory() { return new CanonService(i0.inject(i1.DOCUMENT)); }, token: CanonService, providedIn: "root" });
    return CanonService;
}());
export { CanonService };

import { Component, OnInit } from '@angular/core';
import { CatagoryServiceService } from '../catagory-service.service';

@Component({
  selector: 'app-new-screen-seven',
  templateUrl: './new-screen-seven.component.html',
  styleUrls: ['./new-screen-seven.component.css']
})
export class NewScreenSevenComponent implements OnInit {
	conv_image: any;

  constructor(private service: CatagoryServiceService) { }

  ngOnInit() {
    this.ppt();
  }
	ppt() {
		this.service.the_last_word_iages().subscribe(
			data => {
				let da = data.json();
				if (da['success']) {
					this.conv_image = da['data'];
				}


			}
		);
  }
  
  createRange(number) {
		var items: number[] = [];
		for (var i = 1; i <= number; i++) {
			items.push(i);
		}
		return items;
	}
}

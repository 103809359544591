function _window() {
    return window;
}
var WindowRefSubscription = (function () {
    function WindowRefSubscription() {
    }
    Object.defineProperty(WindowRefSubscription.prototype, "nativeWindow", {
        get: function () {
            return _window();
        },
        enumerable: true,
        configurable: true
    });
    return WindowRefSubscription;
}());
export { WindowRefSubscription };

<app-nav> </app-nav>
<div class="new-screen1">
<div class="container" fxLayout="column" fxLayoutAlign="center center">
  <div fxLayout.lt-sm="column" fxLayout.gt-xs="row" fxLayoutGap.lt-sm="10px" fxLayoutGap.gt-xs="10px">
     
      <ng-template ngFor let-item [ngForOf]="createRange(pdf_list.length)" let-currentIndex='index'>
          <div fxFlex.lt-sm="100%" fxFlex.gt-xs="50%" fxFlexAlign="center">
              <div fxLayoutAlign="center center">
                <mat-card style="max-width: 250px;">
                    <a [routerLink]="['/aoc-2020/pdf/' + pdf_list[currentIndex]['slug']]" style="cursor: pointer;">
                        <div fxLayoutAlign="center center">
                            <img src="{{pdf_list[currentIndex]['image_url']}}" width="100%" alt="TheRightDoctors">
                        </div>
                        <h6 style="text-align: center; padding-top: 5px;" class="tv-drname-btm" >{{ pdf_list[currentIndex]['sub_headline']}}</h6>
                        <h5 class="tv-headline-btm"style="text-align:center;padding-top: 5px;" >{{ pdf_list[currentIndex]['headline'] |slice:0:60}}</h5>
                    </a>
                    <div style="padding-top: 5px; padding-bottom: 2px;" fxLayoutAlign="center center">
                        <button (click) = "downloadImage(pdf_list[currentIndex]['url'])">Download PDF</button>
                    </div>
  
                </mat-card>
              </div>
              
          </div>
      </ng-template>
<!-- 

      <div   fxFlex.lt-md="50%" >
        <mat-card fxLayout="column" >
            <a [routerLink]="['/aoc-2020/pdf/dr-dev-pahlajani-pci-in-women-are-outcomes-different-in-men']" style="cursor: pointer;">
                <div >                                   
                    <span></span>
                    <img src="https://storage.googleapis.com/web-assets/aoc-mumbai/dr-dev-pahlajani-pci-in-women-are-outcomes-different-in-men.PNG" alt="TheRightDoctors">
                </div>
            <h6 class="tv-drname-btm">Dr. Dev Pahlajani</h6>
            <h5 class="tv-headline-btm">PCI in Women: Are Outcomes different in Men</h5></a>
            <br>
            <button (click) = "downloadImage('https://storage.googleapis.com/web-assets/AOC-2020/Dev%20Pahlajani.pdf')">Download PDF</button>
        </mat-card>
    </div>

    <div fxFlex.lt-md="50%" >
      <mat-card fxLayout="column"  >
          <a [routerLink]="['/aoc-2020/pdf/dr-nanette-kas-wenger-transforming-cvd-prevention-for-women']" style="cursor: pointer;">
              <div >                                   
                  <span></span>
                  <img src="https://storage.googleapis.com/web-assets/aoc-mumbai/dr-nanette-kas-wenger-transforming-cvd-prevention-for-women.PNG" alt="TheRightDoctors">
              </div>
          <h6 class="tv-drname-btm">Dr. Nanette Kas</h6>
          <h5 class="tv-headline-btm">Transforming CVD prevention for Women</h5></a>
          <br>
          <button (click) = "downloadImage('https://storage.googleapis.com/web-assets/AOC-2020/Wenger%202.pdf')">Download PDF</button>
      </mat-card>
  </div> -->
  </div>
</div>
</div>
<app-footer></app-footer>

import { Component, OnInit } from '@angular/core';
import {LoginserviceService} from '../loginservice.service';
@Component({
  selector: 'app-magzine-landing-page',
  templateUrl: './magzine-landing-page.component.html',
  styleUrls: ['./magzine-landing-page.component.css']
})
export class MagzineLandingPageComponent implements OnInit {

  pdf_list = 
    [
        {
          'image_url': 'https://storage.googleapis.com/web-assets/aoc-mumbai/dr-dev-pahlajani-pci-in-women-are-outcomes-different-in-men.PNG',
          'image_alt':'Dr. Dev Pahlajani',
          'slug':'dr-dev-pahlajani-pci-in-women-are-outcomes-different-in-men',
          'sub_headline':'Dr. Dev Pahlajani',
          'headline':'PCI in Women: Are Outcomes different in Men',
          'url' : 'https://storage.googleapis.com/web-assets/AOC-2020/Dev%20Pahlajani.pdf'
        },

        {
          'image_url':'https://storage.googleapis.com/web-assets/aoc-mumbai/dr-nanette-kas-wenger-transforming-cvd-prevention-for-women.PNG',
          'image_alt':'Dr. Nanette Kas',
          'slug':'dr-nanette-kas-wenger-transforming-cvd-prevention-for-women',
          'sub_headline':'Dr. Nanette Kas',
          'headline':'Transforming CVD prevention for Women',
          'url': 'https://storage.googleapis.com/web-assets/AOC-2020/Wenger%202.pdf'
        }
    ]




  constructor(private service: LoginserviceService) { }

  ngOnInit() {
    this.leng();
  }
  
  createRange(number) {
    var items: number[] = [];
    for (var i = 1; i <= number; i++) {
        items.push(i);
    }
    return items;
    }

    downloadImage(url) {
      console.log('aaaaa');
     this.service.getDownload(url);

    }

    leng(){
      console.log(this.pdf_list.length);
    }


}

import { Component, OnInit } from '@angular/core';
import { Router ,ActivatedRoute, NavigationEnd} from '@angular/router';
import {FirebaseServiceService} from "../firebase-service.service";
import {DataService} from "../data.service";
import * as firebase from 'firebase/app';
import {Live_Details} from "../live_details";
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';
import {Fileup} from "../fileup";
import {UserServiceService} from "../user-service.service";
@Component({
  selector: 'app-live-update-edit-page',
  templateUrl: './live-update-edit-page.component.html',
  styleUrls: ['./live-update-edit-page.component.css']
})
export class LiveUpdateEditPageComponent implements OnInit {
  video : any = {}; 
  useriids : any;
  u_id:any;

  id :any;
  datatitle :any;
  dataid :any;

  constructor(private fbs: FirebaseServiceService, private dataservice:DataService,private route: Router,
    private router: ActivatedRoute,) { }

  ngOnInit() {
    this.router.params.subscribe(params => {
      console.log('this is llovwee,',params);
      this.id = params['id'];

    });

    console.log(this.id);
    var need_arr = []
    var keyarray = []

     firebase.database().ref('/live-updates').once("value").then(function(snapshot){
          
       snapshot.forEach(function(childSnapshot){
  
         var childKey = childSnapshot.key;
         var childData = childSnapshot.val();
         need_arr.unshift(childData)
         keyarray.unshift(childKey)
      
       });
     });
     this.datatitle = need_arr;
     this.dataid = keyarray ;

     console.log("retrieving data")



  }


  edituser(f_title:string,f_subheadline:string,f_description:string,f_video_subheadline:string){
    console.log("Hi");
     this.fbs.Edituser(this.id,f_title,f_subheadline,f_description,f_video_subheadline)
  }
 

}
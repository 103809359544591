
<style>
  .content h2{
    font-size: 23px;
    padding: 40px 0px;
    font-family: 'Open Sans', sans-serif;
  }
  .btn-mar {
    color: #fff;
    background-color: #cc1318;
    border-color: #cc1318;
    width: 100%;
    height: 37px;
    border-radius: 0px;
    margin-bottom: 8px;
    font-size: 17px;
    font-family: 'Open Sans', sans-serif;
  }
  .row-zero{
    margin:auto 0px;
  }
  .btn-default{
    width: 100%;
    border-radius: 0px;
    height: 37px;
    background-color: #fff;
    border-color:#cc1318;
    font-size: 17px;
    color: #cc1318;
  }
  .subscription{
    padding-top: 15px;
    color: #00000085;}
  .subscription a{
    padding-top: 15px;
    color: #00000085;}

  .close-btn{
    color: #cc1318;
    text-align: center;
    line-height: 30px;
    position: absolute;
    z-index: 9999;
    font-size: 23px;
    left: 98%;
    top: -25px;
  }
  .new-des{
    color: #3d3a3a;
    font-size: 15px;
  }

  @media (max-width:767px){
    .m-mar{
      margin-top:10px;
    }

    .content h2 {
      font-size: 17px;
      padding: 13px 0px;
      font-family: 'Open Sans', sans-serif;
    }
    .logo{
      width:50px;
    }
    .close-btn {
      color: #cc1318;
      text-align: center;
      line-height: 30px;
      position: absolute;
      z-index: 9999;
      font-size: 18px;
      left: 93%;
      top: -22px;
    }
    .new-des {
      color: #a9a4a4;
      font-size: 12px;
    }
    .subscription {
      font-size: 11px;
    }
    .btn-default {
      width: 100%;
      border-radius: 0px;
height:auto;
      background-color: #fff;
      border-color: #cc1318;
      font-size: 12px;
      color: #cc1318;
      padding: 5px 20px;
    }
    .btn-mar {
      color: #fff;
      background-color: #cc1318;
      border-color: #cc1318;
      width: 100%;
      height: auto;
      border-radius: 0px;
      margin-bottom: 8px;
      font-size: 13px;
      font-family: 'Open Sans', sans-serif;
      padding: 5px 20px;
    }
  }
@media(min-width:768px)and(max-width:1023px){
  .close-btn {
    color: #cc1318;
    text-align: center;
    line-height: 30px;
    position: absolute;
    z-index: 9999;
    font-size: 18px;
    left: 97%;
    top: -21px;
  }
}
</style>



<section class="login-block11" style="position:relative">
  <i class="fa fa-times close-btn" aria-hidden="true" (click)="close()"></i>
  <div class="row row-zero">

    <div class="col-md-7  col-md-push-5 banner-sec m-mar">
      <img src="assets/ulhas1.jpg" style="width:100%;border-radius: 15px;"/>
    </div>
    <div class="col-md-5  col-md-pull-7  m-mar">
      <img src="/assets/new-home-page/single/images/logo_6303dbf9adadd170aac4fd62a1f6c410.webp" class="logo"/>
      <div class="content">
        <h2>Would you like to Download a Presentation on "ECG:Basics and Beyond" by  Dr.Ulhas Pandurangi<br><span class="new-des">(Chief-Cardiac Electrophysiology & Pacing, The Madras Medical Mission)</span></h2>

        <a href="https://therightdoctors.com/specials/dr-ulhas-pandurangi-fifteenth-annual-electrocardiology-course-basics-and-beyond-madras-medical-mission-2018/presentation/dr-ulhas-m-pandurangi-normal-ecg/5b80f4aa49d928151dccd92b">
          <button type="button" class="btn btn-mar">Yes</button></a>

        <button  type="button" class="btn btn-default" (click)="close()">No Thanks</button>
        <p class="subscription">By Subscribing you agree our <a href="#">Privacy</a> ,<a href="#">Policy</a></p>


      </div>

    </div>

  </div>

</section>



<!--<style>
  .close-btn{
    font-size: 31px;
    color: #fff;
    /* position: relative; */
    /* left: 100%; */
    float: right;
  }

  .pop-up-back-image{
    clear: both;
    width: 100%;
    height: 412px;
    /*background: #0000009c;*/}
  .logo-popup{
    margin-bottom: 20px;
    border-right: 1px solid #f4f4f4;
    padding-right: 10px;
  }
  .mar-popup{
    margin:100px 0px;
  }
  .head-pop{
    font-weight: 500;
    margin-top: -15px;
    font-size: 23px;
    text-align: left;
  }
  .btn-sub-pop{
    background: #d80404;
    color: #fff;
    padding: 6px 40px;
    border-color: #ccc;
  }
  .btn-sub-pop:hover{
    background: #d80404;
    color: #fff;
    padding: 6px 40px;
    border-color: #ccc;
  }
  @media (max-width:599px){
    .head-pop {
      font-weight: 500;
      margin-top: -23px;
      font-size: 14px;
      text-align: left;
    }
    .mar-popup {
      margin: 66px 0px;
    }
    .pop-up-back-image {
      clear: both;
      width: 100%;
      height: 269px;
      /*background: #0000009c;*/

    }
  }




</style>


<div class="pop-up-back-image" align="center">
  <i class="fa fa-times close-btn" aria-hidden="true" (click)="close()"></i>

<div class="">
  <div class="col-md-2"></div>
  <div class="col-md-8 mar-popup">
    <div class="row">
      <div class="col-md-3 col-xs-3 col-sm-3">
     <img alt="TheRightDoctors Logo" src="/assets/new-home-page/single/images/logo_6303dbf9adadd170aac4fd62a1f6c410.webp" class="logo-popup" ></div>
    <br/>
      <div class="col-md-9 col-xs-9 col-sm-9" style="padding:0px">
        <h2 class="head-pop">Cardiac Surgery- A Dying Star or A Supernova?</h2></div></div>
  <form role="form" (ngSubmit)="subscribe_email()" #f="ngForm" novalidate>
    &lt;!&ndash;<mat-form-field style="width:100%">&ndash;&gt;
    <div class="form-group">
      <input class="form-control ng-pristine ng-valid ng-touched" placeholder="Enter Your Email Address to Subscribe" name="email" [(ngModel)]="model.email" required>
      </div>
      &lt;!&ndash;<mat-error *ngIf="inputChecking.hasError('required')">&ndash;&gt;
        &lt;!&ndash;Email is <strong>Required</strong>&ndash;&gt;
      &lt;!&ndash;</mat-error>&ndash;&gt;
    &lt;!&ndash;</mat-form-field>&ndash;&gt;
    <div class="form-group">
      <div class="for_sign_in">
        <button class="btn btn-default btn-sub-pop" type="submit" [disabled]="!f.form.valid">Subscribe
        <i class="fa fa-spinner fa-spin" *ngIf="load"></i></button>
  </div>
    </div>
    <div class="col-md-12">
    <div class="form-group col-md-6">
      <h4><i class="fa fa-whatsapp" style="color:teal;"></i> Whatsapp</h4>
      <a href="https://api.whatsapp.com/send?phone=917729811997&text=JOIN" target="_blank" rel="noopener">
        <button mat-raised-button style="background-color:teal;color:white" type="button">Subscribe</button>
        </a>
    </div>

    <div class="form-group col-md-6">
      <h4><img src="../../assets/facebook-messenger.png" style="width:20px;height:20px"> Facebook</h4>
      <a href="http://m.me/2160155680920904" target="_blank" rel="noopener"><button mat-raised-button color="primary" type="button">Subscribe</button></a>
    </div>
      </div>
  </form>
  </div>
  <div class="col-md-2"></div>
</div>
</div>-->


<style>
    .vid_side_box_inner {
        padding: 0 0 5px 0 !important;
        margin: 5px 0 0 !important;
        border-bottom: 1px solid #eee;
    }
</style>
<div class="new-screen4">
    <div class="container" fxLayout="column">
        <h5 class="all-head">Insights</h5>
        <div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap.lt-sm="0px" fxLayoutGap.lt-md="20px" fxLayoutGap.gt-sm="20px" class="display-mob">
            <div fxFlex.lt-md="100%" fxFlex.gt-sm="50%" fxLayout="column" fxLayoutGap.lt-sm="0px" fxLayoutGap.lt-md="20px" fxLayoutGap.gt-sm="20px">
                <div fxLayout.lt-sm="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="20px">
                    <ng-template ngFor let-item [ngForOf]="createRange(1)" let-currentIndex="index+1">

                        <div fxFlex.lt-sm="100%" fxFlex.gt-xs="60%" class="mob-brdr-btm">
                            <a class="testvideo" href="https://therightdoctors.com/insights/{{blogdata[currentIndex]['category']}}/{{blogdata[currentIndex]['blog_category']}}/{{blogdata[currentIndex]['slug']}}">

                                <div class="mrg-after-imgvid">
                                    <picture>
                                        <source type="image/webp" srcset="{{blogdata[currentIndex]['thumbnail_url1']}}">
                                            <img src="{{blogdata[currentIndex]['image_url']}}" alt="{{blogdata[currentIndex]['headline']}}" style="width:100%" />
                                    </picture>
                                </div>
                            
                            <p class="internal-updates-head">{{blogdata[currentIndex]['headline'] }}</p></a>
                        </div>
                    </ng-template>
                    <div fxFlex.lt-sm="100%" fxFlex.gt-xs="40%" class="deskbg-pad">
                        <ul>
                            <ng-template ngFor let-item [ngForOf]="createRange(1)" let-currentIndex="index+2">
                                <!-- <a class="testvideo" href="https://therightdoctors.com/insights/{{blogdata[currentIndex]['category']}}/{{blogdata[currentIndex]['blog_category']}}/{{blogdata[currentIndex]['slug']}}">

                                    <li>

                                        <p class="internal-updates-head">{{blogdata[currentIndex]['headline'] }}</p>
                                        <div *ngIf="blogdata[currentIndex]['transcript']!=['']">
                                            <p class="descrip">{{blogdata[currentIndex]['transcript'] | slice: 0:100 }}</p>
                                        </div>
                                    </li>
                                </a> -->

                                    <a class="testvideo" href="https://therightdoctors.com/insights/{{blogdata[currentIndex]['category']}}/{{blogdata[currentIndex]['blog_category']}}/{{blogdata[currentIndex]['slug']}}">
        
                                        <div class="mrg-after-imgvid">
                                            <picture>
                                                <source type="image/webp" srcset="{{blogdata[currentIndex]['thumbnail_url1']}}">
                                                    <img src="{{blogdata[currentIndex]['image_url']}}" alt="{{blogdata[currentIndex]['headline']}}" style="width:100%" />
                                                </picture>
                                        </div>
                                    
                                    <p class="internal-updates-head">{{blogdata[currentIndex]['headline'] }}</p></a>

                            </ng-template>
                        </ul>
                    </div>
                </div>
                <div fxLayout.lt-sm="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="20px">
                    <div fxFlex.lt-sm="100%" fxFlex.gt-xs="40%" class="deskbg-pad">
                        <ul>
                            <ng-template ngFor let-item [ngForOf]="createRange(1)" let-currentIndex="index+3">
                                <!-- <a class="testvideo" [routerLink]="['/'+the_interview['data']['feature'][currentIndex]['event']+'/'+the_interview['data']['feature'][currentIndex]['category']+'/'+the_interview['data']['feature'][currentIndex]['slug']]"> -->
                                <!-- <a class="testvideo" href="https://therightdoctors.com/insights/{{blogdata[currentIndex]['category']}}/{{blogdata[currentIndex]['blog_category']}}/{{blogdata[currentIndex]['slug']}}">

                                    <li>

                                        <p class="internal-dr-name">{{blogdata[currentIndex]['headline'] }}</p>
                                        <div *ngIf="blogdata[currentIndex]['transcript']!=['']">
                                            <p class="descrip">{{blogdata[currentIndex]['transcript'] | slice: 0:100 }}</p>
                                        </div>
                                    </li>
                                </a> -->
                                            <a class="testvideo" href="https://therightdoctors.com/insights/{{blogdata[currentIndex]['category']}}/{{blogdata[currentIndex]['blog_category']}}/{{blogdata[currentIndex]['slug']}}">

                                                <div class="mrg-after-imgvid">
                                                    <picture>
                                                        <source type="image/webp" srcset="{{blogdata[currentIndex]['thumbnail_url1']}}">
                                                            <img src="{{blogdata[currentIndex]['image_url']}}" alt="{{blogdata[currentIndex]['headline']}}" style="width:100%" />
                                                        </picture>
                                                </div>
                                            
                                            <p class="internal-updates-head">{{blogdata[currentIndex]['headline'] }}</p></a>
                            </ng-template>
                        </ul>
                    </div>
                    <ng-template ngFor let-item [ngForOf]="createRange(1)" let-currentIndex="index+4">

                        <div fxFlex.lt-sm="100%" fxFlex.gt-xs="60%" class="mob-brdr-btm">
                            <a class="testvideo" href="https://therightdoctors.com/insights/{{blogdata[currentIndex]['category']}}/{{blogdata[currentIndex]['blog_category']}}/{{blogdata[currentIndex]['slug']}}">
                                <div class="mrg-after-imgvid">
                                    <picture>
                                        <source type="image/webp" srcset="{{blogdata[currentIndex]['thumbnail_url1']}}">
                                            <img src="{{blogdata[currentIndex]['image_url'] }}" alt="{{blogdata[currentIndex]['headline']}}" style="width:100%" />
                                        </picture>
                                </div>
                            </a>
                            <p class="internal-updates-head">{{blogdata[currentIndex]['headline']}}</p>
                        </div>
                    </ng-template>
                </div>
            </div>

            <div fxFlex.lt-md="100%" fxFlex.gt-sm="50%">
                <div fxLayout.lt-sm="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="20px">
                    <div fxFlex.lt-sm="100%" fxFlex.gt-sm="50%">
                        <ul>
                            <ng-template ngFor let-item [ngForOf]="createRange(6)" let-currentIndex="index+5">
                                <a class="testvideo" href="https://therightdoctors.com/insights/{{blogdata[currentIndex]['category']}}/{{blogdata[currentIndex]['blog_category']}}/{{blogdata[currentIndex]['slug']}}">

                                    <li>

                                        <div class="brdr-btm" fxLayout="column">
                                            <!-- <p class="internal-dr-name">{{blogdata[currentIndex]['sub_headline'] }}</p> -->
                                            <p class="internal-updates-head">{{blogdata[currentIndex]['headline'] }}</p>
                                        </div>
                                    </li>
                                </a>
                            </ng-template>
                        </ul>
                    </div>
                    <div fxFlex.lt-sm="100%" fxFlex.gt-sm="50%" fxLayout="column">
                        <ng-template ngFor let-item [ngForOf]="createRange(1)" let-currentIndex="index+12">

                            <div class="brdr-btm">
                                <a class="testvideo" href="https://therightdoctors.com/insights/{{blogdata[currentIndex]['category']}}/{{blogdata[currentIndex]['blog_category']}}/{{blogdata[currentIndex]['slug']}}">
                                    <div class="mrg-after-imgvid">
                                        <picture>
                                            <source type="image/webp" srcset="{{blogdata[currentIndex]['thumbnail_url1']}}">
                                                <img src="{{blogdata[currentIndex]['image_url']}}" alt="{{blogdata[currentIndex]['headline']}}" style="width:100%" />
                                            </picture>
                                    </div>
                                    <p class="internal-updates-head">{{blogdata[currentIndex]['headline'] }}</p>
                                </a>
                               
                            </div>
                        </ng-template>
                        <ng-template ngFor let-item [ngForOf]="createRange(1)" let-currentIndex="index+13">

                            <div class="brdr-btm">
                                <a class="testvideo" href="https://therightdoctors.com/insights/{{blogdata[currentIndex]['category']}}/{{blogdata[currentIndex]['blog_category']}}/{{blogdata[currentIndex]['slug']}}">
                                    <div class="mrg-after-imgvid">
                                        <picture>
                                            <source type="image/webp" srcset="{{blogdata[currentIndex]['thumbnail_url1']}}">
                                                <img src="{{blogdata[currentIndex]['image_url']}}" alt="{{blogdata[currentIndex]['headline']}}" style="width:100%" />
                                            </picture>
                                    </div>
                                <p class="internal-updates-head">{{blogdata[currentIndex]['headline'] }}</p>
                            </a>

                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <a class="testvideo" href="https://therightdoctors.com/insights/"> <button mat-flat-button class="view-all-btn">View all</button></a>
    </div>
</div>
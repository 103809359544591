import * as i0 from "./new-survey.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./new-survey.component";
import * as i3 from "@angular/router";
import * as i4 from "../article.service";
var styles_NewSurveyComponent = [i0.styles];
var RenderType_NewSurveyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewSurveyComponent, data: {} });
export { RenderType_NewSurveyComponent as RenderType_NewSurveyComponent };
export function View_NewSurveyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["adsdasdadada\nadsdasdadadaadasdasdad\nadsdasdadadaadasdasdaddadasdasd\nddsadadadasdaad\nddsadadadasdaadadasdad ddsadadadasdaadada"]))], null, null); }
export function View_NewSurveyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-new-survey", [], null, null, null, View_NewSurveyComponent_0, RenderType_NewSurveyComponent)), i1.ɵdid(1, 114688, null, 0, i2.NewSurveyComponent, [i3.Router, i3.ActivatedRoute, i4.ArticleService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewSurveyComponentNgFactory = i1.ɵccf("app-new-survey", i2.NewSurveyComponent, View_NewSurveyComponent_Host_0, {}, {}, []);
export { NewSurveyComponentNgFactory as NewSurveyComponentNgFactory };

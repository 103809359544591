<!--<app-nav></app-nav>-->
<!-- <img src="https://storage.googleapis.com/web-assets/social-media-icons/Download%20button.png" > -->
<!-- 
<div class = "container">
  <div fxFlex.lt-sm="100%" fxFlex.lt-md="100%" fxFlex.gt-sm="25%">
    <h4>{{heading}}</h4>
    <h6>{{drname}}</h6>
    <button (click)="downloadImage()" type="button">Download PDF</button>
  </div>


  <div fxFlex.lt-sm="100%" fxFlex.lt-md="100%" fxFlex.gt-sm="75%">
          <div class="background-know">

            <div class="container" >
                  <div fxLayout="row" fxLayout.lt-md="column" >
                    <div fxFlex="10">
                    </div>
                    <div fxFlex="80" class="page-content-sliders">
                      <pdf-viewer [src]="pdfSrc" 
                      [render-text]="true"
                      style="display: block;"></pdf-viewer>

                      </div>
                     
                </div>
          </div>
    </div>
</div>

</div>
 -->
<!-- <div *ngIf="loading">
 <div style="margin:200px auto; width:200px; text-align: center" >
  <i class="fa fa-spinner fa-spin" style="font-size:35px;color:#3f51b5;"></i>
  <h2 style="color:#3f51b5">Pranay</h2>
</div>
</div>-->
 <!--<div class="margtop">
  <div class="container" style="margin-top:0px;">
  <div class="content-event-inner the-interview" >
          <div fxLayout="row" fxLayoutGap="25px" fxLayout.lt-md="column">
                      <div fxFlex="100">-->
                        <!--<button type="button">Download PDF</button> -->
                          <!--<h5><div class="sty-intd" > <p style="text-align:center !important;     font-family: 'Open Sans',sans-serif!important;     font-weight: 700;" class="headnew"> {{heading }} </p> </div></h5>
                                      <div fxLayout.lt-sm="column" fxLayout.lt-ms="column" fxLayout.gt-sm="row">
                                                          <div fxFlex.lt-sm="100%" fxFlex.lt-md="100%" fxFlex.gt-sm="50%">
                                                             <div> <h6 class="tv-drname float-heading" style="text-align:center !important;padding-bottom: 10px;">{{drname}}</h6>       
                                                            </div>
                                                          </div>
                                      </div>-->
                                    <div class="col-md-2"></div>
                                      <div class="col-md-8">
                                       <div class="transbox">
                                      <p class="para"><b>Dear Dr.</b></p>
                                      <p class="para">It gives us great pleasure,  in sharing daily updates from <b>ada 2021</b>.</p>
                                        
                                      <p class="para">Corona Remedies, has taken its foray through various scientific
                                        platforms and has always been on the forefront for scientific updates and
                                        education.</p>
                                        
                                        <p class="para">Warm regards,</p>
                                        </div>
                                        <br>
                                      <div  fxLayout="column">
                                          <div class="text-center">
                                            
                                            <div fxFlex="100" style="max-width: 100% !important;" class="page-content-sliders">
                                              <div class="center">
                                              <div *ngIf="loading">
                                                <div style="margin:200px auto; width:200px; text-align: center" >
                                                 <i class="fa fa-spinner fa-spin" style="font-size:35px;color:#3f51b5;"></i>
                                                 <!-- <h2 style="color:#3f51b5;"><img style="width:50px" src="assets/dashboard_assets/new/images/dpm_patient.png" class="fa-spin"><br>Loading</h2> -->
                                                 <h2 style="color:#3f51b5">Loading PDF</h2>
                                               </div>
                                               </div>
                                              <pdf-viewer [src]="pdfSrc" 
                                              [render-text]="false"
                                              [show-all]="false"
                                              [page]="page" 
                                              [original-size]="false" [fit-to-page]="true"
                                              (after-load-complete)="afterLoadComplete($event)"
                                              style="display: block;"></pdf-viewer>
                                              </div>
                                              <br>
                                              <br>
                                              <button style="margin-left:2px; margin-right:2px"(click)="previousPage()" [disabled]="page === 1">Prev</button>

                                              <button style="margin-left:2px; margin-right:2px"(click)="nextPage()" [disabled]="totalPages === 1">Next</button>
                                              <br>
                                              <br>
                                              <br>
                                              <!-- <pdf-viewer
                                              [src]='pdfSrc'
                                              [original-size]="true"
                                              [render-text]='false'
                                              [show-all]="false"
                                              style="display: block; height: 400px;"
                                              [fit-to-page]="true">
                                           </pdf-viewer> -->
                                              </div>
                                          </div>
                                      </div>  
                                    </div>
                                      <div class="col-md-2"></div>
                                      <!--</div>
                                
     </div>
  </div>
  </div>
  </div>-->
  <br>
  <br>
  
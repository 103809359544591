import { Injectable } from '@angular/core';
import {AngularFireDatabase, AngularFireList} from "angularfire2/database";
import { Live_Details} from "./live_details";
import * as firebase from 'firebase';
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';
//import {FirebaseListObservable, FirebaseObjectObservable} from "angularfire2/database";
//import { AngularFireDatabase } from 'angularfire2/database';
import {Observable} from "rxjs/Observable";
import { environment } from '../environments/environment';
import { AngularFireModule } from 'angularfire2';
import { Live_Top_Details } from './live_top_details';
import { unique } from 'jquery';


@Injectable()
export class FirebaseServiceService {   
    live_details: AngularFireList<Live_Details[]>;
    public now: Date = new Date();
    ld: any;
    uid: any;

    private dbPath = '/live-updates';
    private dbPath2 = '/live-updates2';
    private dbPath3 = '/live-updates3';
    private dbPath4 = '/live-updates4';


    private dbPath_for_iages = '/iages-live-updates';
    private dbPath_Main = '/live-updates-main'
    private dbPath_top = '/live-updates-top';
    private dbPath_homepage_video = '/home-page-videos'

    customersRef: AngularFireList<Live_Details> = null;
    customersRef2: AngularFireList<Live_Details> = null;
    customersRef3: AngularFireList<Live_Details> = null;
    customersRef4: AngularFireList<String> = null;


    IagescustomerRef : AngularFireList<Live_Details> = null ; 
    customersMainRef: AngularFireList<Live_Details> = null;
     customersReftop: AngularFireList<Live_Top_Details> = null
     customerVideoRef : AngularFireList<any> = null ; 


    constructor(private db: AngularFireDatabase) {
        AngularFireModule.initializeApp(environment.firebase)
        this.customersRef = db.list(this.dbPath);
        this.customersRef2 = db.list(this.dbPath2);
        this.customersRef3 = db.list(this.dbPath3);
        this.customersRef4 = db.list(this.dbPath4);

        this.IagescustomerRef = db.list(this.dbPath_for_iages); 
        this.customersMainRef = db.list(this.dbPath_Main);
         this.customersReftop = db.list(this.dbPath_top);
         this.customerVideoRef = db.list(this.dbPath_homepage_video)

    }

 createCustomerTop(customer: Live_Top_Details): void {
        this.customersReftop.push(customer);
    }
    createCustomer(customer: Live_Details): void {
        this.customersRef.push(customer);
    }
    createCustomer2(customer: Live_Details): void {
        alert("cardio")
        this.customersRef2.push(customer);
    }
    createCustomer3(customer: Live_Details): void {
        alert("diab")
        this.customersRef3.push(customer);
    }

    // createCustomer4(customer: String, customer2:Live_Details): void {
    //     this.db.list('/' + customer).push(customer2);

    // }

    createCustomer4(customer){
        return this.customersRef4.push(customer).key;
        // this.db.list('/live-updates4/-MkM4yRvB1y2OinjD8fV/new').push(customer);

    }

    createCustomer5(customer: Live_Details){
        // console.log("customerrrrrrrrr",customer);
        this.db.list('/live-updates4/' + customer['f_category'] + '/category').push(customer);

    }


    IagescreateCustomer(customer: Live_Details): void {
        this.IagescustomerRef.push(customer);
    }
    createMainCustomer(customer: Live_Details): void {
        this.customersMainRef.push(customer);
    }

    createVideoCustomer(customer: any) : void {
        this.customerVideoRef.push(customer);
    }

    onGetVideoDocuments() {
        return this.db.list('/home-page-videos').snapshotChanges()
                .map((changes) => {
                    return changes.map((data) => {
                        return data;
                    });
                })
                // .switchMap((usersId: string[]) => {
                //     return Observable.combineLatest(usersId.map((u) => {
                //         return this.onGetUserDocuments(u);
                //     }))
                // })
    }
    onGetUserDocuments(customer: string) {
        return this.db.list('/live-updates').snapshotChanges()
                .map((changes) => {
                    return changes.map((data) => {
                        // console.log(data);
                        return data;
                    });
                })
                // .switchMap((usersId: string[]) => {
                //     return Observable.combineLatest(usersId.map((u) => {
                //         return this.onGetUserDocuments(u);
                //     }))
                // })
    }

    onGetUserDocuments2(customer: string) {
        return this.db.list('/live-updates2').snapshotChanges()
                .map((changes) => {
                    return changes.map((data) => {
                        // console.log(data);
                        return data;
                    });
                })
                // .switchMap((usersId: string[]) => {
                //     return Observable.combineLatest(usersId.map((u) => {
                //         return this.onGetUserDocuments(u);
                //     }))
                // })
    }

    onGetUserDocuments3(customer: string) {
        return this.db.list('/live-updates3').snapshotChanges()
                .map((changes) => {
                    return changes.map((data) => {
                        // console.log(data);
                        return data;
                    });
                })
                // .switchMap((usersId: string[]) => {
                //     return Observable.combineLatest(usersId.map((u) => {
                //         return this.onGetUserDocuments(u);
                //     }))
                // })
    }


    onGetUsersDocumentsMain() {
        return this.db.list('/live-updates-main').valueChanges()
            .map((changes) => {
                return changes.map((data) => {
                    // console.log(data);
                    return data;
                   
                });
            })
            // .switchMap((usersId: string[]) => {
            //     return Observable.combineLatest(usersId.map((u) => {
            //         return this.onGetUserDocuments(u);
            //     }))
            // })
            
    }

    onGetUsersDocuments() {
        return this.db.list('/live-updates').valueChanges()
            .map((changes) => {
                return changes.map((data) => {
                    // console.log(data);
                    return data;
                });
            })
            // .switchMap((usersId: string[]) => {
            //     return Observable.combineLatest(usersId.map((u) => {
            //         return this.onGetUserDocuments(u);
            //     }))
            // })
            
    }

    onGetUsersDocuments2() {
        return this.db.list('/live-updates2').valueChanges()
            .map((changes) => {
                return changes.map((data) => {
                    // console.log(data);
                    return data;
                });
            })
            // .switchMap((usersId: string[]) => {
            //     return Observable.combineLatest(usersId.map((u) => {
            //         return this.onGetUserDocuments(u);
            //     }))
            // })
            
    }
    onGetUsersDocuments3() {
        return this.db.list('/live-updates3').valueChanges()
            .map((changes) => {
                return changes.map((data) => {
                    // console.log(data);
                    return data;
                });
            })
            // .switchMap((usersId: string[]) => {
            //     return Observable.combineLatest(usersId.map((u) => {
            //         return this.onGetUserDocuments(u);
            //     }))
            // })
            
    }

    onGetUsersDocuments4(key) {
        
        return this.db.list('/live-updates4/' + key + '/category').valueChanges()
            .map((changes) => {
                return changes.map((data) => {
                    // console.log(data);
                    return data;
                });
            })
            // .switchMap((usersId: string[]) => {
            //     return Observable.combineLatest(usersId.map((u) => {
            //         return this.onGetUserDocuments(u);
            //     }))
            // })
            
    }


    onGetUsersDocuments_for_Iages() {
        return this.db.list('/iages-live-updates').valueChanges()
            .map((changes) => {
                return changes.map((data) => {
                    return data;
                });
            })
            // .switchMap((usersId: string[]) => {
            //     return Observable.combineLatest(usersId.map((u) => {
            //         return this.onGetUserDocuments(u);
            //     }))
            // })
            
    }
     

 //  getalldocument(){
   // var database = firebase.database().ref().child('users');
    //database.on('value', function (snapshot) {
      //  if (snapshot.exists()) {
        //    var content = '';
          //  snapshot.forEach(function (data) {
            //   var unique= data.key;
           // });
           // return unique;
       // }
    //});}
    
    getalluser(){
        var database = firebase.database().ref('/live-updates')
        database.once('value', (snapshot) => {
            snapshot.forEach((childSnapshot) => {
              var childKey = childSnapshot.key;
              var childData = childSnapshot.val();
              // ...
            //  console.log(childKey,childData.f_title);
            return childData;
            });
          });
    }
    Deleteuser(id){
        var ref = firebase.database().ref().child('live-updates/' + id)
        ref.once("value")
        .then(function(snapshot) {
           snapshot.ref.remove();
           alert("user deleted..!");
       }).catch(function(error) {alert("Data could not be deleted." + error);});
       // firebase.database().ref().child(id).remove();
    }
   
    Edituser(u_id,f_title,f_subheadline,f_description,f_video_subheadline){
        
        var updates = {};
        updates[ '/' +'f_title'] = f_title;
        updates[ '/' + 'f_subheadline'] = f_subheadline;
        updates[ '/' + 'f_description'] = f_description;
        updates['/' + 'f_video_subheadline'] = f_video_subheadline;
        console.log(updates);
       // firebase.database().ref().child(u_id).update(updates);
    }
}


 // onGetUsersDocuments() {
    //     return this.db.list('/live-updates').snapshotChanges()
    //         .map((changes) => {
    //             return changes.map((data) => {
    //                 return data.payload.key
    //             });
    //         })
    //         .switchMap((usersId: string[]) => {
    //             return Observable.combineLatest(usersId.map((u) => {
    //                 return this.onGetUserDocuments(u);
    //             }))
    //         })
            
    // }
    // updates['/live-details/' + u_id + '/' + 'f_title'] = f_title;
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserServiceService } from '../user-service.service';

@Component({
  selector: 'app-send-views-mailer-to-doctors',
  templateUrl: './send-views-mailer-to-doctors.component.html',
  styleUrls: ['./send-views-mailer-to-doctors.component.css']
})
export class SendViewsMailerToDoctorsComponent implements OnInit {
  buttonDisabled = false;
  constructor(private userService: UserServiceService,
    private router: ActivatedRoute, private route: Router) { }
  model: any;
  model1: any;
  model2: any;
  model3: any;
  ntrd: any;
  event: any;
  id_of_event:any;
  isNonTrade: boolean = false
  checkAllNonTrades: boolean = false
  newsletter_list : any = [];
  newsletter_list1 : any = [];
  newsletter_list2 : any = [];
  newsletter_list3 : any = [];
  updated_newsletter_list : any = [];
  email_list : any;
  tok_list:any;
  ngOnInit() {
   // alert("In end pharma page")
    // this.router.params.subscribe(params => {
    //   //   console.log('this is llovwee,',params);
    //      this.id_of_event = params['id'];
    // //     alert("All Docs"+this.id_of_event);
    //    //  localStorage.setItem('Mail_slug', this.all_doc);
    //    });
    // this.getData();
    // setInterval(()=>{
    //   // console.log('LIST 1' ,)
    //   console.log('LST 2' , this.newsletter_list);
    // },1000);
  }
  public removeItem(item: any, list: any[]): void {
    list.splice(list.indexOf(item), 1);
  }
  // drop(event: CdkDragDrop<string[]>) {
  //   if (event.previousContainer === event.container) {
  //     moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  //   } else {
  //     transferArrayItem(event.previousContainer.data,
  //                       event.container.data,
  //                       event.previousIndex,
  //                       event.currentIndex);
  //   }
  // }

  add_list() {
    // var em_list = this.email_list.replace( /\n/g,',').split(",");
    // em_list = {...em_list};
    // console.log(em_list);
    // this.send_list(em_list).subscribe(
    //   data => {
    //     console.log(data);
    //     this.tok_list = data['result'];
    //     console.log(this.tok_list);
    //   }
    // );
  }
getdatafeature(){
  this.userService.getData_articles_forViews_Feature().subscribe(
    data => {
        // console.log(JSON.stringify(data));
        
        this.model = data['data'];
        // alert(data);
        // alert(data.length);
        // alert(JSON.stringify(data));
        //console.log(JSON.stringify(data));
        this.model.sort(function(a, b) {
          a = new Date(a.created_at);
          b = new Date(b.created_at);
          return a>b ? -1 : a<b ? 1 : 0;
      });
      console.log("Show Data ::: " + this.model);
      for(let i = 0 ; i < 1 ; i++){
        this.newsletter_list.push(this.model[i]);
      }
      // this.model.splice(0,10);
    }
  );
}

getdatacolumn(){
  document.getElementById("newsletters").innerHTML = "";


  this.userService.getData_articles_forViews_Column().subscribe(
    data => {
        // console.log(JSON.stringify(data));
        
        this.model1 = data['data'];
        // alert(data);
        // alert(data.length);
        // alert(JSON.stringify(data));
        //console.log(JSON.stringify(data));
        this.model1.sort(function(a, b) {
          a = new Date(a.created_at);
          b = new Date(b.created_at);
          return a>b ? -1 : a<b ? 1 : 0;
      });
      console.log("Show Data ::: " + this.model1);
      for(let i = 0 ; i < 1 ; i++){
        this.newsletter_list1.push(this.model1[i]);
      }
      // this.model1.splice(0,10);
    }
  );
}


getdataguidelines(){
  this.userService.getData_articles_forViews_guidelines().subscribe(
    data => {
        // console.log(JSON.stringify(data));
        
        this.model2 = data['data'];
        // alert(data);
        // alert(data.length);
        // alert(JSON.stringify(data));
        //console.log(JSON.stringify(data));
        this.model2.sort(function(a, b) {
          a = new Date(a.created_at);
          b = new Date(b.created_at);
          return a>b ? -1 : a<b ? 1 : 0;
      });
      console.log("Show Data ::: " + this.model2);
      for(let i = 0 ; i < 10 ; i++){
        this.newsletter_list2.push(this.model2[i]);
      }
      this.model2.splice(0,10);
    }
    
  );
}




getdatasurvey(){
  this.userService.getData_articles_forViews_survey().subscribe(
    data => {
        // console.log(JSON.stringify(data));
        
        this.model3 = data['data'];
        // alert(data);
        // alert(data.length);
        // alert(JSON.stringify(data));
        //console.log(JSON.stringify(data));
        this.model3.sort(function(a, b) {
          a = new Date(a.created_at);
          b = new Date(b.created_at);
          return a>b ? -1 : a<b ? 1 : 0;
      });
      console.log("Show Data ::: " + this.model3);
      for(let i = 0 ; i < 10 ; i++){
        this.newsletter_list3.push(this.model3[i]);
      }
      this.model3.splice(0,10);
    }
  );
}



    getData() {
    this.userService.getData_articles_forViews().subscribe(
      data => {
        // console.log(JSON.stringify(data));
        
        this.model = data['data'];
        // alert(data);
        // alert(data.length);
        // alert(JSON.stringify(data));
        //console.log(JSON.stringify(data));
        this.model.sort(function(a, b) {
          a = new Date(a.created_at);
          b = new Date(b.created_at);
          return a>b ? -1 : a<b ? 1 : 0;
      });
      console.log("Show Data ::: " + this.model);
      for(let i = 0 ; i < 10 ; i++){
        this.newsletter_list.push(this.model[i]);
      }
      this.model.splice(0,10);
      },
      error => {
        console.log(error);
      });
  }
  changeTradesByCategory(event) {
    if (event.target.name == 'nontrades') {
      this.isNonTrade = true
    }

    if (this.isNonTrade && this.checkAllNonTrades) {
      event.target.checked = true
    }
  }
  send() {
    var em_list = this.email_list.replace( /\n/g,',').split(",");
    em_list = {...em_list};
    console.log(em_list);

    this.newsletter_list.push(em_list);
    console.log("EditNewsletterMailerComponent -> send -> this.newsletter_list", this.newsletter_list)
    // console.log("EditNewsletterMailerComponent -> send -> this.newsletter_list", this.newsletter_list['em_list'])
    
    this.userService.send_email_views_feature(this.newsletter_list).subscribe(
      data => {
      console.log("EditNewsletterMailerComponent -> send -> data", data)
        // this.tok_list = data['result'];
      }
    );
    // this.userService.send_email_newsletter(this.newsletter_list).subscribe(
    //   data => {
    //     if (data) {
    //       console.log("DATA IN TS FILE :: " + data);
    //     }
    //   }
    // )
  //  alert("Mail Sent ");
 //  window.location.reload();
  }

  SelectAll(event) {
    const checked = event.target.checked;
    this.model.forEach(item => item.selected = checked);
  }
}


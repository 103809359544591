import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { UserServiceService } from './user-service.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "./user-service.service";
var LoginService = (function () {
    function LoginService(http, route, router, userService) {
        this.http = http;
        this.route = route;
        this.router = router;
        this.userService = userService;
        this.situation = false;
        this.switch_to_register = false;
        this.response = {};
        this.token = localStorage.getItem('token');
        this.user = {
            'name': '',
            'email': '',
            'amount': null,
            'country': '',
            'mobile': null,
            'period': null,
            'expiry': null
        };
    }
    LoginService.prototype.setName = function (value) {
        localStorage.setItem('name', value);
    };
    LoginService.prototype.setMobile = function (value) {
        localStorage.setItem('mobile', value);
    };
    LoginService.prototype.saveUser = function (user) {
        this.user = user;
        localStorage.setItem('name', user.name);
        localStorage.setItem('email', user.email);
        if (user.mobile != null) {
            localStorage.setItem('mobile', this.user.mobile.toString());
        }
        else {
            localStorage.setItem('mobile', null);
        }
        if (user.amount != null) {
            localStorage.setItem('amount', this.user.amount.toString());
        }
        else {
            localStorage.setItem('amount', null);
        }
        localStorage.setItem('country', user.country);
        localStorage.setItem('expiry', user.expiry);
        localStorage.setItem('period', user.period);
    };
    LoginService.prototype.getUser = function () {
        this.user.name = localStorage.getItem('name');
        this.user.email = localStorage.getItem('email');
        this.user.mobile = +localStorage.getItem('mobile');
        this.user.country = localStorage.getItem('country');
        this.user.amount = +localStorage.getItem('amount');
        this.user.period = localStorage.getItem('period');
        this.user.expiry = localStorage.getItem('expiry');
        return this.user;
    };
    LoginService.prototype.setAmount = function (amount) {
        this.user.amount = amount;
        localStorage.setItem('amount', amount.toString());
    };
    LoginService.prototype.setCountry = function (country) {
        this.user.country = country;
        localStorage.setItem('country', country);
    };
    LoginService.prototype.setPeriod = function (plan) {
        this.user.period = plan;
        localStorage.setItem('period', plan);
    };
    LoginService.prototype.setExpiry = function (date) {
        this.user.expiry = date;
        localStorage.setItem('expiry', date);
    };
    LoginService.prototype.getToken = function () {
        return localStorage.getItem('currentUser');
    };
    LoginService.prototype.onLogout = function () {
        localStorage.removeItem('token');
        localStorage.removeItem('name');
        localStorage.removeItem('email');
        localStorage.removeItem('mobile');
        localStorage.removeItem('amount');
        localStorage.removeItem('country');
        localStorage.removeItem('period');
        localStorage.removeItem('expiry');
        localStorage.removeItem('currentUser');
    };
    LoginService.prototype.offUser = function () {
        this.user.name = '';
        this.user.email = '';
        this.user.amount = null;
        this.user.mobile = null;
        this.user.country = null;
    };
    LoginService.prototype.getSituation = function () {
        return this.situation;
    };
    LoginService.prototype.subscription_login = function (user) {
        var _this = this;
        this.situation = true;
        this.userService.mail_checking_login(user.email, user.password).subscribe(function (responseData) {
            if (responseData['success']) {
                _this.response = responseData['data'];
                if (responseData.message == 'Login Successfull') {
                    console.log(_this.response);
                    _this.user.name = _this.response.firstname;
                    _this.user.email = _this.response.email;
                    _this.user.mobile = _this.response.mobile;
                    _this.user.amount = _this.response.amount;
                    _this.user.country = _this.response.country;
                    console.log("abrakadabra", _this.user);
                    _this.saveUser(_this.user);
                    localStorage.setItem('name', _this.response.firstname);
                    localStorage.setItem('email', _this.response.email);
                    localStorage.setItem('mobile', _this.response.mobile);
                    localStorage.setItem('amount', _this.response.amount);
                    localStorage.setItem('country', _this.response.country);
                    localStorage.setItem('period', _this.response.period);
                    localStorage.setItem('expiry', _this.response.expiry);
                    localStorage.setItem('pay_id', _this.response.pay_id);
                    localStorage.setItem('token', _this.response.token);
                    localStorage.setItem('currentUser', JSON.stringify(_this.response));
                    _this.situation = true;
                    setTimeout(function () { return _this.situation = false; }, 6000);
                    alert('Welcome ' + _this.response.firstname);
                    setTimeout(function () { return _this.router.navigate(['/subscription-plan-page']); }, 1000);
                    _this.successful = true;
                    return true;
                }
                else {
                    _this.situation = false;
                    _this.notReg = true;
                    setTimeout(function () { return _this.notReg = false; }, 1000);
                    alert('Email or Password incorrect');
                    return false;
                }
            }
            else {
                alert(responseData.message);
                setTimeout(function () { return _this.notReg = false; }, 4000);
                return false;
            }
        });
    };
    LoginService.prototype.login = function (user) {
        var _this = this;
        this.situation = true;
        console.log(user.email);
        this.http.post('https://therightdoctors.com/api/beta/contribution/getUser?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg', user).subscribe(function (responseData) {
            _this.response = responseData;
            console.log(_this.response.message);
            if (_this.response.message == 'Logged in Successfully') {
                console.log(_this.response);
                _this.user.name = _this.response.user;
                _this.user.email = _this.response.email;
                _this.user.mobile = _this.response.mobile;
                _this.user.amount = _this.response.amount;
                _this.user.country = _this.response.country;
                console.log("localstoragetoken", localStorage.getItem('token'));
                _this.setToken(_this.response.token);
                _this.saveUser(_this.user);
                localStorage.setItem('name', _this.response.user);
                localStorage.setItem('email', _this.response.email);
                localStorage.setItem('mobile', _this.response.mobile);
                localStorage.setItem('amount', _this.response.amount);
                localStorage.setItem('country', _this.response.country);
                localStorage.setItem('period', _this.response.period);
                localStorage.setItem('expiry', _this.response.expiry);
                localStorage.setItem('pay_id', _this.response.pay_id);
                localStorage.setItem('token', _this.response.token);
                console.log(localStorage.getItem('email'));
                _this.situation = true;
                localStorage.setItem('logToken', _this.response.token);
                setTimeout(function () { return _this.situation = false; }, 1000);
                alert('Welcome ' + _this.response.user);
                setTimeout(function () { return _this.router.navigate(['/plan']); }, 1000);
                _this.successful = true;
                return true;
            }
            else {
                _this.situation = false;
                _this.notReg = true;
                setTimeout(function () { return _this.notReg = false; }, 6000);
                alert('Email or Password incorrect');
                return false;
            }
        }, function (err) {
            setTimeout(function () { return _this.notReg = false; }, 4000);
            return false;
        });
    };
    LoginService.prototype.setToken = function (token) {
        console.log(token);
        localStorage.setItem('token', token);
    };
    LoginService.ngInjectableDef = i0.defineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.inject(i1.HttpClient), i0.inject(i2.ActivatedRoute), i0.inject(i2.Router), i0.inject(i3.UserServiceService)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };

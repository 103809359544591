<body>
  <div class="bg">
    <div class="layer">
      <div class="container">
        <div fxLayout="row" fxLayout.lt-md="column">
          <div fxFlex="30"></div>
          <div fxFlex="40" class="pad-top-btm">
            <mat-card style="padding:10px;">
              <div align="center">
              <img src="/assets/trd-logo.png" style="margin-bottom:10px;"/></div>
              <h4 class="login">Update Password</h4>

              <form id="form" class="example-form" name="form" (ngSubmit)="sendPwd()" novalidate
              #f="ngForm">
                <p>
                  <mat-form-field class="example-full-width">

                    <input matInput placeholder="Email" name="email" [(ngModel)]="email1" #email="ngModel"  type="email" style="box-shadow:none;border:0;background:transparent">
                  </mat-form-field>
                </p>
                
                <div fxLayout="row" style="margin-top:10px;">
                  <button mat-flat-button color="primary" >Submit</button>
</div>
                
              </form>
            </mat-card>
          </div>
          <div fxFlex="30">
          </div>
        </div>
      </div>
    </div>
  </div>
</body>

<!--<app-nav></app-nav>-->
<!-- <img src="https://storage.googleapis.com/web-assets/social-media-icons/Download%20button.png" > -->

<div class = "container">
        <!-- <div fxFlex.lt-sm="100%" fxFlex.lt-md="100%" fxFlex.gt-sm="25%">

          <button (click)="downloadImage()" type="button">Download PDF</button>
        </div> -->


        <div fxFlex.lt-sm="100%" fxFlex.lt-md="100%" fxFlex.gt-sm="75%">
                <div class="background-know">

                  <div class="container" >
                        <div fxLayout="row" fxLayout.lt-md="column" >
                          <div fxFlex="10">
                          </div>
                          <div fxFlex="80" class="page-content-sliders">
                            <pdf-viewer [src]="pdfSrc" 
                            [render-text]="true"
                            style="display: block;"></pdf-viewer>

                            </div>
                            <!-- <div fxFlex="10">
                            </div> -->
                      </div>
                </div>
          </div>
      </div>

</div>
<!--<app-footer></app-footer>-->

<div *ngFor=" let ids of dataid;let i = index ">

     
      
        
       
  <!--  <div *ngIf="id == ids ">
       <p><strong> f_title </strong> :</p>
 <div>  {{d['f_title']}} </div>
 <br>
 <p><strong>Story Description</strong>:</p>
 <div [innerHtml]="d['f_description']">  </div>
</div>--> 
<div *ngIf="id == ids ">
 <p><strong> f_title </strong> :</p>
 <div> {{datatitle[i]['f_title']}}</div>
 <br>
 <p><strong> Story Sub headline: </strong> :</p>
 <div> {{datatitle[i]['f_subheadline']}}</div>
 <br>
 <p><strong>Story Description</strong>:</p>
 <div [innerHtml]="datatitle[i]['f_description']">  </div>

  <br>
  <p><strong>Story Video Headline</strong>:</p>
  <div [innerHtml]="datatitle[i]['f_video_subheadline']">  </div>
 </div> 




</div>

<br>
<br>

<div><strong>FORM TO MAKE CHANGES</strong></div>

<br>

  
<div>


<label  for="f_title">Story Heading/Title</label>
<input #f_title type='text' placeholder='f_title'>
</div>
<div>
<label  for="f_subheadline">Story Sub headline:</label>
<input #f_subheadline type='text' placeholder='f_subheadline'>
</div>
<div>
<label  for="f_description">Story Description</label>
<input #f_description type='text' placeholder='f_description'>
</div>
<div>

<label  for="f_video_subheadline">Story Video Headline </label>
<input #f_video_subheadline type='text' placeholder='f_video_subheadline'>
</div>
<br>
<button (click)=edituser(f_title.value,f_subheadline.value,f_description.value,f_video_subheadline.value)>SUBMIT</button>



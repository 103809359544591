<!-- <div class="example-container">
  <h2>To do</h2>

  <div
    cdkDropList
    #todoList="cdkDropList"
    [cdkDropListData]="todo"
    [cdkDropListConnectedTo]="[doneList]"
    class="example-list"
    (cdkDropListDropped)="drop($event)">
    <div class="example-box" *ngFor="let item of todo" cdkDrag>{{item}}</div>
  </div>
</div>

<div class="example-container">
  <h2>Done</h2>

  <div
    cdkDropList
    #doneList="cdkDropList"
    [cdkDropListData]="done"
    [cdkDropListConnectedTo]="[todoList]"
    class="example-list"
    (cdkDropListDropped)="drop($event)">
    <div class="example-box" *ngFor="let item of done" cdkDrag>{{item}}</div>
  </div>
</div> -->
<app-nav></app-nav>
<div class="container">
  <div class="dndPlaceholder col-md-12"
    #placeholder></div>
  <div class="page-header">
    <h1>Edit Newsletter </h1>
    <p class="lead">Drag and drop </p>
    <div style="text-align: right;">
      <!-- <button (click)="send()" >Send</button> -->
   </div>

   <form name="form" (ngSubmit) = "send()" id="loginform" novalidate #f = "ngForm">
    <mat-form-field style="width:100%">
    <input matInput type="email_list" name="email_list" placeholder="Enter the email id of the doctor" [(ngModel)]="this.email_list" #email="ngModel" required autofocus id="uemail" required>
    </mat-form-field>
        <div fxLayout="row">
            <div fxLayout="column" fxFlex="100" fxLayoutAlign="center end">
                <button mat-raised-button type="submit" id="submit" color="primary">Submit</button>
                    <!-- <i class="fa fa-spinner fa-spin"  *ngIf="load"></i> -->
            </div>
          </div>
        </form>

  </div>
<div class="row">
  <button mat-raised-button (click)="getdatafeature()" color="primary">Feature</button>
  <!-- <button mat-raised-button (click)="getdatacolumn()" color="primary">Column</button>
  <button mat-raised-button (click)="getdataguidelines()" color="primary">Guidelines</button>
  <button mat-raised-button (click)="getdatasurvey()" color="primary">Survey</button> -->
</div>
  
  
  
  
  <div class="row" id="newsletters">
    <div class="col-md-6"><h3>Metrics Article of Doctor</h3> </div>
    <div class="col-md-6"><h3>List of Articles</h3> </div>
    <div class="col-md-6"
      [dndList]
      [dndPlaceholder]="placeholder"
      [dndModel]="newsletter_list">
      <div *ngFor="let item of newsletter_list"
        class="col-md-12"
        [dndType]="'item'"
        (dndMoved)="removeItem(item, newsletter_list)"
        [dndDraggable]
        [dndObject]="item">{{item.headline}}<br>{{item.sub_headline}}</div>
    </div>
    
    <div class="col-md-6"
      [dndList]
      [dndModel]="model"
      [dndPlaceholder]="placeholder">
      <div *ngFor="let item of model"
        class="col-md-12"
        [dndType]="'item'"
        (dndMoved)="removeItem(item, model)"
        [dndDraggable]
        [dndObject]="item">{{item.headline}} <br>{{item.sub_headline}}</div>
    </div>
  </div>
  <!-- <div class="row" id="newsletters1">
    <div class="col-md-6"><h3>Newsletter 1</h3> </div>
    <div class="col-md-6"><h3>List of Articles</h3> </div>
    <div class="col-md-6"
      [dndList]
      [dndPlaceholder]="placeholder"
      [dndModel]="newsletter_list1">
      <div *ngFor="let item1 of newsletter_list1"
        class="col-md-12"
        [dndType]="'item1'"
        (dndMoved)="removeItem(item1, newsletter_list1)"
        [dndDraggable]
        [dndObject]="item1">{{item1.headline}}<br>{{item1.sub_headline}}</div>
    </div>
    
    <div class="col-md-6"
      [dndList]
      [dndModel]="model1"
      [dndPlaceholder]="placeholder">
      <div *ngFor="let item1 of model1"
        class="col-md-12"
        [dndType]="'item1'"
        (dndMoved)="removeItem(item1, model1)"
        [dndDraggable]
        [dndObject]="item1">{{item1.headline}} <br>{{item1.sub_headline}}</div>
    </div>
  </div> -->


</div>


import {map} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Http, Response } from '@angular/http';

@Component({
  selector: 'app-new-screen-four',
  templateUrl: './new-screen-four.component.html',
  styleUrls: ['./new-screen-four.component.css']
})
export class NewScreenFourComponent implements OnInit {
  sources: Array < Object > ;
	articles: any;
	inc: number = 0;
	blogdata_c: any;

	audio_list1: object[] = [];
	video_list: any = [];
	conv_image: any;
	imagesSrcs = [];
	audio_list5: object[] = [];

	routerLinks = [];
	catagory: any;
	// live_details: AngularFireList<Live_Details[]>;
	public now: Date = new Date();
	ld: any;
	conv: any;
	finaldata: object[] = [];
	finaldata_other: object[] = [];
	finaldata_oye: object[] = [];
	data_we_need: object[] = [];
	data_final: object[] = [];
	lastdata: object[] = [];
	d: any;
	h1: string;
	h2: string;
	h3: string;
	finaldata_all: object[] = [];
	//private dbPath = '/live_updates';
	isplay: boolean = true;
	isplay2: boolean = true;
	url: any;
	blogdata: any;
	blogdata_corna: any;
	corona_v: any;
	finaldata1 = [];
	cat = [];
	event: string;
	finaldata2 = [];
	finaldata3 = [];
	should_not=[];
	audio_list: any;
	finaldata_two: object[] = [];
	audio_list_two: object[] = [];
	exclusive: any;
	exclusive_story: any;
	main_block2: any;
	block2_category: any;
	block2_data: any;
	url2: string;
	block2_link: string;
	main_block3: any;
	block3_category: any;
	block3_data: any;
	intr: object[] = [];
	url1: string;

	block3_link: string;
	main_block4: any;
	block4_category: any;
	block4_data: any;
	block4_link: string;
	top_stories: any;
	block1_category: any;
	main_block1: any;
	block1_data: any;
	block1_link: string;
	exclusive_paras = [];
  exclusive_story3 : any;
  exclusive_story2 :any;
  exclusive_story0 : any;
  constructor(private http: Http) { }

  ngOnInit() {
	this.exclusiveStory();
	this.get_topStories();
	
	this.BlogData();
  }

  get_topStories() {
	this.url = 'https://therightdoctors.com/api/beta/article?is_top_story=true&event=corona-virus&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4&limit=15'
	this.http.get(this.url)
		.map((res: Response) => res.json()).subscribe(data => {
			if (data['success']) {
				this.top_stories = data['data'];

				this.top_stories.sort(function (a, b) {
					a = new Date(a.created_at);
					b = new Date(b.created_at);
					return a > b ? -1 : a < b ? 1 : 0;
				});
				// console.log('LOL', this.top_stories);

				// this.shuffleArray(this.top_stories);
				for (let i = 0; i < 15; i++) {
					if(! this.should_not.includes(this.top_stories[i]['slug'])){
						this.should_not.push(this.top_stories[i]['slug']);
					}
				}

			}

		})
}



exclusiveStory() {
	this.url = 'https://therightdoctors.com/api/beta/article?category=cme&is_exclusive=true&limit=40&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4'
	// this.url='https://therightdoctors.com/api/beta/article?category=cme&blog_category=column&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4'
	this.http.get(this.url)
		.map((res: Response) => res.json()).subscribe(data => {
			// console.log('IDK', data);
			if (data['success']) {
				this.exclusive = data['data'];
			
				// console.log('excllllll_creatd',this.exclusive);

				this.exclusive.sort(function (a, b) {
					a = new Date(a.created_at);
					b = new Date(b.created_at);
					return a > b ? -1 : a < b ? 1 : 0;
				});
				// console.log('excllllll_updated',this.exclusive);
				this.exclusive_story0 = this.exclusive[0]
				this.exclusive_story2 = this.exclusive[1]
				this.exclusive_story3 = this.exclusive[2] 
				this.should_not.push(this.exclusive[0]['slug'])
				this.should_not.push(this.exclusive[1]['slug']);
				this.should_not.push(this.exclusive[2] ['slug']);
		
			}
		})





	}
  BlogData() {

		// this.event='rssdi-jaipur-2019';
		// this.url = 'https://therightdoctors.com/api/beta/article?key=7xOyNH554tY83cBN7Ktpw3s1y68ql6Eg&category=cme';
    // this.url = 'https://therightdoctors.com/api/beta/article?category=cme&event=rssdi-jaipur-2019&is_live=true&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4'
	// 	console.log('set articles calling');
	// 	this.http.get(this.url).pipe(
	// 		map((res: Response) => res.json())).subscribe(data => {

    //     this.blogdata = data['data'];
	// 			// console.log("DARTATA " , this.blogdata)
	// 			for (var i = 0; i < this.blogdata.length; i++) {
	// 				if (this.blogdata[i]['image_url'] != '' && this.blogdata[i]['headline'] != '' && this.blogdata[i]['sub_headline'] != '' && this.blogdata[i]['transcript'] != '' && this.blogdata[i]['sub_headline'] != 'Dr. AG Unni Krishnan' && this.blogdata[i]['sub_headline'] != 'Dr. Rajeev Chawla' && this.blogdata[i]['category'] == 'cme' && this.blogdata[i]['blog_category'] == 'the-interview')
	// 					this.finaldata.push(this.blogdata[i]);


	// 			}
	// 			for (var i = 0; i < this.blogdata.length; i++) {
	// 				let temp;
	// 				if(this.finaldata[i]){
	// 				temp = this.finaldata[i]['transcript'].split('<b>');
	// 				let tem_st = '';
	// 				for (let j = 0; j < temp.length; ++j) {
	// 					tem_st += temp[j];
	// 				}
					
	// 				this.finaldata[i]['transcript'] = tem_st;}
	// 				// console.log('hi i am final data',this.finaldata[i]['transcript']);

	// 			}

	// 			this.blogdata = this.finaldata;
	// 			// console.log('hi this is blog data',this.blogdata[0]['transcript'])
	// 			// this.blogdata['transcript']= this.blogdata['transcript'].replace('<b>',' ');

	// 			// console.log("FINAKL " , this.blogdata)
	// 			// console.log('this is amusing',this.blogdata.length);
	// 		})
		// this.url1 = 'https://therightdoctors.com/api/beta/article?category=cme&blog_category=feature&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4&limit=20';
		this.url1 = 'https://therightdoctors.com/api/beta/article?category=cme&limit=40&key=6ZzQ52peX5XqUx3t824670wv8jIaf1B4';

		this.http.get(this.url1).pipe(
			map((res: Response) => res.json())).subscribe(data1 => {

				this.blogdata_c = data1['data'];
				// console.log("DARTATA Corona " , this.blogdata_c)
				for (var i = 0; i < this.blogdata_c.length; i++) {
					if (this.blogdata_c[i]['image_url'] != '' &&  this.blogdata_c[i]['headline'] != ''){
						if(! this.should_not.includes(this.blogdata_c[i]['slug'])){
							this.finaldata.push(this.blogdata_c[i]);
						}
						
					}
						
// 
				}


				this.blogdata = this.finaldata;

				// this.blogdata = this.blogdata_c;

				// console.log("FINAKL ", this.blogdata)


this.blogdata.sort(function (a, b) {
	a = new Date(a.created_at);
	b = new Date(b.created_at);
	return a > b ? -1 : a < b ? 1 : 0;
});

				// console.log("FINAKL ", this.blogdata)
				// console.log('this is amusing', this.blogdata.length);
				// alert('hello')
				// console.log('blog data is', this.blogdata)

			})
		// alert('i am blog data')
		// if (this.today_date == this.prev_date+1){   

			// this.blogdata=	  this.shuffleArray(this.blogdata);
		//   console.log('shuffled array', this.blogdata)
		//   this.prev_date=this.prev_date+1;
		//   }
		//   if(this.prev_date==30 && this.month== 4 && this.month== 4 && this.month== 6 && this.month== 9 && this.month== 11){

		//      this.prev_date=1;          
		//   } 

		//   if(this.prev_date==31) {
		//     this.prev_date=1;}
		// } 


		// alert('hello')
  }
  shuffleArray(array) {
	for (var i = array.length - 1; i > 0; i--) {
		var j = Math.floor(Math.random() * (i + 1));
		var temp = array[i];
		array[i] = array[j];
		array[j] = temp;
	}
  }
  	createRange(number) {
		var items: number[] = [];
		for (var i = 1; i <= number; i++) {
			items.push(i);
		}
		return items;
	}

}
import { Component, OnInit } from '@angular/core';
import { UserServiceService } from '../user-service.service';


@Component({
  selector: 'app-register-forgot-password',
  templateUrl: './register-forgot-password.component.html',
  styleUrls: ['./register-forgot-password.component.css']
})


export class RegisterForgotPasswordComponent implements OnInit {
  email1: string = "";
  constructor(private uservice: UserServiceService) { }


  ngOnInit() {
  }


  sendPwd() {
    console.log('In sendPwd in RegisterForgotPasswordComponent','email:'+this.email1);
        this.uservice.sendPwd(this.email1).subscribe(
          data => {
            if(data['success']){
              console.log(data);
              alert('Email sent to mail successfully');
            }
            else{
              console.log(data);
              alert('Mail is not registered');
            }
            
          },
          err=>{
            alert(err.message);
          }
        );
      
    }

}

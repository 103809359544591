<!--footer-->
<footer>
    <div class="pddgbtm">
    <div class="container">
        <div class="row" fxLayoutAlign="start center">
            <div class="col-xs-6 col-sm-6 col-md-6"><h4>TheRightDoctors</h4></div>
            <div class="col-xs-6 col-sm-6 col-md-6" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="end center">
                <h6 class="desk-didp"><a [routerLink]="['/subscription']">Subscribe</a></h6>
                <h6><a [routerLink]="['/plan']">Contribute</a></h6>
            </div>
        </div>
    </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-2 col-sm-6 col-xs-6 mrg_btm_m2">
                <h1>Popular Categories</h1>
                <ul>
                    <li><a [routerLink]="['/coronavirus/live-updates']">Live Updates</a></li>
                    <li><a [routerLink]="['/insights']">Insights</a></li>
                    <li><a [routerLink]="['/podcast']">Podcast</a></li>
                    <li><a [routerLink]="['/tv']">TV</a></li>
                    <li><a [routerLink]="['/images']">Images</a></li>
                    <li><a [routerLink]="['/slides']">Slides</a></li>
                </ul>
            </div> 
            <div class="col-md-2 col-sm-6 col-xs-6 mrg_btm_m2">
                <h1>Segments</h1>
                <ul>
                    
                    <li><a [routerLink]="['/the-interview']">The Interview</a></li>
                    <li><a [routerLink]="['/in-conversation']">In Conversation</a></li>
                    <li><a [routerLink]="['/one-more-thing']">One More Thing</a></li>
                    <li><a [routerLink]="['/the-speakers']">The Speaker</a></li>
                    <!-- <li><a [routerLink]="['/in-conversation']">IN Conversation</a></li> -->
                    <li><a [routerLink]="['/journals']">Journals</a></li>
                </ul>
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12 mrg_btm_m2">
                <h1>Specials</h1>
                <ul>
                    <li><a href="https://aoc.therightdoctors.com/aoc-2020">AOC 2020 Mumbai</a></li>
                    <li><a href="https://rssdi2019.therightdoctors.com">Torrent RSSDI tv</a></li>
                    <li><a href="https://wccmm2019.therightdoctors.com/">WCCMM 2019</a></li>
                    <li><a [routerLink]="['/specials/rssdi-ahmedabad-2018']">RSSDI Ahmedabad 2018</a></li>
                    <li><a [routerLink]="['/specials/dr-ulhas-pandurangi-sixth-live-cardiac-electrophysiology-workshop-madras-medical-mission-2018']">Cardiac Electrophysiology Workshop</a></li>
                    <li><a [routerLink]="['/specials/dr-ulhas-pandurangi-fifteenth-annual-electrocardiology-course-basics-and-beyond-madras-medical-mission-2018']">15th Annual ECG Course</a></li>
                </ul>
            </div>

             
           
            <div class="col-md-4 col-sm-12 col-xs-12 mrg_btm_m2">
                <h1 style="text-align: center;">Get TheRightDoctors email updates</h1>                <!-- <p>Subscribe to receive TheRightDoctors email notifications </p> -->
                <!--whenever new talks are published-->
               
               
               
                <form role="form" #contactForm="ngForm" (submit)="subscribe_email()" class="row" style="padding:5px 0px;margin: 0px;">             
                    <mat-form-field style="display: block;" appearance="fill" class="col-sm-12 col-md-6">
                     <mat-label>Full Name</mat-label>
                    <input  #full_name="ngModel" name="full_name" [(ngModel)]="model.full_name" matInput>
                    </mat-form-field>
                    <mat-form-field style="display: block;" appearance="fill" class="col-sm-12 col-md-6">
                    <mat-label>Email</mat-label>
                    <input [(ngModel)]="model.email" #email="ngModel" name="email" matInput placeholder="Ex. abc@example.com">
                    </mat-form-field>
                    <br>
                    <div class="col-12" style="display: block !important; margin: auto !important;
                    width: 50% !important;" fxLayoutAlign="center center">
                      <button mat-flat-button style=" font-weight:bold;color:#fff;background-color: #B71C1C;border-radius: 4px;">SUBSCRIBE</button>
                    </div>
                </form>



                  
                <h1 style="margin-bottom: 0px;">Follow Us</h1>
                <ul class="list-inline list-icons-top ul-li-mar-top">
                    <li>
                        <a class="" data-service="facebook" title="Follow us on Facebook" href="https://www.facebook.com/therightdoctors">
                            <i class="fa fa-facebook" aria-hidden="true"></i>
                        </a>
                    </li>
                    <li>
                        <a class="" data-service="telegram" title="Follow us on Telegram" href="https://t.me/COVID19Coverage">
                             <i class="fa fa-telegram" aria-hidden="true"></i>
                        </a>
                    </li>
                    <li>
                        <a class="" data-service="twitter" title="Follow us on Twitter" href="https://twitter.com/therightdoctors">
                            <i class="fa fa-twitter" aria-hidden="true"></i></a>
                    </li>
                    <li>
                        <a class="" data-service="linkedin" title="Follow us on Linkedin" href="https://www.linkedin.com/company/therightdoctors-com">
                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                        </a>
                    </li>

                    <li>
                        <a class="" data-service="youtube" title="Follow us by Youtube" href="https://www.youtube.com/channel/UCUZIhLsNbgkCqymAZshSVHQ">
                            <i class="fa fa-youtube" aria-hidden="true" style="font-size:18px;"></i>
                        </a>
                    </li>
                    <li>
                        <a data-service="instagram" target="_blank" rel="noopener" title="Follow us by Instagram" href="https://www.instagram.com/therightdoctors/">
                            <i class="fa fa-instagram" aria-hidden="true"></i>
                        </a>
                    </li>                    
                </ul>

            </div>
        </div>
        <div class="row">
            <div class="post_footer row" style="width:100%;">
                <div class="col-md-6 col-sm-12  col-xs-12 text-left">
                    <ul class="list-inline">
                        <li><a [routerLink]="['/terms-of-service']">Terms of Service</a>&nbsp;|&nbsp;<a [routerLink]="['/privacy-policy']">Privacy Policy</a>&nbsp;|&nbsp;<a [routerLink]="['/disclaimer']">Disclaimer</a></li>
                    </ul>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12" fxLayoutAlign.gt-sm="end">
                    <p> © TheRightDoctors Network. All Rights Reserved</p>
                    <div class="clearfix"></div>
                </div>
                <!-- <div class="col-md-4 col-sm-4  col-xs-12 text-right text_left_m pad-text">
                    TheRightDoctors
                    <div class="clearfix"></div>
                </div> -->
            </div>
        </div>

    </div>
</footer>
<div class="bg-diff">
<section class="container">

    <section class="back2">
        <div class="container" style="padding:0px;">
            <div fxLayout.lt-sm="column" fxLayout.gt-xs="row">
                <div  style="padding:0px" fxFlex.lt-sm="100%" fxFlex.gt-xs="30%" fxLayout.lt-sm="row" fxLayout.gt-xs="column" fxLayoutAlign.lt-sm="start center">
                    <h5 fxFlex.lt-sm="25%" fxFlex.gt-xs="100%" class="head-power" >Powered by:</h5>
                    <!-- <img src="../../assets/img/new-poweredby-google_0139f0ce2a96cd30bb2566658be72556.webp" class="mar-top-d" alt="new-poweredby-google" /> -->
                    <img fxFlex.lt-sm="75%" fxFlex.gt-xs="100%" src="https://storage.googleapis.com/web-assets/footer/google%20cloud%20platform%20(1).png" loading='lazy' class="mar-top-d" alt="new-poweredby-google" />

                </div>

                <div class="marg-btm-m" fxFlex.lt-sm="100%" fxFlex.gt-xs="70%" style="padding:0px">
                    <h5 class="head-power">Awards & Recognitions:</h5>
                    <div class="row img-wid">
                        <div class="col-md-3 col-xs-3 col-sm-3">
                            <img src="assets/img/award1.png" loading='lazy' alt="award" />
                        </div>
                        <div class="col-md-3 col-xs-3 col-sm-3">
                            <img src="assets/img/1000awards.png" loading='lazy' alt="1000 awards" />

                        </div>
                        <div class="col-md-3 col-xs-3 col-sm-3">

                            <img src="assets/img/SBC_global_logo-L-2.png" loading='lazy' style="margin-top:13px" alt="SBC Global Logo" />
                        </div>
                        <div class="col-md-3 col-xs-3 col-sm-3">
                            <img src="assets/img/startup.png" style="margin-top: 13px;"loading='lazy'  alt="Start Up" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>



</section>

</div>



<!-- 
<section class="back3 post_footer_pdg">
    <section class="container">
        <div class="row">
            <div class="post_footer">
                <div class="col-md-6 col-sm-6  col-xs-12 text-left">
                    <ul>
                        <li><a [routerLink]="['/terms-of-service']">Terms of Service</a></li>
                        <li><a [routerLink]="['/privacy-policy']">Privacy Policy</a></li>
                        <li><a [routerLink]="['/disclaimer']">Disclaimer</a></li>
                    </ul>
                    <div class="clearfix"></div>
                </div>
                <div class="col-md-6 col-sm-6  col-xs-12 text-right text_left_m pad-text">
                    © TheRightDoctors Network. All Rights Reserved
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>

    </section>
</section> -->

<!--footer-->
<!--login comment popup-->
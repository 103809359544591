<div class="new-screen6">
    <div class="container" fxLayout="column">
        <h5 class="all-head">Trending Images</h5>
        <div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="20px" class="display-mob">

            <ng-template ngFor let-item [ngForOf]="createRange(1)" let-currentIndex="index">


                <div fxFlex.lt-md="100%" fxFlex.gt-sm="33.33%" class="md-brdr-btm inconv1">

                    <a class="testvideo" [routerLink]="['/'+'key-takeaways'+'/'+conv_image[currentIndex]['slug']+'/'+conv_image[currentIndex]['_id']]">
                        <!-- href="https://therightdoctors.com/images/{{conv_image[currentIndex]['slug']}}"> -->

                        <div class="mrg-after-imgvid">
                            <picture>
                                <source type="image/webp" srcset="{{conv_image[currentIndex]['thumbnail_url']}}">
                                <img src="{{conv_image[currentIndex]['url']}}" style="width:100%" alt="{{conv_image[currentIndex]['headline']}}" />
                                </picture>
                        </div>
                    </a>
                    <a class="testvideo" [routerLink]="['/'+'key-takeaways'+'/'+conv_image[currentIndex]['slug']+'/'+conv_image[currentIndex]['_id']]">
                        <!-- <p class="internal-dr-name">{{conv_image[currentIndex]['doctor_name'] }}</p> -->
                        <p class="internal-updates-head">{{conv_image[currentIndex]['headline']}}</p>
                        

                    </a>
                </div>
            </ng-template>

            <ng-template ngFor let-item [ngForOf]="createRange(1)" let-currentIndex="index+1">
                <div fxFlex.lt-md="100%" fxFlex.gt-sm="33.33%" class="md-brdr-btm inconv1">
                    <a class="testvideo" [routerLink]="['/'+'key-takeaways'+'/'+conv_image[currentIndex]['slug']+'/'+conv_image[currentIndex]['_id']]">
                        <div class="mrg-after-imgvid">
                            <picture>
                                <source type="image/webp" srcset="{{conv_image[currentIndex]['thumbnail_url']}}">
                                    <img src="{{conv_image[currentIndex]['url']}}" style="width:100%" alt="{{conv_image[currentIndex]['headline']}}" />
                                </picture>
                        </div>
                    </a>
                    <a class="testvideo" [routerLink]="['/'+'key-takeaways'+'/'+conv_image[currentIndex]['slug']+'/'+conv_image[currentIndex]['_id']]">
                        <!-- <p class="internal-dr-name">{{conv_image[currentIndex]['doctor_name'] }}</p> -->
                        <p class="internal-updates-head">{{conv_image[currentIndex]['headline']}}</p>
                        

                    </a>
                </div>
            </ng-template>



            <div fxFlex.lt-md="100%" fxFlex.gt-sm="33.33%" class="inconv1">
                <ul fxLayout="column">
                    <ng-template ngFor let-item [ngForOf]="createRange(4)" let-currentIndex="index+2">
                        <a class="testvideo" [routerLink]="['/'+'key-takeaways'+'/'+conv_image[currentIndex]['slug']+'/'+conv_image[currentIndex]['_id']]">
                            <li class="brdr-btm">
                                <!-- <p class="internal-dr-name">{{conv_image[currentIndex]['doctor_name'] }}</p> -->
                                <p class="internal-updates-head">{{conv_image[currentIndex]['headline'] }}</p>
                            </li>
                        </a>
                    </ng-template>
                </ul>
            </div>
        </div>
        <a class="testvideo" href="https://therightdoctors.com/images/">
            <button mat-flat-button class="view-all-btn">View all</button></a>
    </div>
</div>
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import {LoginserviceService} from '../loginservice.service';

import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
@Component({
  selector: 'app-magzine',
  templateUrl: './magzine.component.html',
  styleUrls: ['./magzine.component.css']
})
export class MagzineComponent implements OnInit {

  pdfSrc  : any;
  public configure: PerfectScrollbarConfigInterface = {};
  slug : any;
  constructor(private service: LoginserviceService , private route: ActivatedRoute, private meta: Meta,
  private _formBuilder: FormBuilder, private titleService: Title) { }
  ngOnInit() {

                this.route.params.subscribe(params=>{
                  this.slug = params['slug'];
                  if(this.slug == "dr-dev-pahlajani-pci-in-women-are-outcomes-different-in-men")
                    this.pdfSrc = "https://storage.googleapis.com/web-assets/AOC-2020/Dev%20Pahlajani.pdf";
                  else
                    this.pdfSrc = "https://storage.googleapis.com/web-assets/AOC-2020/Wenger%202.pdf";
        
                });
              //  this.pdfSrc="https://storage.googleapis.com/web-assets/TheRightDoctorsWebinarBroadcast.pdf";   
     }

     downloadImage(){
      this.service.getDownload(this.pdfSrc);

     }

}

<style>
    .vid_side_box_inner {
        padding: 0 0 5px 0 !important;
        margin: 5px 0 0 !important;
        border-bottom: 1px solid #eee;
    }
</style>
<div class="new-screen3">
    <div class="container" fxLayout="column">
        <h5 class="all-head">TV</h5>
        <div fxLayout="column" fxLayoutGap="16px">
            <div fxLayout.lt-md="column" fxLayout.gt-sm="row" class="desk-brdr-btm display-mob" fxLayoutGap.gt-sm="20px" fxLayoutGap.lt-md="16px">
                <div fxFlex="100%">
                    <div fxLayout="column">

                        <div fxLayout="column">
                            <ul>
                                <div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="20px" fxLayoutGap.lt-md="8px">
                                    <ng-template ngFor let-item [ngForOf]="createRange(4)" let-currentIndex="index">

                                        <div fxFlex.lt-md="100%" fxFlex.gt-sm="25%" class="mob-brdr-btm">

                                            <a href="https://therightdoctors.com/tv/covid-19/features/{{intr[currentIndex]['slug']}}">

                                                <!-- <div> {{ intr[currentIndex]['slug'] }}   </div> -->

                                                <li fxLayout="column">
                                                    <div class="mrg-after-imgvid">
                                                        <picture>
                                                            <source type="image/webp" srcset="{{intr[currentIndex]['thumbnail_url1']}}">
                                                                <img src="{{intr[currentIndex]['image_url']}}" alt="{{intr[currentIndex]['headline']}}" style="width:100%" />
                                                            </picture>
                                                    </div>
                                                    <div>
                                                        <!-- <p class="internal-dr-name">{{intr[currentIndex]['sub_headline']}}</p> -->
                                                        <p class="internal-updates-head">{{intr[currentIndex]['headline']}}</p>

                                                    </div>

                                                </li>
                                            </a>
                                        </div>
                                    </ng-template>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- <div fxFlex.lt-md="100%" fxFlex.gt-sm="23.5%">
          <div fxLayout="column" class="mob-brdr-btm">
            <a class="testvideo"
            [routerLink]="['/'+audio_list1[1]['event']+'/'+audio_list1[1]['category']+'/'+audio_list1[1]['slug']]">
            <div class="mrg-after-imgvid">
              <img src="{{audio_list1[1]['image_url']}}" alt="{{audio_list1[1]['image_alt']}}" style="width:100%">
            </div>
            </a>
            <a class="testvideo"
            [routerLink]="['/'+audio_list1[1]['event']+'/'+audio_list1[1]['category']+'/'+audio_list1[1]['slug']]">
           
            <ul fxLayout="column">
            <li fxLayout="column">                
              <p class="internal-dr-name">{{audio_list1[1]['sub_headline']}}</p>
              <p class="internal-updates-head">{{audio_list1[1]['headline']}}</p>
            </li>
            </ul>
            </a>
          </div>
        </div> -->
            </div>
            <div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="20px" fxLayoutGap.lt-sm="0px" fxLayoutGap.lt-md="8px">
                <div fxFlex.lt-md="100%" fxFlex.gt-sm="50%" fxLayout.lt-sm="column" fxLayout.gt-xs="row" fxLayoutGap.lt-sm="16px" fxLayoutGap.gt-xs="20px">
                    <!-- <div fxFlex.lt-sm="100%" fxFlex.gt-xs="50%">
                  <div fxLayout="column"  class="mob-brdr-btm">
                    <a class="testvideo" [routerLink]="['/'+corona_v[0]['event']+'/'+corona_v[1]['category']+'/'+corona_v[1]['slug']]">
                      
                      <div class="mrg-after-imgvid">
                        <img src="{{corona_v[1]['image_url']}}" alt="{{corona_v[1]['image_alt']}}" style="width:100%" />
                      </div>
                    </a>
                    <p class="internal-updates-head"> {{corona_v[1]['headline']}}</p>
                  </div>
                </div> -->
                    <ng-template ngFor let-item [ngForOf]="createRange(2)" let-currentIndex="index+5">

                        <div fxFlex.lt-sm="100%" fxFlex.gt-xs="50%">
                            <div fxLayout="column" class="mob-brdr-btm">
                                <!-- <a class="testvideo" [routerLink]="['/'+corona_v[0]['event']+'/'+corona_v[0]['category']+'/'+corona_v[0]['slug']]"> -->
                                <a href="https://therightdoctors.com/tv/covid-19/features/{{intr[currentIndex]['slug']}}">
                                    <div class="mrg-after-imgvid">
                                        <picture>
                                            <source type="image/webp" srcset="{{intr[currentIndex]['thumbnail_url1']}}">
                                                <img src="{{intr[currentIndex]['image_url']}}" alt="{{intr[currentIndex]['headline']}}" style="width:100%" />
                                            </picture>
                                    </div>
                                </a>
                                <a href="https://therightdoctors.com/tv/covid-19/features/{{intr[currentIndex]['slug']}}">

                                    <ul fxLayout="column">
                                        <li fxLayout="column">
                                            <!-- <p class="internal-dr-name">{{intr[currentIndex]['sub_headline']}}</p> -->
                                            <p class="internal-updates-head">{{intr[currentIndex]['headline']}}</p>
                                        </li>
                                    </ul>
                                </a>
                            </div>
                        </div>
                    </ng-template>
                </div>
                <div fxLayout.lt-sm="column" fxLayout.gt-xs="row" fxFlex.lt-md="100%" fxFlex.gt-sm="50%" fxLayoutGap.lt-sm="0px" fxLayoutGap.gt-xs="20px">
                    <div fxFlex.lt-sm="100%" fxFlex.gt-xs="50%">
                        <div fxLayout="column" class="mrg-pad3">
                            <ul fxLayout="column">
                                <ng-template ngFor let-item [ngForOf]="createRange(3)" let-currentIndex="index+7">
                                    <a href="https://therightdoctors.com/tv/covid-19/features/{{intr[currentIndex]['slug']}}">

                                        <li fxLayout="column" class="brdr-btm">
                                            <!-- <p class="internal-dr-name">{{intr[currentIndex]['sub_headline']}}</p> -->
                                            <p class="internal-updates-head">{{intr[currentIndex]['headline']}}</p>
                                        </li>
                                    </a>
                                </ng-template>
                            </ul>
                        </div>
                    </div>
                    <div fxFlex.lt-md="100%" fxFlex.gt-xs="50%">
                        <div fxLayout="column">
                            <ul fxLayout="column">
                                <ng-template ngFor let-item [ngForOf]="createRange(3)" let-currentIndex="index+11">
                                    <a href="https://therightdoctors.com/tv/covid-19/features/{{intr[currentIndex]['slug']}}">
                                        <li fxLayout="column" class="brdr-btm">
                                            <!-- <p class="internal-dr-name">{{intr[currentIndex]['sub_headline']}}</p> -->
                                            <p class="internal-updates-head">{{intr[currentIndex]['headline']}}</p>
                                        </li>
                                    </a>
                                </ng-template>

                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <a href="https://therightdoctors.com/tv/">
            <button mat-flat-button class="view-all-btn">View all</button></a>
    </div>
</div>
<div class="new-screen8">
    <div class="container" fxLayout="column">
        <h5 class="all-head">Trending Podcasts</h5>
        <div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap.gt-sm="20px" class="display-mob">
            <div fxFlex.lt-md="100%" fxFlex.gt-sm="50%">
                <div fxLayout.lt-sm="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="20px">
                    <ng-template ngFor let-item [ngForOf]="createRange(2)" let-currentIndex="index">
                        <div fxFlex.lt-sm="100%" fxFlex.gt-xs="50%">
                            <a class="testvideo" href="https://therightdoctors.com/podcast/{{audio_list5[currentIndex]['event']}}/{{audio_list5[currentIndex]['slug']}}">
                                <div fxLayout="column" class="mob-brdr-btm">
                                    <div class="mrg-after-imgvid">
                                        <picture>
                                            <source type="image/webp" srcset="{{audio_list5[currentIndex]['thumbnail_url1']}}">
                                                <img src="{{audio_list5[currentIndex]['image_url']}}" alt="{{audio_list5[currentIndex]['headline']}}" style="width:100%" />
                                            </picture>
                                    </div>
                                    <ul>
                                        <li>
                                            <!-- <p class="internal-dr-name">{{audio_list5[currentIndex]['sub_headline']}}</p> -->
                                            <p class="internal-updates-head"> {{audio_list5[currentIndex]['headline'] }}</p>
                                        </li>
                                    </ul>
                                </div>
                            </a>
                        </div>
                    </ng-template>
                </div>
            </div>
            <div fxFlex.lt-md="100%" fxFlex.gt-sm="50%">
                <div fxLayout.lt-sm="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="20px">
                    <div fxFlex.lt-sm="100%" fxFlex.gt-xs="50%">
                        <ul>
                            <ng-template ngFor let-item [ngForOf]="createRange(4)" let-currentIndex="index+3">
                                <a class="testvideo" href="https://therightdoctors.com/podcast/{{audio_list5[currentIndex]['event']}}/{{audio_list5[currentIndex]['slug']}}">
                                    <li class="brdr-btm-red">
                                        <!-- <p class="internal-dr-name">{{audio_list5[currentIndex]['sub_headline'] }}</p> -->
                                        <p class="internal-updates-head"> {{audio_list5[currentIndex]['headline'] }}</p>
                                    </li>
                                </a>
                            </ng-template>
                        </ul>
                    </div>
                    <div fxFlex.lt-sm="100%" fxFlex.gt-xs="50%">
                        <ul>
                            <ng-template ngFor let-item [ngForOf]="createRange(4)" let-currentIndex="index+8">
                                <a class="testvideo" href="https://therightdoctors.com/podcast/{{audio_list5[currentIndex]['event']}}/{{audio_list5[currentIndex]['slug']}}">
                                    <li class="brdr-btm-red">
                                        <!-- <p class="internal-dr-name">{{audio_list5[currentIndex]['sub_headline'] }}</p> -->
                                        <p class="internal-updates-head"> {{audio_list5[currentIndex]['headline'] }}</p>
                                    </li>
                                </a>
                            </ng-template>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <a class="testvideo" href="https://therightdoctors.com/podcast/">
            <button mat-flat-button class="view-all-btn">View all</button></a>
    </div>
</div>